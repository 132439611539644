import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import AppConfig from '../entities/AppConfig';

declare const gtag: (command: string, commandParameters?: any, additionalConfigInfo?: any) => any;

interface Props extends RouteComponentProps<any> {
    config: AppConfig;
    children?: React.ReactNode;
}

class AnalyticsPageTracker extends React.Component<Props, {}> {

    componentDidMount() {

        (window as any).dataLayer = (window as any).dataLayer || [];
        (window as any).gtag = function () { (window as any).dataLayer.push(arguments); };

        if (this.props.config.googleAnalytics.isEnabled) {

            let script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.src = `https://www.googletagmanager.com/gtag/js?id=${this.props.config.googleAnalytics.trackingId}`;
            document.getElementsByTagName('head')[0].appendChild(script);
        }

        gtag('js', new Date());
        gtag('config', this.props.config.googleAnalytics.trackingId, { 'send_page_view': false });
        gtag('config', this.props.config.googleAnalytics.trackingId, { 'page_path': this.props.location.pathname + this.props.location.search });

        this.props.history.listen(location => {
            gtag('config', this.props.config.googleAnalytics.trackingId, { 'page_path': location.pathname + location.search });
        });
    }

    render() {
        return this.props.children;
    }
}

export default withRouter(AnalyticsPageTracker);