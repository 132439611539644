import ApplicationUser from "../entities/ApplicationUser";
import ChurchMediaSet from "../entities/ChurchMediaSet";
import ChurchMediaSetPurchase from "../entities/ChurchMediaSetPurchase";
import { ServerResponse, WebClient } from "./ServiceHelper";

export class ChurchMediaSetPurchaseService {
  static async getChurchMediaSet(id: number) {
    var response = await WebClient.Get<ChurchMediaSet>(
      `api/ChurchMediaSetPurchases/GetChurchMediaSet/${id}`
    );
    if (ServerResponse.isSuccess<ChurchMediaSet>(response)) {
      return response.data;
    } else {
      return null;
    }
  }

  static async purchaseChurchMediaSet(
    churchMediaSetId: number,
    creditCardId: string,
    nameOnCard: string,
    creditCardNumber: string,
    cv2: string,
    expirationMonth: number,
    expirationYear: number,
    postalCode: string,
    saveCreditCard: boolean,
    region?: string,
    country?: string
  ) {
    return await WebClient.Post<ChurchMediaSetPurchase>(
      "api/ChurchMediaSetPurchases/PurchaseChurchMediaSet",
      {
        churchMediaSetId,
        creditCardId,
        nameOnCard,
        creditCardNumber,
        cv2,
        expirationMonth,
        expirationYear,
        postalCode,
        saveCreditCard,
        region,
        country,
      }
    );
  }

  static async subscribeToProPremiumAndGiftChurchMediaSet(
    churchMediaSetId: number,
    creditCardId: string,
    nameOnCard: string,
    creditCardNumber: string,
    cv2: string,
    expirationMonth: number,
    expirationYear: number,
    postalCode: string,
    phoneNumber: string,
    region?: string,
    country?: string
  ) {
    return await WebClient.Post<ApplicationUser>(
      "api/ChurchMediaSetPurchases/SubscribeToProPremiumAndGiftChurchMediaSet",
      {
        churchMediaSetId,
        creditCardId,
        nameOnCard,
        creditCardNumber,
        cv2,
        expirationMonth,
        expirationYear,
        postalCode,
        phoneNumber,
        region,
        country,
      }
    );
  }

  static async upgradeToProPremiumAndGiftChurchMediaSet(
    churchMediaSetId: number,
    creditCardId: string,
    nameOnCard: string,
    creditCardNumber: string,
    cv2: string,
    expirationMonth: number,
    expirationYear: number,
    postalCode: string,
    phoneNumber: string,
    region?: string,
    country?: string
  ) {
    return await WebClient.Post<ApplicationUser>(
      "api/ChurchMediaSetPurchases/UpgradeToProPremiumAndGiftChurchMediaSet",
      {
        churchMediaSetId,
        creditCardId,
        nameOnCard,
        creditCardNumber,
        cv2,
        expirationMonth,
        expirationYear,
        postalCode,
        phoneNumber,
        region,
        country,
      }
    );
  }

  static roundToTwo(number: number) {
    return +(Math.round(+(number + "e+2")) + "e-2");
  }
}
