import * as React from 'react';
import { Link, RouteComponentProps, Redirect } from 'react-router-dom';

import ApplicationUser from '../../entities/ApplicationUser';
import AppConfig from '../../entities/AppConfig';
import { ProSubscriptionTerm, ProSubscriptionStatus, ProSubscriptionTier } from '../../entities/ProSubscription';

interface Props extends RouteComponentProps<any> {
    user: ApplicationUser;
    config: AppConfig;
}

type State = {
    redirectToSubscribe: boolean;
};

export default class SubscribeAlreadyAMember extends React.Component<Props, State> {

    redirectState: any = {};

    constructor(props: Props) {
        super(props);
        this.state = {
            redirectToSubscribe: false
        };
    }

    async componentDidMount() {

        document.title = "SermonCentral Payments - Already a Member";

        let redirectState = this.props.location.state;

        // Unlikely scenario, but user shouldn't be on this page unless they've been redirected, 
        // so redirect back to /subscribe
        if (!redirectState || !this.props.user.proSubscription) {
            this.setState({
                redirectToSubscribe: true
            });
        }

        this.redirectState = redirectState;
    }

    render() {

        if (this.state.redirectToSubscribe) {
            return <Redirect to={{ pathname: "/subscribe", state: this.redirectState }} />;
        }

        const proSubscription = this.props.user.proSubscription;

        return (
            <div className="container py-5">

                <div className="row">
                    <div className="offset-md-2 col-md-8">
                        <div className="text-center py-5">
                            <h2 className="mb-4">Already a Member</h2>
                            <h6 className="mb-4">
                                It looks like you've already got a SermonCentral PRO membership.
                                {proSubscription?.term !== ProSubscriptionTerm.None && proSubscription?.status === ProSubscriptionStatus.Active &&
                                    <> <a href={`${this.props.config.accountSiteUrl}/`}>Click here</a> to manage your subscription to SermonCentral PRO {ProSubscriptionTier[proSubscription.tier]}.</>
                                }
                                {proSubscription?.term === ProSubscriptionTerm.None && !proSubscription.serviceStopDate &&
                                    <> As a Site Administrator, you've got access to PRO Premium that will never expire.</>
                                }
                                {proSubscription?.term === ProSubscriptionTerm.None && proSubscription.serviceStopDate &&
                                    <> Your Free Contributor PRO doesn't expire until {new Date(proSubscription.serviceStopDate).toLocaleDateString()}.</>
                                }
                                {proSubscription?.serviceStopDate && proSubscription.status !== ProSubscriptionStatus.Active &&
                                    <> Even though your subscription has been canceled, your benefits will not expire until {new Date(proSubscription.serviceStopDate).toLocaleDateString()}.</>
                                }
                            </h6>
                            <button className="btn btn-secondary ml-auto mr-auto d-block"><a href={this.props.config.mainSiteUrl} className="buttonLink">RETURN TO SERMONCENTRAL</a></button>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}