import * as React from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import AppConfig from "../../entities/AppConfig";
import ApplicationUser from "../../entities/ApplicationUser";
import ChurchMediaSet from "../../entities/ChurchMediaSet";
import ChurchMediaSetPurchase from "../../entities/ChurchMediaSetPurchase";

interface Props extends RouteComponentProps<{ id: string }> {
  user: ApplicationUser;
  config: AppConfig;
}

type State = {
  churchMediaSet?: ChurchMediaSet;
  churchMediaSetPurchase?: ChurchMediaSetPurchase;
  redirectToPurchase: boolean;
  returnUrl: string;
};

declare const window: any;
declare const gtag: any;

export default class PurchaseChurchMediaSetConfirmation extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      redirectToPurchase: false,
      returnUrl: this.props.config.mainSiteUrl,
    };
  }

  componentDidMount() {
    document.title =
      "SermonCentral Payments - Purchase Media Item Confirmation";

    let redirectState = this.props.location.state;

    // Unlikely scenario, but user shouldn't be on this page unless they've been redirected,
    // so redirect back to /subscribe
    if (!redirectState) {
      this.setState({
        redirectToPurchase: true,
      });
    } else {
      this.setState({
        churchMediaSet: (redirectState as any).churchMediaSet,
        churchMediaSetPurchase: (redirectState as any).churchMediaSetPurchase,
      });

      if ((redirectState as any).returnUrl) {
        this.setState({
          returnUrl: (redirectState as any).returnUrl,
        });
      }

      setTimeout(() => {
        const churchMediaSet = this.state.churchMediaSet!;
        const churchMediaSetPurchase = this.state.churchMediaSetPurchase!;
        const user = this.props.user;
  
        window.dataLayer.push({
            'event':'orderComplete',
            'orderValue': churchMediaSetPurchase.purchasePrice,
            'orderCurrency': user.currency || "USD",
            'orderId': churchMediaSetPurchase.orderId, 
            'ecData': { 'email': user.email, "phone_number": user.phoneNumber?.replace(/\D/g, "")}
          });

        if ("gtag" in window) {

          window.gaItem = {
            "id": churchMediaSet.id,
            "name": churchMediaSet.name,
            "brand": "SermonCentral",
            "category": churchMediaSet.type,
            "quantity": "1",
            "price": churchMediaSetPurchase.purchasePrice,
          };
          window.gaTransaction = {
            "transaction_id": churchMediaSetPurchase.orderId,
            "affiliation": "Payments Digital Download",
            "value": churchMediaSetPurchase.purchasePrice,
            "currency": "USD",
            "shipping": "0",
            "tax": "0",
            "items": [window.gaItem],
            "coupon": (redirectState as any).appliedPromoCode || "",
          };

          gtag("event", "purchase", window.gaTransaction);
        }
      }, 1000);
    }
  }
  render() {
    if (this.state.redirectToPurchase) {
      return (
        <Redirect
          to={{ pathname: `/purchase/media/${this.props.match.params.id}` }}
        />
      );
    }

    return (
      <div className="row">
        <div className="offset-md-2 col-md-8">
          <div className="text-center py-5">
            <h2 className="mb-4">Thank you for your purchase!</h2>
            <h6 className="mb-4">
              Return to SermonCentral to download your media item, or{" "}
              <a href={`${this.props.config.accountSiteUrl}/media/purchases`}>
                print a receipt
              </a>{" "}
              for your records.
            </h6>
            <button className="btn btn-secondary ml-auto mr-auto d-block">
              <a href={this.state.returnUrl} className="buttonLink">
                RETURN TO SERMONCENTRAL
              </a>
            </button>
          </div>
        </div>
      </div>
    );
  }
}
