import ApplicationUser from "../entities/ApplicationUser";
import { PromoCodeDiscountType } from "../entities/PromoCodeDiscountType";
import Kit from "../entities/Kit";
import KitPromoCode from "../entities/KitPromoCode";
import KitPurchase from "../entities/KitPurchase";
import { ServerResponse, WebClient } from "./ServiceHelper";

export class KitPurchaseService {
  static GetPriceIncludingDiscountAndTax(
    kit: Kit,
    tax: number,
    appliedPromoCode?: KitPromoCode
  ) {
    var purchasePrice = kit.salePrice
      ? kit.salePrice
      : kit.listPrice
      ? kit.listPrice
      : 0;

    var finalPrice = purchasePrice;

    if (appliedPromoCode) {
      if (appliedPromoCode.discountType === PromoCodeDiscountType.PercentOff) {
        var discountPercentage = appliedPromoCode.discountAmount / 100;
        if (discountPercentage == 1) {
          finalPrice = 0;
        } else {
          var discountAmount = purchasePrice * discountPercentage;
          finalPrice = this.roundToTwo(purchasePrice - discountAmount);
        }
      } else if (
        appliedPromoCode.discountType === PromoCodeDiscountType.DollarOff
      ) {
        finalPrice = purchasePrice - appliedPromoCode.discountAmount;
      }
    }

    finalPrice = finalPrice + tax;

    return Math.max(0, finalPrice);
  }

  static async Get(id: number) {
    var response = await WebClient.Get<Kit>(
      `api/KitPurchases/Get/${id}`
    );
    if (ServerResponse.isSuccess<Kit>(response)) {
      return response.data;
    } else {
      return null;
    }
  }

  static async purchaseKit(
    kitId: number,
    creditCardId: string,
    nameOnCard: string,
    creditCardNumber: string,
    cv2: string,
    expirationMonth: number,
    expirationYear: number,
    postalCode: string,
    saveCreditCard: boolean,
    promoCode: string,
    region?: string,
    country?: string
  ) {
    return await WebClient.Post<KitPurchase>(
      "api/KitPurchases/PurchaseKit",
      {
        kitId,
        creditCardId,
        nameOnCard,
        creditCardNumber,
        cv2,
        expirationMonth,
        expirationYear,
        postalCode,
        saveCreditCard,
        promoCode,
        region,
        country,
      }
    );
  }

  static async subscribeToProPremiumAndGiftKit(
    kitId: number,
    creditCardId: string,
    nameOnCard: string,
    creditCardNumber: string,
    cv2: string,
    expirationMonth: number,
    expirationYear: number,
    postalCode: string,
    phoneNumber: string,
    region?: string,
    country?: string
  ) {
    return await WebClient.Post<ApplicationUser>(
      "api/KitPurchases/SubscribeToProPremiumAndGiftKit",
      {
        kitId,
        creditCardId,
        nameOnCard,
        creditCardNumber,
        cv2,
        expirationMonth,
        expirationYear,
        postalCode,
        phoneNumber,
        region,
        country,
      }
    );
  }

  static async upgradeToProPremiumAndGiftKit(
    kitId: number,
    creditCardId: string,
    nameOnCard: string,
    creditCardNumber: string,
    cv2: string,
    expirationMonth: number,
    expirationYear: number,
    postalCode: string,
    phoneNumber: string,
    region?: string,
    country?: string
  ) {
    return await WebClient.Post<ApplicationUser>(
      "api/KitPurchases/UpgradeToProPremiumAndGiftKit",
      {
        kitId,
        creditCardId,
        nameOnCard,
        creditCardNumber,
        cv2,
        expirationMonth,
        expirationYear,
        postalCode,
        phoneNumber,
        region,
        country,
      }
    );
  }

  static async validatePromoCode(promoCode: string, kitId: number) {
    return await WebClient.Post<KitPromoCode>(
      "api/KitPurchases/ValidatePromoCode",
      { promoCode, kitId }
    );
  }

  static roundToTwo(number: number) {
    return +(Math.round(+(number + "e+2")) + "e-2");
  }
}
