export enum ProSubscriptionTier {
    None = 0,
    Basic = 1,
    Plus = 2,
    Premium = 3
}

export enum ProSubscriptionTerm {
    None = 0,
    Monthly = 1,
    Annual = 12,
    Biennial = 24
}

export enum ProSubscriptionStatus {
    None,
    Draft,
    PendingActivation,
    PendingAcceptance,
    Active,
    Cancelled,
    Expired,
    Suspended
}

export enum ProSubscriptionSource {
    Zuora = 1,
    Gloo = 2,
    Employee = 3
}

export default interface ProSubscription {      
    cancelledDate?: string;
    inTrial: boolean;
    nextBillDate?: string;
    nextBillPrice?: number;
    planName: string;
    planPrice?: number;
    serviceStopDate?: string;
    status: ProSubscriptionStatus;
    subscriptionStartDate?: string;
    term: ProSubscriptionTerm;
    tier: ProSubscriptionTier;
    source: ProSubscriptionSource;
}

export const ProCost = {
    basic : 149,
    plus : 199,
    plusMonthly : 25,
    premium : 299
}
