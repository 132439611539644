import * as React from 'react';
import { Link, RouteComponentProps, Redirect } from 'react-router-dom';
import { LocationDescriptor } from "history";

import ApplicationUser from '../../entities/ApplicationUser';
import AppConfig from '../../entities/AppConfig';
import { ProSubscriptionTerm, ProSubscriptionStatus, ProSubscriptionTier } from '../../entities/ProSubscription';

interface Props extends RouteComponentProps<any> {
    user: ApplicationUser;
    config: AppConfig;
}

type State = {
    redirectTo: LocationDescriptor<any> | null;
};

export default class CantUpgradeSubscription extends React.Component<Props, State> {

    redirectState: any = {};

    constructor(props: Props) {
        super(props);
        this.state = {
            redirectTo: null
        };
    }

    async componentDidMount() {

        document.title = "SermonCentral Payments - Cannot Upgrade PRO Membership";

        if (!this.props.user.proSubscription) {

            this.setState({ redirectTo: { pathname: '/upgrade', state: this.redirectState } });
        }

        else if (this.props.user.proSubscription.term !== ProSubscriptionTerm.None 
              && this.props.user.proSubscription.status === ProSubscriptionStatus.Active
              && this.props.user.proSubscription.tier !== ProSubscriptionTier.Premium) {

            this.setState({ redirectTo: { pathname: '/upgrade', state: this.redirectState } });
        }
    }

    render() {

        if (this.state.redirectTo) {
            return (
                <Redirect to={this.state.redirectTo} />
            );
        }
        else {
            const subscription = this.props.user.proSubscription;
            return (
                <div className="container py-5">

                    <div className="row">
                        <div className="offset-md-2 col-md-8">
                            <div className="text-center py-5">
                                <h2 className="mb-4">Cannot Upgrade PRO Membership</h2>
                                <h6 className="mb-4">
                                    {subscription?.term === ProSubscriptionTerm.None && !subscription?.serviceStopDate &&
                                        <> As a Site Administrator, you already have access to PRO Premium benefits.</>
                                    }
                                    {subscription?.term === ProSubscriptionTerm.None && subscription?.serviceStopDate &&
                                        <> Your Free Contributor PRO gives you access to PRO Premium benefits until {new Date(subscription?.serviceStopDate).toLocaleDateString()}.</>
                                    }
                                    {subscription?.term !== ProSubscriptionTerm.None && subscription?.tier === ProSubscriptionTier.Premium &&
                                        <> You cannot upgrade your subscription to PRO Premium because you already have PRO Premium membership.</>
                                    }
                                    {subscription?.serviceStopDate && subscription?.status !== ProSubscriptionStatus.Active &&
                                        <> Your subscription has been canceled. Your will be able to sign up for a new subscription on {new Date(subscription.serviceStopDate).toLocaleDateString()}.</>
                                    }
                                </h6>
                                <button className="btn btn-secondary ml-auto mr-auto d-block"><a href={this.props.config.mainSiteUrl} className="buttonLink">RETURN TO SERMONCENTRAL</a></button>
                            </div>
                        </div>
                    </div>

                </div>
            );
        }
    }
}