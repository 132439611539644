import { LocationDescriptor } from "history";
import * as React from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import ApplicationUser from "../../entities/ApplicationUser";
import { AuthenticationService } from "../../services/AuthenticationService";
import { getParameterByName } from "../../services/ServiceHelper";

interface Props extends RouteComponentProps<any> {
  user: ApplicationUser | null;
  handleUpdateUser: (user: ApplicationUser | null) => void;
}

type State = {
  redirectTo: LocationDescriptor<any> | null;
};

export default class SubscribeRouter extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      redirectTo: null,
    };
  }

  async componentDidMount() {
    document.title = "SermonCentral Payments - Subscribe";

    let redirectState = this.props.location.state || {};
    let email = getParameterByName("email");
    if (!email) {
      email = (redirectState as any).email;
    }
    let fullAccountSinglePage = getParameterByName("full-account");
    if (!fullAccountSinglePage) {
      fullAccountSinglePage = (redirectState as any).fullAccountSinglePage;
    }

    let fullAccountQuickSetup = getParameterByName("quick-setup");
    if (!fullAccountQuickSetup) {
      fullAccountQuickSetup = (redirectState as any).fullAccountQuickSetup;
    }

    let fullAccountEmbed = getParameterByName("embed");
    if (!fullAccountEmbed) {
      fullAccountEmbed = (redirectState as any).fullAccountEmbed;
    }

    // We're making the assumption that if an email is passed in (through a form submission in Unbounce), the user may or may not know
    // that they have an SC account, or that their changes affect their SC account. So we make sure to sign out the user, if they're signed in,
    // and if the email matches an existing account, we force the user to sign in again, before they can enter their billing info
    if (email) {
      if (this.props.user) {
        await AuthenticationService.signOut();
        this.props.handleUpdateUser(null);
      }

      const isExistingUser = await AuthenticationService.getIsExistingUser(
        email!
      );

      if (isExistingUser) {
        this.setState({
          redirectTo: {
            pathname: "/subscribe/auth-challenge",
            state: this.getCombinedRedirectState(redirectState),
          },
        });
      } else {
        if (fullAccountSinglePage) {
          // Caleb page for single full account page
          this.setState({
            redirectTo: {
              pathname: "/subscribe/create-full-account",
              state: this.getCombinedRedirectState(redirectState),
            },
          });
        } else if (fullAccountQuickSetup) {
          // Caleb page for single full account page
          this.setState({
            redirectTo: {
              pathname: "/subscribe/quick-setup",
              state: this.getCombinedRedirectState(redirectState),
            },
          });
        } else if (fullAccountEmbed) {
          this.setState({
            redirectTo: {
              pathname: "/subscribe/embed",
              state: this.getCombinedRedirectState(redirectState),
            },
          });
        } else {
          this.setState({
            redirectTo: {
              pathname: "/subscribe/create-account",
              state: this.getCombinedRedirectState(redirectState),
            },
          });
        }
      }
    }

    // If the user is signed in, check for an existing PRO subscription. A user cannot sign up for a new PRO sub while they still have a non-expired
    // PRO sub. So redirect them to a page that let's them know they're good to go
    else if (this.props.user) {
      if (this.props.user.proSubscription) {
        this.setState({
          redirectTo: {
            pathname: "/subscribe/already-a-member",
            state: this.getCombinedRedirectState(redirectState),
          },
        });
      } else {
        if ((redirectState as any).embed) {
          this.setState({
            redirectTo: {
              pathname: "/subscribe/embed",
              state: this.getCombinedRedirectState(redirectState),
            },
          });
        } else {
          this.setState({
            redirectTo: {
              pathname: "/subscribe/billing-info",
              state: this.getCombinedRedirectState(redirectState),
            },
          });
        }
      }
    } else {
      if (fullAccountSinglePage) {
        // Caleb page for single full account page
        this.setState({
          redirectTo: {
            pathname: "/subscribe/create-full-account",
            state: this.getCombinedRedirectState(redirectState),
          },
        });
      } else if (fullAccountQuickSetup) {
        // Caleb page for single full account page
        this.setState({
          redirectTo: {
            pathname: "/subscribe/quick-setup",
            state: this.getCombinedRedirectState(redirectState),
          },
        });
      } else if (fullAccountEmbed) {
        this.setState({
          redirectTo: {
            pathname: "/subscribe/embed",
            state: this.getCombinedRedirectState(redirectState),
          },
        });
      } else {
        this.setState({
          redirectTo: {
            pathname: "/subscribe/create-account",
            state: this.getCombinedRedirectState(redirectState),
          },
        });
      }
    }
  }

  getCombinedRedirectState(existingRedirectState: any) {
    let redirectState = existingRedirectState || {};

    if (getParameterByName("email")) {
      redirectState["email"] = getParameterByName("email");
    }

    if (getParameterByName("firstName")) {
      redirectState["firstName"] = getParameterByName("firstName");
    }

    if (getParameterByName("lastName")) {
      redirectState["lastName"] = getParameterByName("lastName");
    }

    if (getParameterByName("phoneNumber")) {
      redirectState["phoneNumber"] = getParameterByName("phoneNumber");
    }

    if (getParameterByName("pageTemplate")) {
      redirectState["pageTemplate"] = getParameterByName("pageTemplate");
    }

    if (getParameterByName("ref")) {
      redirectState["url_ref"] = getParameterByName("ref");
    }

    if (getParameterByName("url_ref")) {
      redirectState["url_ref"] = getParameterByName("url_ref");
    }

    if (getParameterByName("utm_promo")) {
      redirectState["utm_promo"] = getParameterByName("utm_promo");
    }

    if (getParameterByName("utm_source")) {
      redirectState["utm_source"] = getParameterByName("utm_source");
    }

    if (getParameterByName("utm_campaign")) {
      redirectState["utm_campaign"] = getParameterByName("utm_campaign");
    }

    if (getParameterByName("utm_medium")) {
      redirectState["utm_medium"] = getParameterByName("utm_medium");
    }

    if (getParameterByName("utm_content")) {
      redirectState["utm_content"] = getParameterByName("utm_content");
    }

    if (getParameterByName("pre_selected_plan")) {
      redirectState["pre_selected_plan"] =
        getParameterByName("pre_selected_plan");
    }

    if (getParameterByName("excludeTrial")) {
      redirectState["excludeTrial"] = getParameterByName("excludeTrial");
    }

    if (getParameterByName("includeBasicTrial")) {
      redirectState["includeBasicTrial"] = getParameterByName("includeBasicTrial");
    }
    
    if (getParameterByName("returnUrl")) {
      redirectState["returnUrl"] = getParameterByName("returnUrl");
    }

    if (getParameterByName("fullName")) {
      redirectState["fullName"] = getParameterByName("fullName");
    }

    if (getParameterByName("embed")) {
      redirectState["embed"] = getParameterByName("embed");
    }

    return redirectState;
  }

  render() {
    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo!} />;
    } else {
      return (
        <img
          className="sc-content-spinner"
          src="//i.cdn-sc.com/Logos/sc-full-spinner-gray.gif"
        />
      );
    }
  }
}
