import React from "react";

import { GlooIcon } from "./GlooIcon";

interface Props {
    url: string;
    disabled?: boolean;
    styleOverrides?: React.CSSProperties;
}

export function GlooButton(props: Props) {
    return (
        <a href={props.url} className="gloo-btn-link" style={{pointerEvents: props.disabled ? 'none' : 'auto', cursor: props.disabled ? 'default' : 'pointer'}}>
            <button style={props.styleOverrides} className="btn gloo-btn btn-block btn-lg mb-4" type="button">
                Connect with
                <GlooIcon />
            </button>
        </a>
    );
}