import * as React from 'react';
import { Link, RouteComponentProps, Redirect } from 'react-router-dom';
import { LocationDescriptor } from "history";

import ApplicationUser from '../../entities/ApplicationUser';
import AppConfig from '../../entities/AppConfig';
import { ProSubscriptionTerm, ProSubscriptionStatus } from '../../entities/ProSubscription';

interface Props extends RouteComponentProps<any> {
    user: ApplicationUser;
    config: AppConfig;
}

type State = {
    redirectTo: LocationDescriptor<any> | null;
};

export default class CantCancelSubscription extends React.Component<Props, State> {

    redirectState: any = {};

    constructor(props: Props) {
        super(props);
        this.state = {
            redirectTo: null
        };
    }

    async componentDidMount() {

        document.title = "SermonCentral Payments - Cannot Cancel PRO Subscription";

        if (!this.props.user.proSubscription) {

            this.setState({ redirectTo: { pathname: '/cancel', state: this.redirectState } });
        }

        else if (this.props.user.proSubscription.term !== ProSubscriptionTerm.None && this.props.user.proSubscription.status === ProSubscriptionStatus.Active) {

            this.setState({ redirectTo: { pathname: '/cancel', state: this.redirectState } });
        }
    }

    render() {

        if (this.state.redirectTo) {
            return (
                <Redirect to={this.state.redirectTo} />
            );
        }
        else {
            return (
                <div className="container py-5">

                    <div className="row">
                        <div className="offset-md-2 col-md-8">
                            <div className="text-center py-5">
                                <h2 className="mb-4">Cannot Cancel PRO Subscription</h2>
                                <h6 className="mb-4">
                                    {this.props.user.proSubscription?.term === ProSubscriptionTerm.None && !this.props.user.proSubscription?.serviceStopDate &&
                                        <> As a Site Administrator, your access to PRO Premium is not tied to a subscription.</>
                                    }
                                    {this.props.user.proSubscription?.term === ProSubscriptionTerm.None && this.props.user.proSubscription?.serviceStopDate &&
                                        <> Your Free Contributor PRO doesn't expire until {new Date(this.props.user.proSubscription?.serviceStopDate).toLocaleDateString()}.</>
                                    }
                                    {this.props.user.proSubscription?.serviceStopDate && this.props.user.proSubscription?.status !== ProSubscriptionStatus.Active &&
                                        <> Your subscription has already been canceled. Your benefits will end on {new Date(this.props.user.proSubscription?.serviceStopDate).toLocaleDateString()}.</>
                                    }
                                </h6>
                                <button className="btn btn-secondary ml-auto mr-auto d-block"><a href={this.props.config.mainSiteUrl} className="buttonLink">RETURN TO SERMONCENTRAL</a></button>
                            </div>
                        </div>
                    </div>

                </div>
            );
        }
    }
}