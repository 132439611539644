import * as React from 'react';
import { Link, RouteComponentProps, Redirect } from 'react-router-dom';

import ApplicationUser from '../../entities/ApplicationUser';
import AppConfig from '../../entities/AppConfig';

import Kit from '../../entities/Kit';
import KitPurchase from '../../entities/KitPurchase';

interface Props extends RouteComponentProps<{ id: string }> {
    user: ApplicationUser;
    config: AppConfig;
}

type State = {
    sermonSeriesKit?: Kit;
    sermonSeriesKitPurchase?: KitPurchase;
    redirectToPurchase: boolean;
    returnUrl: string;
};

declare const window: any;
declare const gtag: any;

export default class PurchaseSermonSeriesKitConfirmation extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            redirectToPurchase: false,
            returnUrl: this.props.config.mainSiteUrl
        };
    }

    componentDidMount() {

        document.title = "SermonCentral Payments - Purchase Sermon Series Kit Confirmation";

        let redirectState = this.props.location.state;

        // Unlikely scenario, but user shouldn't be on this page unless they've been redirected, 
        // so redirect back to /subscribe
        if (!redirectState) {
            this.setState({
                redirectToPurchase: true
            });
        }
        else {

            this.setState({
                sermonSeriesKit: (redirectState as any).sermonSeriesKit,
                sermonSeriesKitPurchase: (redirectState as any).sermonSeriesKitPurchase
            });

            if ((redirectState as any).returnUrl) {
                this.setState({
                    returnUrl: (redirectState as any).returnUrl
                });
            }

            setTimeout(() => {
                const sermonSeriesKit = this.state.sermonSeriesKit!;
                const sermonSeriesKitPurchase = this.state.sermonSeriesKitPurchase!;
                const user = this.props.user;
    
                window.dataLayer.push({
                    'event':'orderComplete',
                    'orderValue': sermonSeriesKitPurchase.purchasePrice,
                    'orderCurrency': user.currency || "USD",
                    'orderId': sermonSeriesKitPurchase.orderId, 
                    'ecData': { 'email': user.email, "phone_number": user.phoneNumber?.replace(/\D/g, "")}
                });

                if ('gtag' in window) {

                    window.gaItem = {
                        "id": sermonSeriesKit.id,
                        "name": sermonSeriesKit.name,
                        "brand": "SermonCentral",
                        "category": "Sermon Series Kit",
                        "quantity": "1",
                        "price": sermonSeriesKitPurchase.purchasePrice,
                    };
                    window.gaTransaction = {
                        "transaction_id": sermonSeriesKitPurchase.orderId,
                        "affiliation": "Payments Digital Download",
                        "value": sermonSeriesKitPurchase.purchasePrice,
                        "currency": "USD",
                        "shipping": "0",
                        "tax": "0",
                        "items": [
                            window.gaItem
                        ],
                        "coupon": (redirectState as any).appliedPromoCode || ''
                    };

                    gtag('event', 'purchase', window.gaTransaction);
                }

            }, 1000);

        }
    }
    render() {

        if (this.state.redirectToPurchase) {
            return <Redirect to={{ pathname: `/purchase/sermon-series-kits/${this.props.match.params.id}` }} />;
        }

        return (
            <div className="row">
                <div className="offset-md-2 col-md-8">
                    <div className="text-center py-5">
                        <h2 className="mb-4">Thank you for your purchase!</h2>
                        <h6 className="mb-4">
                            Return to SermonCentral to download your Sermon Series Kit, or <a href={`${this.props.config.accountSiteUrl}/media/purchases`}>print a receipt</a> for your records.
                        </h6>
                        <button className="btn btn-secondary ml-auto mr-auto d-block"><a href={this.state.returnUrl} className="buttonLink">RETURN TO SERMONCENTRAL</a></button>
                    </div>
                </div>
            </div>
        );
    }
}