import axios from 'axios';
import { AxiosError } from "axios";

export interface ServerError {
    message: string;
    statusCode: number;
}

export class ServerError {
    static isServerError(model: any): model is ServerError {
        return model.message !== undefined && model.statusCode !== undefined;
    }
}

export interface SuccessResponse<T> {
    data: T;
    message: string;
}

export class ServerResponse {
    static isSuccess<T>(model: any): model is SuccessResponse<T> {
        return model.data !== undefined && model.message !== undefined;
    }
    static isError(model: any): model is ServerError {
        return model.message !== undefined && model.statusCode !== undefined;
    }
    static isModelValidation(model: any): model is ServerModelValidationResponse {
        return model.valid !== undefined && model.errors !== undefined;
    }
}

export interface ServerModelValidationResponse {
    valid: boolean;
    errors: FieldValidationError[];
}

export class ServerModelValidationResponse {
    static isServerModelValidationResponse(model: any): model is ServerModelValidationResponse {
        return model.valid !== undefined && model.errors !== undefined;
    }
}

export interface FieldValidationError {
    field: string;
    errors: string[];
}

export class FieldValidationError {
    static isFieldInError(errors: FieldValidationError[], fieldName: string): boolean {
        return errors.map(error => error.field).indexOf(fieldName) > -1;
    }
    static getFieldErrorSummary(errors: FieldValidationError[], fieldName: string): string {
        var retVal = '';
        errors.filter(error => error.field === fieldName).forEach(error => {
            error.errors.forEach(str => retVal += str + ' ');
        });
        return retVal;
    }
    static hasGenericError(errors: FieldValidationError[]): boolean {

        return errors.map(error => error.field).indexOf('') > -1;
    }
    static getGenericErrorSummary(errors: FieldValidationError[]): string {
        var retVal = '';
        errors.filter(error => error.field === '').forEach(error => {
            error.errors.forEach(str => retVal += str + ' ');
        });
        return retVal;
    }
}

const serializeAxiosError = (errorResponse: AxiosError) => {
    let serverError: ServerError;
    let response = errorResponse.response;

    // Happens if the http request gets killed mid request
    if (!response) {
        serverError = { message: "Received no response", statusCode: 500 };
    }
    else if (response.status === 404) {
        serverError = { message: "Server is not responding", statusCode: response.status };
    }
    // Redirect to sign in page
    else if (response.status === 401) {
        let path = window.location.pathname;
        let redirect = "?returnUrl=" + encodeURIComponent(path);
        window.location.replace("/auth/sign-in" + redirect);
        let standardServerResponse: SuccessResponse<string> = response.data;
        serverError = { message: standardServerResponse.message, statusCode: response.status };
    }
    else {
        let standardServerResponse: SuccessResponse<string> = response.data;
        serverError = { message: standardServerResponse.message, statusCode: response.status };
    }

    return serverError;
};

export const getEmailFromPaypal = (name: string, url?: string) => {
    // We need to take the email exact same way as paypal passes it we can't replace + signs with spaces.
    // Silly to roll our own, I know, but we need the name lookup to be case insensitive,
    // and I didn't want to loop through keys
    if (!url) {
        url = window.location.href;
    }

    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", 'i');
    var results = regex.exec(url);

    if (!results) {
        return null;
    }

    if (!results[2]) {
        return '';
    }

    return decodeURIComponent(results[2]);
}

export const getParameterByName = (name: string, url?: string) => {

    // Silly to roll our own, I know, but we need the name lookup to be case insensitive,
    // and I didn't want to loop through keys
    if (!url) {
        url = window.location.href;
    }

    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", 'i');
    var results = regex.exec(url);

    if (!results) {
        return null;
    }

    if (!results[2]) {
        return '';
    }

    return decodeURIComponent(results[2].replace(/\+/g, " "));
}


export class WebClient {
    static async Get<T>(url: string) {
        try {
            const response = await axios.get(url, { headers: { Pragma: 'no-cache' } });
            return response.data as (SuccessResponse<T> | ServerModelValidationResponse);
        }
        catch (errorResult) {
            return serializeAxiosError((errorResult));
        }
    }

    static async GetWithoutModelValidation<T>(url: string) {
        try {
            const response = await axios.get(url, { headers: { Pragma: 'no-cache' } });
            return response.data as SuccessResponse<T>;
        }
        catch (errorResult) {
            return serializeAxiosError((errorResult));
        }
    }

    static async Post<T>(url: string, data: any) {
        try {
            const response = await axios(url, {
                method: "post",
                data: data,
                headers: { Pragma: 'no-cache' }
            });
            return response.data as (SuccessResponse<T> | ServerModelValidationResponse);
        }
        catch (errorResult) {
            return serializeAxiosError(errorResult);
        }
    }

    static async Put<T>(url: string, data: any) {
        try {
            const response = await axios(url, {
                method: "put",
                data: data,
                headers: { Pragma: 'no-cache' }
            });
            return response.data as (SuccessResponse<T> | ServerModelValidationResponse);
        }
        catch (errorResult) {
            return serializeAxiosError(errorResult);
        }
    }

    static async Delete<T>(url: string, data?: any) {
        try {
            const response = await axios(url, {
                method: "delete",
                data: data,
                headers: { Pragma: 'no-cache' }
            });
            return response.data as (SuccessResponse<T> | ServerModelValidationResponse);
        }
        catch (errorResult) {
            return serializeAxiosError(errorResult);
        }
    }

    static async PostFile<T>(url: string, file: File) {
        try {
            let formData = new FormData();
            formData.append("file", file, file.name);
            const response = await axios(url, {
                method: "post",
                data: formData,
                headers: { Pragma: 'no-cache' }
            });
            return response.data as SuccessResponse<T>;
        }
        catch (errorResult) {
            return serializeAxiosError(errorResult);
        }
    }
}
