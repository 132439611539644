import { FieldValidationError } from "../../services/ServiceHelper";

export const ValidateInput = {
  // Validates the expiration month of credit card, returning null means valid.
  CreditCardExpiryMonth: (expirationMonth: string) => {
    if (
      isNaN(+expirationMonth) ||
      +expirationMonth < 1 ||
      +expirationMonth > 12
    ) {
      const serverError: FieldValidationError = {
        field: "CreditCardExpirationMonth",
        errors: ["Expiration Month needs to be between 01-12"],
      };
      return serverError;
    }

    if (expirationMonth.length < 2) {
      const serverError: FieldValidationError = {
        field: "CreditCardExpirationMonth",
        errors: ["Expiration Month needs to be two digits (MM)"],
      };

      return serverError;
    }

    return null;
  },

  CreditCardExpiryYear: (expirationYear: string) => {
    if (isNaN(+expirationYear)) {
      const serverError: FieldValidationError = {
        field: "CreditCardExpirationYear",
        errors: ["Expiration Year needs to be a valid year"],
      };
      return serverError;
    }

    if (expirationYear.length < 2) {
      const serverError: FieldValidationError = {
        field: "CreditCardExpirationYear",
        errors: ["Expiration Year needs to be two digits (YY)"],
      };
      return serverError;
    }

    return null;
  },
};
