import * as React from "react";
import { Link, RouteComponentProps, Redirect } from "react-router-dom";
import { LocationDescriptor } from "history";

import AppConfig from "../../entities/AppConfig";
import ApplicationUser from "../../entities/ApplicationUser";
import { getParameterByName } from "../../services/ServiceHelper";

interface Props extends RouteComponentProps<any> {
    config: AppConfig;
    user: ApplicationUser | null;
    hasPaypal: boolean;
}

type State = {
    redirectTo: LocationDescriptor<any> | null;
    showGenericError: boolean;
};

export default class CheckoutRouter extends React.Component<Props, State> {
    redirectState: any = {};

    constructor(props: Props) {
        super(props);
        this.state = {
            redirectTo: null,
            showGenericError: false,
        };
    }

    componentDidMount() {

        document.title = "SermonCentral Payments - Checkout";

        if (this.props.user) {
            let currency = this.props.user.currency;

            if (this.props.hasPaypal) {

                (this.redirectState as any).previousLocation = "Main Purchase";
                this.setState({ redirectTo: { pathname: '/feature-unavailable', state: this.redirectState } });
            }
        }

        try {

            let mediaItemId = getParameterByName('mvId');
            if (mediaItemId) {
                mediaItemId = window.atob(mediaItemId);
            }

            let sermonSeriesKitId = getParameterByName('kId');
            if (sermonSeriesKitId) {
                sermonSeriesKitId = window.atob(sermonSeriesKitId);
            }

            let premiumSermonKitId = getParameterByName('pskId');
            if (premiumSermonKitId) {
                premiumSermonKitId = window.atob(premiumSermonKitId);
            }

            let churchMediaSetId = getParameterByName('cmsId');
            if (churchMediaSetId) {
                churchMediaSetId = window.atob(churchMediaSetId);
            }

            let userId = getParameterByName('cId');
            if (userId) {
                userId = window.atob(userId);
            }

            let code = getParameterByName('code');
            let returnUrl = getParameterByName('returnUrl');

            if (mediaItemId) {
                this.setState({ redirectTo: { pathname: `/purchase/media/${mediaItemId}`, state: { id: mediaItemId, userId: userId, code: code, returnUrl: returnUrl } } });

            }
            else if (sermonSeriesKitId) {
                this.setState({ redirectTo: { pathname: `/purchase/sermon-series-kit/${sermonSeriesKitId}`, state: { id: sermonSeriesKitId, userId: userId, code: code, returnUrl: returnUrl } } });
            }    
            else if (premiumSermonKitId) {
                this.setState({ redirectTo: { pathname: `/purchase/premium-sermon-kit/${premiumSermonKitId}`, state: { id: premiumSermonKitId, userId: userId, code: code, returnUrl: returnUrl } } });
            }    
            else if (churchMediaSetId) {
                this.setState({ redirectTo: { pathname: `/purchase/church-media-set/${churchMediaSetId}`, state: { id: churchMediaSetId, userId: userId, code: code, returnUrl: returnUrl } } });
            }
            else {
                this.setState({ showGenericError: true });
            }
        }
        catch {
            this.setState({ showGenericError: true });
        }
    }

    render() {

        if (this.state.redirectTo) {
            return (
                <Redirect to={this.state.redirectTo} />
            );
        }
        else if (this.state.showGenericError) {
            return (
                <div className="container py-5">
                    <div className="text-center py-5">
                        <h2>Something Went Wrong :'(</h2>
                        <h6>Please contact our <a href={`${this.props.config.mainSiteUrl}/contactus`}>customer service</a> team for help.</h6>
                    </div>
                </div>
            );
        }
        else {
            return (
                <img className="sc-content-spinner" src="//i.cdn-sc.com/Logos/sc-full-spinner-gray.gif" />
            );
        }
    }
}
