import * as React from 'react';
import { Link, RouteComponentProps, Redirect } from 'react-router-dom';

import ApplicationUser from '../../entities/ApplicationUser';
import AppConfig from '../../entities/AppConfig';

interface Props extends RouteComponentProps<{ id: string }> {
    user: ApplicationUser;
    config: AppConfig;
}

type State = {
    redirectToCancel: boolean;
    returnUrl: string;
};

declare const window: any;
declare const gtag: any;

export default class DowngradeConfirmation extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            redirectToCancel: false,
            returnUrl: this.props.config.mainSiteUrl
        };
    }

    componentDidMount() {

        document.title = "SermonCentral Payments - Downgrade Confirmation";

        let redirectState = this.props.location.state;

        // Unlikely scenario, but user shouldn't be on this page unless they've been redirected, 
        // so redirect back to /cancel
        if (!redirectState || !this.props.user.proSubscription || !(redirectState as any).redirectedFromDowngrade) {
            this.setState({
                redirectToCancel: true
            });
            return;
        }

        setTimeout(() => {

            if ('gtag' in window) {

                const proSubscription = this.props.user.proSubscription;
                const planName = proSubscription?.planName ?? "";
                var sku = '';
                if (planName.indexOf('Premium') >= 0) {
                    sku = '2c92a0fd61e79fd40161fc8fd3264af7';
                }
                else if (planName.indexOf('Basic') >= 0) {
                    sku = '2c92a0fe631cf034016321a3a6ee256c';
                }
                else if (planName.indexOf('Monthly') >= 0) {
                    sku = '2c92a0fb501f2f53015021c3b91c2cf3';
                }
                else {
                    sku = '2c92a0fb501f2f53015021c3b9082cf1';
                }

                // Price is set to be $109 (price change 6/9/21) off the nextBillPrice due to decision by Caleb 10/18.  He didn't feel like it was worth
                // getting what Zuora was actually going to charge after invoices were generated, that this price was to detail
                // the measurement of success of this flow, we don't calc revenue from it.  
                window.gaItem = {
                    "id": sku,
                    "name": "PRO Downgrade",
                    "brand": "SermonCentral",
                    "category": "PRO Subscription",
                    "quantity": "1",
                    "price": proSubscription?.nextBillPrice ? proSubscription.nextBillPrice : proSubscription?.planPrice,
                };
                window.gaTransaction = {
                    "transaction_id": this.props.user.id,
                    "affiliation": "SermonCentralPro",
                    "value": proSubscription?.nextBillPrice ? proSubscription.nextBillPrice : proSubscription?.planPrice,
                    "currency": "USD",
                    "shipping": "0",
                    "tax": "0",
                    "items": [
                        window.gaItem
                    ],
                    "coupon": (redirectState as any).appliedPromoCode || ''
                };

                gtag('event', 'purchase', window.gaTransaction);
            }

        }, 1000);
    }

    render() {

        if (this.state.redirectToCancel) {
            return <Redirect to={{ pathname: "/cancel" }} />;
        }



        return (
            <div className="row">
                <div className="offset-md-2 col-md-8">
                    <div className="text-center py-5">
                        <h2 className="mb-4">Thank you for your PRO subscription!</h2>
                        <h6 className="mb-4">
                            Return to SermonCentral to use PRO features, manage your subscription from your <a href={this.props.config.accountSiteUrl}>account dashboard</a>, or view your <a href={`${this.props.config.mainSiteUrl}/pro-benefits`}>PRO benefits</a>.
                        </h6>
                        <button className="btn btn-secondary ml-auto mr-auto d-block"><a href={this.state.returnUrl} className="buttonLink">RETURN TO SERMONCENTRAL</a></button>
                    </div>
                </div>
            </div>
        );

    }
}