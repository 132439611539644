import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import AppConfig from "../../entities/AppConfig";
import ApplicationUser from "../../entities/ApplicationUser";
import Country from "../../entities/Country";
import CreditCard from "../../entities/CreditCard";
import { CreditCardType } from "../../entities/CreditCardType";
import PostalCodeLocation from "../../entities/PostalCodeLocation";
import { ProCost, ProSubscriptionTier } from "../../entities/ProSubscription";
import Kit from "../../entities/Kit";
import KitPromoCode from "../../entities/KitPromoCode";
import KitPurchase from "../../entities/KitPurchase";
import SSLLogo from "../../resources/ssl.png";
import { CreditCardService } from "../../services/CreditCardService";
import { PostalCodeService } from "../../services/PostalCodeService";
import { ProSubscriptionService } from "../../services/ProSubscriptionService";
import { KitPurchaseService } from "../../services/KitPurchaseService";
import {
  FieldValidationError,
  getParameterByName,
  ServerModelValidationResponse,
  ServerResponse,
} from "../../services/ServiceHelper";
import { TaxService } from "../../services/TaxService";
import "./Kit.css";

interface Props extends RouteComponentProps<{ id: string }> {
  config: AppConfig;
  user: ApplicationUser;
  handleUpdateUser: (user: ApplicationUser | null) => void;
  hasPaypal: boolean;
}

type State = {
  loading: boolean;
  returnUrl: string;
  premiumSermonKit?: Kit;
  showGenericError: boolean;
  errors: FieldValidationError[];
  existingCreditCards: CreditCard[];
  selectedCreditCardId: string;
  showUseNewCardForm: boolean;
  nameOnCard: string;
  creditCardNumber: string;
  cv2: string;
  expirationMonth: number;
  expirationYear: number;
  postalCode: string;
  saveCard: boolean;
  creditCardType?: CreditCardType;
  phoneNumber: string;
  enteredPromoCode: string;
  appliedPromoCode?: KitPromoCode;
  enableSubmit: boolean;
  redirectToConfirmation: boolean;
  redirectToSubscriptionConfirmation: boolean;
  upsoldToProPremium: boolean;
  showLearnMoreProPremiumModal: boolean;
  upgradeToProPremium: boolean;
  upgradeToProPremiumCost?: number;
  countries: Country[];
  showCountries: boolean;
  validatedPostalCodeLocation?: PostalCodeLocation;
  selectedCountryShortCode?: string;
  taxAmount: number;
  newCardPostalCodeCheck?: string;
  taxIsZero: boolean;
};

export default class PurchasePremiumSermonKit extends React.Component<
  Props,
  State
> {
  redirectState: any = {};

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: true,
      returnUrl: "",
      showGenericError: false,
      errors: [],
      existingCreditCards: [],
      selectedCreditCardId: "",
      showUseNewCardForm: false,
      nameOnCard: "",
      creditCardNumber: "",
      cv2: "",
      expirationMonth: new Date().getMonth() + 1,
      expirationYear: new Date().getFullYear(),
      postalCode: "",
      saveCard: true,
      phoneNumber: "",
      enteredPromoCode: "",
      appliedPromoCode: undefined,
      enableSubmit: true,
      redirectToConfirmation: false,
      redirectToSubscriptionConfirmation: false,
      upsoldToProPremium: false,
      showLearnMoreProPremiumModal: false,
      upgradeToProPremium: false,
      upgradeToProPremiumCost: undefined,
      countries: [],
      showCountries: false,
      taxAmount: 0,
      taxIsZero: false,
    };
  }

  async componentDidMount() {
    document.title = "SermonCentral Payments - Purchase Premium Sermon Kit";

    if (this.props.hasPaypal) {
      this.props.history.replace("/feature-unavailable");
    }

    let redirectState = this.props.location.state || {};
    let id = (redirectState as any).id;
    if (!id) {
      id = this.props.match.params.id;
    }

    let returnUrl = (redirectState as any).returnUrl;
    if (!returnUrl) {
      returnUrl = getParameterByName("returnUrl");
    }

    let enteredPromoCode = (redirectState as any).code;
    if (!enteredPromoCode) {
      enteredPromoCode = getParameterByName("code");
    }

    this.setState({
      returnUrl: returnUrl || "",
      enteredPromoCode: enteredPromoCode || "",
    });

    if (id) {
      const responses = await Promise.all([
        KitPurchaseService.Get(id),
        ProSubscriptionService.getCountries(),
        CreditCardService.getCreditCards(),
      ]);

      const [pskResult, locationResult, creditCardsResult] = responses;

      let premiumSermonKit = await pskResult;
      if (!premiumSermonKit) {
        this.setState({ showGenericError: true, loading: false });
      } else if (!premiumSermonKit.listPrice && !premiumSermonKit.salePrice) {
        window.location.replace(premiumSermonKit.url);
      } else if (!premiumSermonKit.publiclyAvailable) {
        window.location.replace(premiumSermonKit.url);
      } else if (premiumSermonKit.alreadyPurchased) {
        window.location.replace(premiumSermonKit.url);
      } else if (
        this.props.user.proSubscription &&
        this.props.user.proSubscription.tier >= ProSubscriptionTier.Premium
      ) {
        window.location.replace(premiumSermonKit.url);
      } else {
        this.setState({ premiumSermonKit: premiumSermonKit });

        if (
          this.props.user.proSubscription &&
          !this.props.user.proSubscription.inTrial &&
          this.props.user.proSubscription.tier < ProSubscriptionTier.Premium
        ) {
          ProSubscriptionService.getUpgradeToProPremiumCost().then((cost) => {
            this.setState({
              upgradeToProPremiumCost: cost,
            });
          });
        }

        if (ServerResponse.isError(locationResult)) {
          const serverError: FieldValidationError = {
            field: "",
            errors: ["Unable to retrieve data to load. Please try again."],
          };
          this.setState({ errors: [serverError], enableSubmit: true });
        } else if (ServerResponse.isSuccess<Country[]>(locationResult)) {
          this.setState({
            countries: locationResult.data,
          });
        }

        if (ServerResponse.isError(creditCardsResult)) {
          const serverError: FieldValidationError = {
            field: "",
            errors: ["Unable to retrieve credit cards. Please try again."],
          };
          this.setState({ errors: [serverError], enableSubmit: true });
        } else {
          if (creditCardsResult && creditCardsResult.length > 0) {
            var defaultCard = creditCardsResult.find(
              (cc) => cc.useCardByDefault
            );
            this.setState({
              existingCreditCards: creditCardsResult,
              selectedCreditCardId: defaultCard
                ? defaultCard.id
                : creditCardsResult[0].id,
            });
          }
        }

        if (enteredPromoCode) {
          await this.validatePromoCode();
        }

        this.setState({ loading: false });
      }
    } else {
      this.setState({ showGenericError: true, loading: false });
    }
  }

  handleCheckTaxFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (this.state.newCardPostalCodeCheck) {
      this.setState({ enableSubmit: false });
      var price =
        KitPurchaseService.GetPriceIncludingDiscountAndTax(
          this.state.premiumSermonKit!,
          this.state.taxAmount,
          this.state.appliedPromoCode
        ).toFixed(2);

      var taxRequest = await TaxService.getTaxAmount(
        this.state.newCardPostalCodeCheck,
        false,
        +price
      );

      this.setState({ enableSubmit: true, errors: [] });

      if (taxRequest == null) {
        const error = {
          field: "TaxCheck",
          errors: [
            "There was an error calculating tax, please check postal code and try again.",
          ],
        };
        this.setState({
          errors: [error],
          enableSubmit: true,
          taxAmount: 0,
          taxIsZero: false,
        });
      } else {
        this.setState({ taxAmount: taxRequest, taxIsZero: taxRequest === 0 });
      }
    }
  };

  handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!this.state.enableSubmit) {
      return;
    }

    // If user has existing cards on file, but none are selected, and not showing new card form, return
    if (
      this.state.existingCreditCards.length > 0 &&
      !this.state.selectedCreditCardId &&
      !this.state.showUseNewCardForm
    ) {
      return;
    }

    // If no existing cards on file or showing new card form, and any of the fields are not entered, return
    if (
      (this.state.existingCreditCards.length === 0 ||
        this.state.showUseNewCardForm) &&
      (!this.state.nameOnCard ||
        !this.state.creditCardNumber ||
        !this.state.cv2 ||
        !this.state.expirationMonth ||
        !this.state.expirationYear)
    ) {
      return;
    }

    if (
      !this.state.showUseNewCardForm &&
      this.state.existingCreditCards.length > 0
    ) {
      this.submitData();
    } else if (this.state.postalCode.length === 0) {
      const serverError: FieldValidationError = {
        field: "CreditCardPostalCode",
        errors: ["Zip/Postal code is required"],
      };
      this.setState({ errors: [serverError] });
      return;
    } else {
      if (this.state.selectedCountryShortCode) {
        this.setState(
          {
            validatedPostalCodeLocation: {
              country: this.state.selectedCountryShortCode,
              region: "",
              postalCode: this.state.postalCode,
            },
          },
          () => this.submitData()
        );
      } else {
        let postalCodeLocation = await PostalCodeService.getUSCanPostalCode(
          this.state.postalCode
        );

        if (postalCodeLocation === null) {
          const serverError: FieldValidationError = {
            field: "UnknownPostalCode",
            errors: ["Please select your country"],
          };
          this.setState({ showCountries: true, errors: [serverError] });
          return;
        } else {
          this.setState(
            { validatedPostalCodeLocation: postalCodeLocation },
            () => this.submitData()
          );
        }
      }
    }
  };

  submitData = async () => {
    try {
      this.setState({ enableSubmit: false });

      let response = null;
      if (!this.state.upsoldToProPremium && !this.state.upgradeToProPremium) {
        response = await KitPurchaseService.purchaseKit(
          this.state.premiumSermonKit!.id,
          !this.state.showUseNewCardForm ? this.state.selectedCreditCardId : "",
          this.state.nameOnCard,
          this.state.creditCardNumber,
          this.state.cv2,
          this.state.expirationMonth,
          this.state.expirationYear,
          this.state.postalCode,
          this.state.saveCard,
          this.state.appliedPromoCode ? this.state.appliedPromoCode.code : "",
          this.state.validatedPostalCodeLocation?.region,
          this.state.validatedPostalCodeLocation?.country
        );

        if (ServerResponse.isSuccess<KitPurchase>(response)) {
          this.redirectState = {
            premiumSermonKit: this.state.premiumSermonKit,
            premiumSermonKitPurchase: response.data,
            returnUrl: this.state.returnUrl,
            appliedPromoCode: this.state.appliedPromoCode
              ? this.state.appliedPromoCode.code
              : "",
          };
          this.setState({ errors: [], redirectToConfirmation: true });
          return;
        }
      } else if (this.state.upsoldToProPremium) {
        if (this.state.phoneNumber.trim().length === 0) {
          const serverError: FieldValidationError = {
            field: "PhoneNumber",
            errors: ["Phone number cannot be empty"],
          };
          this.setState({ errors: [serverError], enableSubmit: true });
          return;
        }

        response =
          await KitPurchaseService.subscribeToProPremiumAndGiftKit(
            this.state.premiumSermonKit!.id,
            !this.state.showUseNewCardForm
              ? this.state.selectedCreditCardId
              : "",
            this.state.nameOnCard,
            this.state.creditCardNumber,
            this.state.cv2,
            this.state.expirationMonth,
            this.state.expirationYear,
            this.state.postalCode,
            this.state.phoneNumber,
            this.state.validatedPostalCodeLocation?.region,
            this.state.validatedPostalCodeLocation?.country
          );

        if (ServerResponse.isSuccess<ApplicationUser>(response)) {
          this.props.handleUpdateUser(response.data);
          this.redirectState = {
            premiumSermonKit: this.state.premiumSermonKit,
            returnUrl: this.state.returnUrl,
          };
          this.setState({
            errors: [],
            redirectToSubscriptionConfirmation: true,
          });
          return;
        }
      } else if (this.state.upgradeToProPremium) {
        if (this.state.phoneNumber.trim().length === 0) {
          const serverError: FieldValidationError = {
            field: "PhoneNumber",
            errors: ["Phone number cannot be empty"],
          };
          this.setState({ errors: [serverError], enableSubmit: true });
          return;
        }

        response =
          await KitPurchaseService.upgradeToProPremiumAndGiftKit(
            this.state.premiumSermonKit!.id,
            !this.state.showUseNewCardForm
              ? this.state.selectedCreditCardId
              : "",
            this.state.nameOnCard,
            this.state.creditCardNumber,
            this.state.cv2,
            this.state.expirationMonth,
            this.state.expirationYear,
            this.state.postalCode,
            this.state.phoneNumber,
            this.state.validatedPostalCodeLocation?.region,
            this.state.validatedPostalCodeLocation?.country
          );

        if (ServerResponse.isSuccess<ApplicationUser>(response)) {
          this.props.handleUpdateUser(response.data);
          this.redirectState = {
            premiumSermonKit: this.state.premiumSermonKit,
            returnUrl: this.state.returnUrl,
          };
          this.setState({
            errors: [],
            redirectToSubscriptionConfirmation: true,
          });
          return;
        }
      }

      if (
        ServerModelValidationResponse.isServerModelValidationResponse(response)
      ) {
        if (response.valid) {
          const serverError: FieldValidationError = {
            field: "",
            errors: ["An unknown error occurred. Please try again."],
          };
          this.setState({ errors: [serverError], enableSubmit: true });
        } else {
          this.setState({ errors: response.errors, enableSubmit: true });
        }
      } else {
        const serverError: FieldValidationError = {
          field: "",
          errors: [response!.message],
        };
        this.setState({ errors: [serverError], enableSubmit: true });
      }
    } catch (errorResult) {
      const serverError: FieldValidationError = {
        field: "",
        errors: ["An unknown error occurred. Please try again."],
      };
      this.setState({ errors: [serverError], enableSubmit: true });
    }
  };

  updateCv2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (
      this.state.creditCardType &&
      this.state.creditCardType === CreditCardType.Amex
    ) {
      this.setState({ cv2: event.target.value.substr(0, 4) });
    } else {
      this.setState({ cv2: event.target.value.substr(0, 3) });
    }
  };

  updateCreditCard = (event: React.ChangeEvent<HTMLInputElement>) => {
    let cardNumber = event.target.value.replace(/[a-zA-Z\-\ ]+/g, "");
    let newCardNumber = "";
    let creditCardType;

    // American Express XXXX XXXXXX XXXXX
    if (cardNumber[0] === "3") {
      newCardNumber = cardNumber.substr(0, 4);
      if (cardNumber.length > 4) {
        newCardNumber += " " + cardNumber.substr(4, 6);
      }

      if (cardNumber.length > 10) {
        newCardNumber += " " + cardNumber.substr(10, 5);
      }

      creditCardType = CreditCardType.Amex;
    }

    // Visa XXXX XXXX XXXX XXXX
    // Mastercard XXXX XXXX XXXX XXXX
    // Discover XXXX XXXX XXXX XXXX
    else if (
      cardNumber[0] === "4" ||
      cardNumber[0] === "5" ||
      cardNumber[0] === "6"
    ) {
      newCardNumber = cardNumber.substr(0, 4);
      if (cardNumber.length > 4) {
        newCardNumber += " " + cardNumber.substr(4, 4);
      }

      if (cardNumber.length > 8) {
        newCardNumber += " " + cardNumber.substr(8, 4);
      }

      if (cardNumber.length > 12) {
        newCardNumber += " " + cardNumber.substr(12, 4);
      }

      if (cardNumber[0] === "4") {
        creditCardType = CreditCardType.Visa;
      } else if (cardNumber[0] === "5") {
        creditCardType = CreditCardType.Mastercard;
      } else if (cardNumber[0] === "6") {
        creditCardType = CreditCardType.Discover;
      }
    }

    this.setState({ creditCardNumber: newCardNumber, creditCardType });
  };

  handlePromoCodeFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await this.validatePromoCode();
  };

  checkTax = async (postalCode: string) => {
    var price =
      KitPurchaseService.GetPriceIncludingDiscountAndTax(
        this.state.premiumSermonKit!,
        this.state.taxAmount,
        this.state.appliedPromoCode
      ).toFixed(2);

    var taxRequest = await TaxService.getTaxAmount(postalCode, false, +price);

    this.setState({ taxAmount: taxRequest == null ? 0 : taxRequest });
  };

  async validatePromoCode() {
    if (!this.state.enableSubmit) {
      return;
    }

    if (this.state.enteredPromoCode) {
      try {
        this.setState({ enableSubmit: false });

        const response = await KitPurchaseService.validatePromoCode(
          this.state.enteredPromoCode,
          this.state.premiumSermonKit!.id
        );

        if (ServerResponse.isSuccess<KitPromoCode>(response)) {
          this.setState(
            {
              appliedPromoCode: response.data,
              enableSubmit: true,
              errors: [],
            },
            () =>
              this.checkTax(
                this.state.existingCreditCards.length > 0 &&
                  !this.state.showUseNewCardForm &&
                  this.state.newCardPostalCodeCheck
                  ? this.state.newCardPostalCodeCheck
                  : this.state.postalCode
              )
          );
        } else if (
          ServerModelValidationResponse.isServerModelValidationResponse(
            response
          )
        ) {
          if (response.valid) {
            const serverError: FieldValidationError = {
              field: "",
              errors: ["An unknown error occurred. Please try again."],
            };
            this.setState({ errors: [serverError], enableSubmit: true });
          } else {
            this.setState({ errors: response.errors, enableSubmit: true });
          }
        } else if (ServerResponse.isError(response)) {
          const serverError: FieldValidationError = {
            field: "",
            errors: [response.message],
          };
          this.setState({ errors: [serverError], enableSubmit: true });
        } else {
          const serverError: FieldValidationError = {
            field: "",
            errors: ["An unknown error occurred. Please try again."],
          };
          this.setState({ errors: [serverError], enableSubmit: true });
        }
      } catch (errorResult) {
        const serverError: FieldValidationError = {
          field: "",
          errors: ["An unknown error occurred. Please try again."],
        };
        this.setState({ errors: [serverError], enableSubmit: true });
      }
    }
  }

  removePromoCode = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    this.setState({
      enteredPromoCode: "",
      appliedPromoCode: undefined,
    });
  };

  render() {
    if (this.state.redirectToConfirmation) {
      return (
        <Redirect
          to={{
            pathname: `/purchase/premium-sermon-kit/${
              this.state.premiumSermonKit!.id
            }/confirmation`,
            state: this.redirectState,
          }}
        />
      );
    } else if (this.state.redirectToSubscriptionConfirmation) {
      return (
        <Redirect
          to={{
            pathname: `/purchase/premium-sermon-kit/${
              this.state.premiumSermonKit!.id
            }/subscription-confirmation`,
            state: this.redirectState,
          }}
        />
      );
    } else if (this.state.loading) {
      return (
        <img
          className="sc-content-spinner"
          src="//i.cdn-sc.com/Logos/sc-full-spinner-gray.gif"
        />
      );
    } else if (this.state.showGenericError) {
      return (
        <div className="container py-5">
          <div className="text-center py-5">
            <h2>Something Went Wrong :'(</h2>
            <h6>
              Please contact our{" "}
              <a href={`${this.props.config.mainSiteUrl}/contactus`}>
                customer service
              </a>{" "}
              team for help.
            </h6>
          </div>
        </div>
      );
    } else if (this.state.premiumSermonKit) {
      var defaultCard = this.state.existingCreditCards.find(
        (cc) => cc.useCardByDefault
      );
      if (!defaultCard) {
        defaultCard =
          this.state.existingCreditCards.length > 0
            ? this.state.existingCreditCards[0]
            : undefined;
      }

      return (
        <div className="py-5">
          {FieldValidationError.hasGenericError(this.state.errors) && (
            <div
              className="alert alert-danger"
              role="alert"
              dangerouslySetInnerHTML={{
                __html: FieldValidationError.getGenericErrorSummary(
                  this.state.errors
                ),
              }}
            />
          )}
          <div className="row">
            <div className="col-lg-8 order-12 order-lg-1">
              <form method="post" onSubmit={this.handleFormSubmit}>
                <div className="card mb-3">
                  <div className="card-header primary-card-header">
                    <h5 className="text-white">Billing Info</h5>
                  </div>
                  <div className="card-body">
                    {this.state.existingCreditCards.length > 0 &&
                      !this.state.showUseNewCardForm && (
                        <>
                          <div className="mb-3">
                            <a
                              href="#"
                              className="float-right"
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({ showUseNewCardForm: true });
                              }}
                            >
                              use a new card
                            </a>
                            <label htmlFor="savedPaymentMethod">
                              Saved Payment Method
                            </label>
                            <div className="input-group">
                              <select
                                className="form-control form-control-lg"
                                id="savedPaymentMethod"
                                value={this.state.selectedCreditCardId}
                                onChange={(e) =>
                                  this.setState({
                                    selectedCreditCardId: e.target.value,
                                  })
                                }
                              >
                                {this.state.existingCreditCards.map((cc) => (
                                  <option key={cc.id} value={cc.id}>
                                    {cc.type} {cc.maskedNumber} (expires:{" "}
                                    {cc.expirationMonth}/{cc.expirationYear})
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div
                              className="invalid-feedback"
                              dangerouslySetInnerHTML={{
                                __html:
                                  FieldValidationError.getFieldErrorSummary(
                                    this.state.errors,
                                    "SavedPaymentMethod"
                                  ),
                              }}
                            />
                          </div>
                          {(this.state.upsoldToProPremium ||
                            this.state.upgradeToProPremium) &&
                            this.state.selectedCreditCardId &&
                            defaultCard &&
                            this.state.selectedCreditCardId !==
                              defaultCard.id && (
                              <div
                                className="alert alert-info mb-3"
                                role="alert"
                              >
                                Your default card will be updated to the card
                                you selected.
                              </div>
                            )}
                        </>
                      )}

                    {(this.state.existingCreditCards.length === 0 ||
                      this.state.showUseNewCardForm) && (
                      <>
                        {this.state.existingCreditCards.length > 0 && (
                          <a
                            href="#"
                            className="float-right"
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({ showUseNewCardForm: false });
                            }}
                          >
                            use an existing card
                          </a>
                        )}
                        <div className="mb-3">
                          <label htmlFor="nameOnCard">Name on card</label>
                          <input
                            type="text"
                            className={
                              FieldValidationError.isFieldInError(
                                this.state.errors,
                                "CreditCardName"
                              )
                                ? "form-control-lg form-control is-invalid"
                                : "form-control-lg form-control"
                            }
                            id="nameOnCard"
                            autoComplete="cc-name"
                            required
                            value={this.state.nameOnCard}
                            onChange={(e) =>
                              this.setState({ nameOnCard: e.target.value })
                            }
                          />
                          <div
                            className="invalid-feedback"
                            dangerouslySetInnerHTML={{
                              __html: FieldValidationError.getFieldErrorSummary(
                                this.state.errors,
                                "CreditCardName"
                              ),
                            }}
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="creditCardNumber">
                            Credit card number
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              className={
                                FieldValidationError.isFieldInError(
                                  this.state.errors,
                                  "CreditCardNumber"
                                )
                                  ? "form-control-lg form-control is-invalid"
                                  : "form-control-lg form-control"
                              }
                              id="creditCardNumber"
                              autoComplete="cc-number"
                              required
                              value={this.state.creditCardNumber}
                              onChange={this.updateCreditCard}
                            />
                            <div className="input-group-append">
                              {(!this.state.creditCardType ||
                                this.state.creditCardType ===
                                  CreditCardType.Amex) && (
                                <span className="input-group-text cc-amex">
                                  <FontAwesomeIcon icon={["fab", "cc-amex"]} />
                                </span>
                              )}
                              {(!this.state.creditCardType ||
                                this.state.creditCardType ===
                                  CreditCardType.Discover) && (
                                <span className="input-group-text cc-discover">
                                  <FontAwesomeIcon
                                    icon={["fab", "cc-discover"]}
                                  />
                                </span>
                              )}
                              {(!this.state.creditCardType ||
                                this.state.creditCardType ===
                                  CreditCardType.Mastercard) && (
                                <span className="input-group-text cc-mastercard">
                                  <FontAwesomeIcon
                                    icon={["fab", "cc-mastercard"]}
                                  />
                                </span>
                              )}
                              {(!this.state.creditCardType ||
                                this.state.creditCardType ===
                                  CreditCardType.Visa) && (
                                <span className="input-group-text cc-visa">
                                  <FontAwesomeIcon icon={["fab", "cc-visa"]} />
                                </span>
                              )}
                            </div>
                            <div
                              className="invalid-feedback"
                              dangerouslySetInnerHTML={{
                                __html:
                                  FieldValidationError.getFieldErrorSummary(
                                    this.state.errors,
                                    "CreditCardNumber"
                                  ),
                              }}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6 mb-3">
                            <label htmlFor="cv2">CVC/CVV</label>
                            <input
                              type="text"
                              className={
                                FieldValidationError.isFieldInError(
                                  this.state.errors,
                                  "CreditCardSecurityCode"
                                )
                                  ? "form-control-lg form-control is-invalid"
                                  : "form-control-lg form-control"
                              }
                              id="cv2"
                              autoComplete="cc-csc"
                              required
                              value={this.state.cv2}
                              onChange={this.updateCv2}
                            />
                            <div
                              className="invalid-feedback"
                              dangerouslySetInnerHTML={{
                                __html:
                                  FieldValidationError.getFieldErrorSummary(
                                    this.state.errors,
                                    "CreditCardSecurityCode"
                                  ),
                              }}
                            />
                          </div>
                          <div className="d-none d-md-block col-md-6 mb-3">
                            <p className="small pt-5">
                              The 3 or 4 digits near the signature strip
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6 mb-3">
                            <label htmlFor="expirationMonth">
                              Expiration Date
                            </label>
                            <div className="input-group">
                              <select
                                className="form-control form-control-lg"
                                id="expirationMonth"
                                autoComplete="cc-exp-month"
                                value={this.state.expirationMonth}
                                onChange={(e) =>
                                  this.setState({
                                    expirationMonth: parseInt(
                                      e.target.value,
                                      10
                                    ),
                                  })
                                }
                              >
                                {[...Array(12).keys()].map((i) => {
                                  return (
                                    <option key={i + 1} value={i + 1}>
                                      {i + 1}
                                    </option>
                                  );
                                })}
                              </select>
                              <select
                                className="form-control form-control-lg"
                                id="expirationYear"
                                autoComplete="cc-exp-year"
                                value={this.state.expirationYear}
                                onChange={(e) =>
                                  this.setState({
                                    expirationYear: parseInt(
                                      e.target.value,
                                      10
                                    ),
                                  })
                                }
                              >
                                {[...Array(10).keys()].map((i) => {
                                  var year = new Date().getFullYear();
                                  return (
                                    <option key={i + year} value={i + year}>
                                      {i + year}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            <div
                              className="invalid-feedback"
                              dangerouslySetInnerHTML={{
                                __html:
                                  FieldValidationError.getFieldErrorSummary(
                                    this.state.errors,
                                    "CreditCardExpirationMonth"
                                  ),
                              }}
                            />
                            <div
                              className="invalid-feedback"
                              dangerouslySetInnerHTML={{
                                __html:
                                  FieldValidationError.getFieldErrorSummary(
                                    this.state.errors,
                                    "CreditCardExpirationYear"
                                  ),
                              }}
                            />
                          </div>
                          <div className="col-md-6 mb-3">
                            <label htmlFor="postalCode">
                              ZIP / Postal code
                            </label>
                            <input
                              type="text"
                              className={
                                FieldValidationError.isFieldInError(
                                  this.state.errors,
                                  "CreditCardPostalCode"
                                )
                                  ? "form-control-lg form-control is-invalid"
                                  : "form-control-lg form-control"
                              }
                              id="postalCode"
                              autoComplete="shipping postal-code"
                              value={this.state.postalCode}
                              onChange={(e) =>
                                this.setState({ postalCode: e.target.value })
                              }
                              onBlur={(e) =>
                                this.checkTax(e.target.value as string)
                              }
                            />
                            <div
                              className="invalid-feedback"
                              dangerouslySetInnerHTML={{
                                __html:
                                  FieldValidationError.getFieldErrorSummary(
                                    this.state.errors,
                                    "CreditCardPostalCode"
                                  ),
                              }}
                            />
                          </div>
                        </div>
                        {this.state.showCountries && (
                          <div className="row">
                            <div className="col-12 mb-3">
                              <div className="input-group input-soft-border">
                                <select
                                  className="form-control form-control-lg select-soft"
                                  id="country"
                                  value={
                                    this.state.selectedCountryShortCode
                                      ? this.state.selectedCountryShortCode
                                      : ""
                                  }
                                  onChange={(e) =>
                                    this.setState({
                                      selectedCountryShortCode: e.target.value,
                                    })
                                  }
                                >
                                  <option value="">Select country...</option>
                                  {this.state.countries.map((c) => (
                                    <option
                                      key={c.twoCharCode}
                                      value={c.threeCharCode}
                                    >
                                      {c.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              {FieldValidationError.isFieldInError(
                                this.state.errors,
                                "UnknownPostalCode"
                              ) && (
                                <div
                                  className="need-more-feedback"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      FieldValidationError.getFieldErrorSummary(
                                        this.state.errors,
                                        "UnknownPostalCode"
                                      ),
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        )}
                        {(this.state.upsoldToProPremium ||
                          this.state.upgradeToProPremium) &&
                          this.state.existingCreditCards.length > 0 && (
                            <div className="alert alert-info" role="alert">
                              Your default card will be updated to the card you
                              enter.
                            </div>
                          )}
                        {!this.state.upsoldToProPremium &&
                          !this.state.upgradeToProPremium && (
                            <div className="mb-3">
                              <label>
                                <input
                                  type="checkbox"
                                  checked={this.state.saveCard}
                                  id="saveCard"
                                  onChange={(e) =>
                                    this.setState({
                                      saveCard: !this.state.saveCard,
                                    })
                                  }
                                />
                                &nbsp; Save this card
                              </label>
                            </div>
                          )}
                      </>
                    )}

                    {(this.state.upsoldToProPremium ||
                      this.state.upgradeToProPremium) && (
                      <div className="mb-3">
                        <label htmlFor="phoneNumber">Phone Number</label>
                        <input
                          type="tel"
                          className={
                            FieldValidationError.isFieldInError(
                              this.state.errors,
                              "PhoneNumber"
                            )
                              ? "form-control-lg form-control is-invalid"
                              : "form-control-lg form-control"
                          }
                          id="phoneNumber"
                          required
                          value={this.state.phoneNumber}
                          onChange={(e) =>
                            this.setState({ phoneNumber: e.target.value })
                          }
                        />
                        <div
                          className="invalid-feedback"
                          dangerouslySetInnerHTML={{
                            __html: FieldValidationError.getFieldErrorSummary(
                              this.state.errors,
                              "PhoneNumber"
                            ),
                          }}
                        />
                      </div>
                    )}

                    {!this.props.user.proSubscription && (
                      <>
                        <hr />
                        <div className="mb-2">
                          <label>
                            <input
                              type="checkbox"
                              checked={this.state.upsoldToProPremium}
                              id="agreeToTerms"
                              onChange={(e) =>
                                this.setState({
                                  upsoldToProPremium:
                                    !this.state.upsoldToProPremium,
                                })
                              }
                            />
                            &nbsp; Sign up for a 1 year{" "}
                            <strong>
                              <span className="text-primary">
                                Premium PRO Membership for ${ProCost.premium}
                              </span>{" "}
                              and receive this and all Premium Sermon Kits on
                              SermonCentral for FREE!
                            </strong>
                          </label>
                          <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({
                                showLearnMoreProPremiumModal: true,
                              });
                            }}
                          >
                            Learn more about this offer
                          </a>
                        </div>
                        <hr />
                      </>
                    )}

                    {this.props.user.proSubscription &&
                      !this.props.user.proSubscription.inTrial &&
                      this.props.user.proSubscription.tier <
                        ProSubscriptionTier.Premium && (
                        <>
                          <hr />
                          <div className="mb-2">
                            <label className="checkbox-container">
                              <input
                                type="checkbox"
                                checked={this.state.upgradeToProPremium}
                                onChange={(e) =>
                                  this.setState({
                                    upgradeToProPremium:
                                      !this.state.upgradeToProPremium,
                                  })
                                }
                              />
                              <span className="checkmark"></span>
                              Upgrade your current PRO subscription to{" "}
                              <strong>
                                <span className="text-primary">
                                  Premium PRO
                                </span>{" "}
                                and receive this and all Premium Sermon Kits on
                                SermonCentral for FREE!
                              </strong>
                              {this.state.upgradeToProPremiumCost && (
                                <span>
                                  {" "}
                                  Instead of paying the full ${ProCost.premium}, we'll prorate
                                  the upgrade fee by $
                                  {Math.max(
                                    0,
                                    Math.round(
                                      (ProCost.premium -
                                        this.state.upgradeToProPremiumCost) *
                                        100
                                    ) / 100
                                  )}
                                  . (Proration amount based on the amount of
                                  time left in your current PRO subscription)
                                </span>
                              )}
                            </label>
                            <a
                              href="#"
                              style={{ marginLeft: "35px" }}
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                  showLearnMoreProPremiumModal: true,
                                });
                              }}
                            >
                              Learn more about this offer
                            </a>
                          </div>
                          <hr />
                        </>
                      )}

                    {this.state.showLearnMoreProPremiumModal && (
                      <div
                        className="custom-modal-background"
                        onClick={(e) => {
                          this.setState({
                            showLearnMoreProPremiumModal: false,
                          });
                        }}
                      >
                        <div
                          className="custom-modal"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={(e) => {
                              this.setState({
                                showLearnMoreProPremiumModal: false,
                              });
                            }}
                          >
                            <span aria-hidden="true">x</span>
                          </button>
                          <div className="custom-modal-body">
                            <p>
                              <strong>
                                With a Premium PRO Membership you will receive:
                              </strong>
                            </p>
                            <ul>
                              <li>Access to all Premium Sermon Series and Premium Sermon Kits</li>
                              <li>Over 800 video illustrations</li>
                              <li>Over 750 preaching slides</li>
                              <li>Over 40 worship music videos</li>
                              <li>Over 40 countdown, service starters</li>
                              <li>Over 350 motion backgrounds</li>
                              <li>Over 700 still backgrounds</li>
                              <li>Over 1,000 royalty-free images</li>
                              <li>Access top rated illustrations</li>
                              <li>View sermons on a single page</li>
                              <li>Download sermons as a PDF</li>
                              <li>Lectionary calendar sermon search</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    )}

                    <button
                      className="btn btn-primary btn-block btn-lg mb-4"
                      type="submit"
                      disabled={!this.state.enableSubmit}
                    >
                      {this.state.enableSubmit &&
                        !this.state.upsoldToProPremium &&
                        !this.state.upgradeToProPremium && <>PURCHASE</>}
                      {this.state.enableSubmit &&
                        (this.state.upsoldToProPremium ||
                          this.state.upgradeToProPremium) && <>SUBSCRIBE</>}
                      {!this.state.enableSubmit && (
                        <>
                          PLEASE WAIT...
                          <div
                            className="spinner-border spinner-border-sm ml-4 mb-1"
                            role="status"
                          />
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-lg-4 order-1 order-lg-2">
              <div className="card mb-3">
                <div className="card-header secondary-card-header">
                  <h5>
                    <span className="text-white">
                      Selected Premium Sermon Kit
                    </span>
                  </h5>
                </div>
                <div className="card-body">
                  <div className="row mb-3">
                    <div className="col-md-3 p-0">
                      <img
                        className="img-fluid mx-auto d-block"
                        src={this.state.premiumSermonKit.image}
                      />
                    </div>
                    <div className="col-md-9">
                      <h6
                        className="mb-0"
                        dangerouslySetInnerHTML={{
                          __html: this.state.premiumSermonKit.name,
                        }}
                      />
                      <small
                        className="mb-2"
                        dangerouslySetInnerHTML={{
                          __html:
                            this.state.premiumSermonKit.description.substr(
                              0,
                              200
                            ) + "...",
                        }}
                      />
                    </div>
                  </div>

                  {!this.state.upsoldToProPremium &&
                    !this.state.upgradeToProPremium && (
                      <>
                        {!this.state.appliedPromoCode && (
                          <form
                            onSubmit={this.handlePromoCodeFormSubmit}
                            className="mt-3"
                          >
                            <div className="input-group">
                              <input
                                type="text"
                                placeholder="Promo code"
                                className={
                                  FieldValidationError.isFieldInError(
                                    this.state.errors,
                                    "PromoCode"
                                  )
                                    ? "form-control is-invalid"
                                    : "form-control"
                                }
                                required
                                value={this.state.enteredPromoCode}
                                onChange={(e) =>
                                  this.setState({
                                    enteredPromoCode: e.target.value,
                                  })
                                }
                              />
                              <div className="input-group-append">
                                <button
                                  type="submit"
                                  className="btn btn-secondary"
                                  disabled={!this.state.enableSubmit}
                                >
                                  Apply
                                </button>
                              </div>
                              <div
                                className="invalid-feedback"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    FieldValidationError.getFieldErrorSummary(
                                      this.state.errors,
                                      "PromoCode"
                                    ),
                                }}
                              />
                            </div>
                          </form>
                        )}

                        {this.state.appliedPromoCode && (
                          <div className="mt-3">
                            <strong>Promo applied:</strong>
                            &nbsp;
                            <strong style={{ color: "#004a87" }}>
                              {this.state.appliedPromoCode.code}
                            </strong>
                            &nbsp; &nbsp;
                            <a
                              href="#"
                              className="text-danger"
                              onClick={this.removePromoCode}
                            >
                              x
                            </a>
                          </div>
                        )}
                      </>
                    )}
                  {this.state.existingCreditCards.length > 0 &&
                    !this.state.showUseNewCardForm && (
                      <form
                        className="mt-3"
                        onSubmit={this.handleCheckTaxFormSubmit}
                      >
                        <div className="input-group">
                          <input
                            type="text"
                            placeholder="Postal Code"
                            className={
                              FieldValidationError.isFieldInError(
                                this.state.errors,
                                "TaxCheck"
                              )
                                ? "form-control is-invalid"
                                : `form-control ${
                                    this.state.taxIsZero ? "is-valid" : ""
                                  }`
                            }
                            value={this.state.newCardPostalCodeCheck}
                            onChange={(e) =>
                              this.setState({
                                newCardPostalCodeCheck: e.target.value,
                              })
                            }
                          />
                          <div className="input-group-append">
                            <button
                              type="submit"
                              className="btn btn-secondary"
                              disabled={!this.state.enableSubmit}
                            >
                              Check Tax
                            </button>
                          </div>
                          <div className="valid-feedback">
                            No tax for this postal code
                          </div>
                          <div
                            className="invalid-feedback"
                            dangerouslySetInnerHTML={{
                              __html: FieldValidationError.getFieldErrorSummary(
                                this.state.errors,
                                "TaxCheck"
                              ),
                            }}
                          />
                        </div>
                        <small>
                          Taxes may apply to this purchase, enter your postal
                          code to check.
                        </small>
                      </form>
                    )}
                  {(this.state.upsoldToProPremium ||
                    this.state.upgradeToProPremium) && (
                    <div className="float-right mt-3">
                      <span className="mr-2">Total (USD)</span>
                      <strong id="total-price">FREE</strong>
                    </div>
                  )}
                  {!this.state.upsoldToProPremium &&
                    !this.state.upgradeToProPremium && (
                      <>
                        {this.state.taxAmount > 0 && (
                          <>
                            <div style={{ display: "flex" }}>
                              <div style={{ flex: 1 }} />
                              <div className="mt-3" style={{ opacity: 0.8 }}>
                                <span style={{ minWidth: 108, float: "left" }}>
                                  Subtotal
                                </span>
                                {KitPurchaseService.GetPriceIncludingDiscountAndTax(
                                  this.state.premiumSermonKit!,
                                  0,
                                  this.state.appliedPromoCode
                                ).toFixed(2)}
                              </div>
                            </div>
                            <div style={{ display: "flex" }}>
                              <div style={{ flex: 1 }} />
                              <div style={{ opacity: 0.8 }}>
                                <span style={{ minWidth: 116, float: "left" }}>
                                  Tax
                                </span>
                                {this.state.taxAmount}
                              </div>
                            </div>
                            <div style={{ display: "flex" }}>
                              <div style={{ flex: 1 }} />
                              <div>
                                <span
                                  style={{
                                    fontWeight: 500,
                                    minWidth: 100,
                                    float: "left",
                                  }}
                                >
                                  Total (USD)
                                </span>
                                $
                                {KitPurchaseService.GetPriceIncludingDiscountAndTax(
                                  this.state.premiumSermonKit!,
                                  this.state.taxAmount,
                                  this.state.appliedPromoCode
                                ).toFixed(2)}
                              </div>
                            </div>
                          </>
                        )}
                        {this.state.taxAmount === 0 && (
                          <div className="float-right mt-3">
                            <span className="mr-2">Total (USD)</span>$
                            {KitPurchaseService.GetPriceIncludingDiscountAndTax(
                              this.state.premiumSermonKit!,
                              this.state.taxAmount,
                              this.state.appliedPromoCode
                            ).toFixed(2)}
                          </div>
                        )}
                      </>
                    )}
                </div>
              </div>

              {this.state.upsoldToProPremium && (
                <div className="card mb-3">
                  <div className="card-header secondary-card-header">
                    <h5>
                      <span className="text-white">Selected PRO Plan</span>
                    </h5>
                  </div>
                  <div className="card-body">
                    <strong>PRO Premium</strong> -&nbsp;
                    <span className="pro-description-secondary">
                      <span>
                        ${ProCost.premium}/yr with full media access + all Premium Sermon Kits
                      </span>
                    </span>
                    <p className="small mt-3">
                      Please note that your subscription will renew
                      automatically unless you cancel prior to renewal. We may
                      contact you with important information regarding your PRO
                      subscription. Standard data fees and text messaging rates
                      may apply based on your plan with your mobile phone
                      carrier.
                    </p>
                  </div>
                </div>
              )}

              {this.state.upgradeToProPremium && (
                <div className="card mb-3">
                  <div className="card-header secondary-card-header">
                    <h5>
                      <span className="text-white">Selected PRO Plan</span>
                    </h5>
                  </div>
                  <div className="card-body">
                    <strong>PRO Premium</strong> -&nbsp;
                    <span className="pro-description-secondary">
                      <span>
                        $
                        {this.state.upgradeToProPremiumCost
                          ? this.state.upgradeToProPremiumCost
                          : ProCost.premium}{" "}
                        with full media access + all Premium Sermon Kits
                      </span>
                    </span>
                    <p className="small mt-3">
                      Please note that your subscription will renew
                      automatically at ${ProCost.premium}/yr unless you cancel prior to
                      renewal.
                    </p>
                  </div>
                </div>
              )}

              <div className="d-none d-lg-block">
                <div className="row py-5 mt-3">
                  <div className="col-sm-3 mt-1 p-1">
                    <img className="img-fluid" src={SSLLogo} />
                  </div>
                  <div className="col-sm-9">
                    <h6>SSL Secure Connection</h6>
                    <small>
                      This a secure transaction with all information sent from
                      our registration page to our secure servers encrypted for
                      your protection.
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
