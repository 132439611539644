import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as React from "react";
import {Redirect, RouteComponentProps} from "react-router-dom";
import ApplicationUser from "../../entities/ApplicationUser";
import Country from "../../entities/Country";
import CreditCard from "../../entities/CreditCard";
import {CreditCardType} from "../../entities/CreditCardType";
import PostalCodeLocation from "../../entities/PostalCodeLocation";
import ProPlan from "../../entities/ProPlan";
import ProSubscriptionPromoCode from "../../entities/ProSubscriptionPromoCode";
import SSLLogo from "../../resources/ssl.png";
import {CreditCardService} from "../../services/CreditCardService";
import {PostalCodeService} from "../../services/PostalCodeService";
import {ProSubscriptionService} from "../../services/ProSubscriptionService";
import {FieldValidationError, ServerModelValidationResponse, ServerResponse,} from "../../services/ServiceHelper";
import {TaxService} from "../../services/TaxService";
import {EventTracking} from "../../utilities/EventTracking";
import {ProPlanIdDetermination} from "../../utilities/ProPlanIdDetermination";
import {ProSubscriptionTier} from "../../entities/ProSubscription";

interface Props extends RouteComponentProps<any> {
  user: ApplicationUser;
  hasPaypal: boolean;
  handleUpdateUser: (user: ApplicationUser | null) => void;
}

type State = {
  loading: boolean;
  proPlans: ProPlan[];
  redirectToSubscribe: boolean;
  errors: FieldValidationError[];
  existingCreditCards: CreditCard[];
  selectedCreditCardId: string;
  showUseNewCardForm: boolean;
  nameOnCard: string;
  creditCardNumber: string;
  cv2: string;
  expirationMonth: number;
  expirationYear: number;
  postalCode: string;
  phoneNumber: string;
  hidePhoneNumberCapture: boolean;
  creditCardType?: CreditCardType;
  selectedProPlanId: string;
  enteredPromoCode: string;
  appliedPromoCode?: ProSubscriptionPromoCode;
  enableSubmit: boolean;
  redirectToConfirmation: boolean;
  cameFromCreateAccount: boolean;
  promoCodeCollapsed: boolean;
  excludeTrial: boolean;
  showPayPalOption: boolean;
  proSubscriptionSelectionExpanded: boolean;
  countries: Country[];
  showCountries: boolean;
  validatedPostalCodeLocation?: PostalCodeLocation;
  selectedCountryShortCode?: string;
  taxIsZero: boolean;
  taxAmount: number;
  newCardPostalCodeCheck?: string;
  includeBasicTrial: boolean;
};

export default class SubscribeBillingInfo extends React.Component<
  Props,
  State
> {
  redirectState: any = {};
  dataLayer = ((window as any).dataLayer = (window as any).dataLayer || []);

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: true,
      proPlans: [],
      redirectToSubscribe: false,
      errors: [],
      existingCreditCards: [],
      selectedCreditCardId: "",
      showUseNewCardForm: false,
      nameOnCard: "",
      creditCardNumber: "",
      cv2: "",
      expirationMonth: new Date().getMonth() + 1,
      expirationYear: new Date().getFullYear(),
      postalCode: "",
      phoneNumber: "",
      hidePhoneNumberCapture: false,
      selectedProPlanId: "AnnualPlus",
      enteredPromoCode: "",
      appliedPromoCode: undefined,
      enableSubmit: true,
      redirectToConfirmation: false,
      cameFromCreateAccount: false,
      promoCodeCollapsed: true,
      excludeTrial: false,
      showPayPalOption: false,
      proSubscriptionSelectionExpanded: false,
      countries: [],
      showCountries: false,
      taxIsZero: false,
      taxAmount: 0,
      includeBasicTrial: false,
    };
  }

  async componentDidMount() {
    document.title = "SermonCentral Payments - Billing Info";

    let redirectState = this.props.location.state;

    // This scenario happens if user signed up internationally using paypal(NOT PHP) then they cancelled and are wanting to activate again. We don't support changing gateways via payments yet and it has to be done manually.
    if (this.props.hasPaypal) {
      this.props.history.replace("/feature-unavailable");
    }

    // Unlikely scenario, but user shouldn't be on this page unless they've been redirected,
    // so redirect back to /subscribe
    if (!redirectState) {
      this.setState({
        redirectToSubscribe: true,
      });
    } else if (this.props.user.currency && this.props.user.currency !== "USD") {
      this.setState({
        redirectToSubscribe: true,
      });
    } else {
      const phoneNumber = (redirectState as any).phoneNumber || "";
      const hidePhoneNumberCapture =
        (redirectState as any).hidePhoneNumberCapture || false;
      let selectedProPlanId =
        (redirectState as any).selectedProPlanId || "AnnualPlus";
      let enteredPromoCode = (redirectState as any).utm_promo || "";
      let cameFromCreateAccount =
        (redirectState as any).cameFromCreateAccount || false;
      let showPayPalOption = (redirectState as any).paypal || false;

      if (!enteredPromoCode) {
        enteredPromoCode = (redirectState as any).enteredPromoCode || "";
      }

      if ((redirectState as any).pre_selected_plan) {
        selectedProPlanId = ProPlanIdDetermination.getProPlanNameById(
          (redirectState as any).pre_selected_plan
        );
      }

      let excludeTrial = false;
      if (
        (redirectState as any).excludeTrial &&
        (redirectState as any).excludeTrial === "1"
      ) {
        excludeTrial = true;
      }

      let includeBasicTrial = false;
      if (
          (redirectState as any).includeBasicTrial &&
          (redirectState as any).includeBasicTrial === "1"
      ) {
        includeBasicTrial = true;
      }


      delete (redirectState as any).phoneNumber;
      delete (redirectState as any).hidePhoneNumberCapture;
      delete (redirectState as any).pre_selected_plan;
      delete (redirectState as any).utm_promo;
      delete (redirectState as any).excludeTrial;
      delete (redirectState as any).includeBasicTrial;

      this.redirectState = redirectState;
      this.setState({
        phoneNumber: phoneNumber,
        hidePhoneNumberCapture: hidePhoneNumberCapture,
        selectedProPlanId: selectedProPlanId,
        proSubscriptionSelectionExpanded: selectedProPlanId.length === 0,
        enteredPromoCode: enteredPromoCode,
        cameFromCreateAccount: cameFromCreateAccount,
        excludeTrial,
        includeBasicTrial,
        showPayPalOption: showPayPalOption,
      });
      const responses = await Promise.all([
        ProSubscriptionService.getProPlans(),
        ProSubscriptionService.getCountries(),
        CreditCardService.getCreditCards(),
      ]);

      this.setState({ loading: false });
      const [proPlansResult, locationResult, creditCardsResult] = responses;

      if (ServerResponse.isError(proPlansResult) || !proPlansResult) {
        const serverError: FieldValidationError = {
          field: "",
          errors: ["Unable to retrieve plans. Please try again."],
        };
        this.setState({ errors: [serverError], enableSubmit: true });
      } else {
        this.setState({ proPlans: proPlansResult });
      }

      if (ServerResponse.isError(locationResult)) {
        const serverError: FieldValidationError = {
          field: "",
          errors: ["Unable to retrieve data to load. Please try again."],
        };
        this.setState({ errors: [serverError], enableSubmit: true });
      } else if (ServerResponse.isSuccess<Country[]>(locationResult)) {
        this.setState({
          countries: locationResult.data,
        });
      }

      if (ServerResponse.isError(creditCardsResult)) {
        const serverError: FieldValidationError = {
          field: "",
          errors: ["Unable to retrieve credit cards. Please try again."],
        };
        this.setState({ errors: [serverError], enableSubmit: true });
      } else {
        if (creditCardsResult && creditCardsResult.length > 0) {
          var defaultCard = creditCardsResult.find((cc) => cc.useCardByDefault);
          this.setState({
            existingCreditCards: creditCardsResult,
            selectedCreditCardId: defaultCard
              ? defaultCard.id
              : creditCardsResult[0].id,
            loading: false,
          });
        }
      }

      // In the case where the user was already signed-in, automatically apply the promo code now
      if (this.state.appliedPromoCode === undefined && enteredPromoCode) {
        await this.validatePromoCode();
      }

      ProSubscriptionService.startCheckout();

      EventTracking.BillingInfo.onPageLoad();
    }
  }

  componentDidUpdate() {
    this.dataLayer.push({ event: "optimize.activate" });
  }

  checkTax = async (postalCode: string) => {
    var selectedProPlan = this.state.proPlans.find(
      (p) => p.id === this.state.selectedProPlanId
    );

    if (!selectedProPlan) {
      selectedProPlan = this.state.proPlans.find((p) => p.id === "AnnualPlus");
    }

    if (!selectedProPlan) {
      return;
    }

    let totalPrice = ProSubscriptionService.getProPlanPriceIncludingDiscount(
      selectedProPlan,
      this.state.appliedPromoCode
    ).toFixed(2);

    var taxRequest = await TaxService.getTaxAmount(
      postalCode,
      true,
      +totalPrice
    );

    this.setState({ taxAmount: taxRequest == null ? 0 : taxRequest });
  };

  handleCheckTaxFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    var selectedProPlan = this.state.proPlans.find(
      (p) => p.id === this.state.selectedProPlanId
    );

    if (!selectedProPlan) {
      selectedProPlan = this.state.proPlans.find((p) => p.id === "AnnualPlus");
    }

    if (!selectedProPlan) {
      return;
    }

    if (this.state.newCardPostalCodeCheck) {
      this.setState({ enableSubmit: false });

      let totalPrice = ProSubscriptionService.getProPlanPriceIncludingDiscount(
        selectedProPlan,
        this.state.appliedPromoCode
      );

      var taxRequest = await TaxService.getTaxAmount(
        this.state.newCardPostalCodeCheck,
        true,
        +totalPrice
      );

      this.setState({ enableSubmit: true, errors: [] });

      if (taxRequest == null) {
        const error = {
          field: "TaxCheck",
          errors: [
            "There was an error calculating tax, please check postal code and try again.",
          ],
        };
        this.setState({
          errors: [error],
          enableSubmit: true,
          taxAmount: 0,
          taxIsZero: false,
        });
      } else {
        this.setState({ taxAmount: taxRequest, taxIsZero: taxRequest === 0 });
      }
    }
  };

  handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!this.state.enableSubmit) {
      return;
    }

    // If user has existing cards on file, but none are selected, and not showing new card form, return
    if (
      this.state.existingCreditCards.length > 0 &&
      !this.state.selectedCreditCardId &&
      !this.state.showUseNewCardForm
    ) {
      return;
    }

    // If no existing cards on file or showing new card form, and any of the fields are not entered, return
    if (
      (this.state.existingCreditCards.length === 0 ||
        this.state.showUseNewCardForm) &&
      (!this.state.nameOnCard ||
        !this.state.creditCardNumber ||
        !this.state.cv2 ||
        !this.state.expirationMonth ||
        !this.state.expirationYear)
    ) {
      return;
    }

    if (
      !this.state.showUseNewCardForm &&
      this.state.existingCreditCards.length > 0
    ) {
      this.submitData();
    } else if (this.state.postalCode.length === 0) {
      const serverError: FieldValidationError = {
        field: "CreditCardPostalCode",
        errors: ["Zip/Postal code is required"],
      };
      this.setState({ errors: [serverError] });
      return;
    } else {
      if (this.state.selectedCountryShortCode) {
        this.setState(
          {
            validatedPostalCodeLocation: {
              country: this.state.selectedCountryShortCode,
              region: "",
              postalCode: this.state.postalCode,
            },
          },
          () => this.submitData()
        );
      } else {
        let postalCodeLocation = await PostalCodeService.getUSCanPostalCode(
          this.state.postalCode
        );

        if (postalCodeLocation === null) {
          const serverError: FieldValidationError = {
            field: "UnknownPostalCode",
            errors: ["Please select your country"],
          };
          this.setState({ showCountries: true, errors: [serverError] });
          return;
        } else {
          this.setState(
            { validatedPostalCodeLocation: postalCodeLocation },
            () => this.submitData()
          );
        }
      }
    }
  };

  submitData = async () => {
    try {
      this.setState({ enableSubmit: false });

      let response;
      if (this.state.selectedProPlanId === "AnnualBasic") {
        response = await ProSubscriptionService.subscribeToAnnualBasic(
          !this.state.showUseNewCardForm ? this.state.selectedCreditCardId : "",
          this.state.nameOnCard,
          this.state.creditCardNumber,
          this.state.cv2,
          this.state.expirationMonth,
          this.state.expirationYear,
          this.state.postalCode,
          this.state.phoneNumber,
          this.state.appliedPromoCode ? this.state.appliedPromoCode.code : "",
          this.redirectState["pageTemplate"] || "",
          this.redirectState["url_ref"] || "",
          this.redirectState["utm_campaign"] || "",
          this.redirectState["utm_content"] || "",
          this.redirectState["utm_medium"] || "",
          this.redirectState["utm_source"] || "",
          this.state.includeBasicTrial,
          this.state.validatedPostalCodeLocation?.region,
          this.state.validatedPostalCodeLocation?.country
        );
      } else if (
        this.state.selectedProPlanId === "AnnualPlus" ||
        !this.state.selectedProPlanId
      ) {
        response = await ProSubscriptionService.subscribeToAnnualPlus(
          !this.state.showUseNewCardForm ? this.state.selectedCreditCardId : "",
          this.state.nameOnCard,
          this.state.creditCardNumber,
          this.state.cv2,
          this.state.expirationMonth,
          this.state.expirationYear,
          this.state.postalCode,
          this.state.phoneNumber,
          this.state.appliedPromoCode ? this.state.appliedPromoCode.code : "",
          this.redirectState["pageTemplate"] || "",
          this.redirectState["url_ref"] || "",
          this.redirectState["utm_campaign"] || "",
          this.redirectState["utm_content"] || "",
          this.redirectState["utm_medium"] || "",
          this.redirectState["utm_source"] || "",
          this.state.excludeTrial,
          this.state.validatedPostalCodeLocation?.region,
          this.state.validatedPostalCodeLocation?.country
        );
      } else if (this.state.selectedProPlanId === "AnnualPremium") {
        response = await ProSubscriptionService.subscribeToAnnualPremium(
          !this.state.showUseNewCardForm ? this.state.selectedCreditCardId : "",
          this.state.nameOnCard,
          this.state.creditCardNumber,
          this.state.cv2,
          this.state.expirationMonth,
          this.state.expirationYear,
          this.state.postalCode,
          this.state.phoneNumber,
          this.state.appliedPromoCode ? this.state.appliedPromoCode.code : "",
          this.redirectState["pageTemplate"] || "",
          this.redirectState["url_ref"] || "",
          this.redirectState["utm_campaign"] || "",
          this.redirectState["utm_content"] || "",
          this.redirectState["utm_medium"] || "",
          this.redirectState["utm_source"] || "",
          this.state.validatedPostalCodeLocation?.region,
          this.state.validatedPostalCodeLocation?.country
        );
      } else if (this.state.selectedProPlanId === "MonthlyPlus") {
        response = await ProSubscriptionService.subscribeToMonthlyPlus(
          !this.state.showUseNewCardForm ? this.state.selectedCreditCardId : "",
          this.state.nameOnCard,
          this.state.creditCardNumber,
          this.state.cv2,
          this.state.expirationMonth,
          this.state.expirationYear,
          this.state.postalCode,
          this.state.phoneNumber,
          this.state.appliedPromoCode ? this.state.appliedPromoCode.code : "",
          this.redirectState["pageTemplate"] || "",
          this.redirectState["url_ref"] || "",
          this.redirectState["utm_campaign"] || "",
          this.redirectState["utm_content"] || "",
          this.redirectState["utm_medium"] || "",
          this.redirectState["utm_source"] || "",
          this.state.validatedPostalCodeLocation?.region,
          this.state.validatedPostalCodeLocation?.country
        );
      }

      if (ServerResponse.isSuccess<ApplicationUser>(response)) {
        EventTracking.BillingInfo.onBillingInfoSubmitted();
        this.props.handleUpdateUser(response.data);
        this.redirectState = {
          returnUrl: (this.redirectState as any).returnUrl,
          redirectedFromBillingInfo: true,
          appliedPromoCode: this.state.appliedPromoCode
            ? this.state.appliedPromoCode.code
            : "",
        };
        this.setState({ errors: [], redirectToConfirmation: true });
      } else if (
        ServerModelValidationResponse.isServerModelValidationResponse(response)
      ) {
        if (response.valid) {
          const serverError: FieldValidationError = {
            field: "",
            errors: ["An unknown error occurred. Please try again."],
          };
          this.setState({ errors: [serverError], enableSubmit: true });
        } else {
          this.setState({ errors: response.errors, enableSubmit: true });
        }
      } else if (ServerResponse.isError(response)) {
        const serverError: FieldValidationError = {
          field: "",
          errors: [response.message],
        };
        this.setState({ errors: [serverError], enableSubmit: true });
      } else {
        const serverError: FieldValidationError = {
          field: "",
          errors: ["An unknown error occurred. Please try again."],
        };
        this.setState({ errors: [serverError], enableSubmit: true });
      }
    } catch (errorResult) {
      const serverError: FieldValidationError = {
        field: "",
        errors: ["An unknown error occurred. Please try again."],
      };
      this.setState({ errors: [serverError], enableSubmit: true });
    }
  };

  updateCv2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (
      this.state.creditCardType &&
      this.state.creditCardType === CreditCardType.Amex
    ) {
      this.setState({ cv2: event.target.value.substr(0, 4) });
    } else {
      this.setState({ cv2: event.target.value.substr(0, 3) });
    }
  };

  updateCreditCard = (event: React.ChangeEvent<HTMLInputElement>) => {
    let cardNumber = event.target.value.replace(/[a-zA-Z\-\ ]+/g, "");
    let newCardNumber = "";
    let creditCardType;

    // American Express XXXX XXXXXX XXXXX
    if (cardNumber[0] === "3") {
      newCardNumber = cardNumber.substr(0, 4);
      if (cardNumber.length > 4) {
        newCardNumber += " " + cardNumber.substr(4, 6);
      }

      if (cardNumber.length > 10) {
        newCardNumber += " " + cardNumber.substr(10, 5);
      }

      creditCardType = CreditCardType.Amex;
    }

    // Visa XXXX XXXX XXXX XXXX
    // Mastercard XXXX XXXX XXXX XXXX
    // Discover XXXX XXXX XXXX XXXX
    else if (
      cardNumber[0] === "4" ||
      cardNumber[0] === "5" ||
      cardNumber[0] === "6"
    ) {
      newCardNumber = cardNumber.substr(0, 4);
      if (cardNumber.length > 4) {
        newCardNumber += " " + cardNumber.substr(4, 4);
      }

      if (cardNumber.length > 8) {
        newCardNumber += " " + cardNumber.substr(8, 4);
      }

      if (cardNumber.length > 12) {
        newCardNumber += " " + cardNumber.substr(12, 4);
      }

      if (cardNumber[0] === "4") {
        creditCardType = CreditCardType.Visa;
      } else if (cardNumber[0] === "5") {
        creditCardType = CreditCardType.Mastercard;
      } else if (cardNumber[0] === "6") {
        creditCardType = CreditCardType.Discover;
      }
    }

    this.setState({ creditCardNumber: newCardNumber, creditCardType });
  };

  handlePromoCodeFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await this.validatePromoCode();
  };

  async validatePromoCode() {
    if (!this.state.enableSubmit) {
      return;
    }

    if (this.state.enteredPromoCode) {
      try {
        this.setState({ enableSubmit: false });
        EventTracking.BillingInfo.onPromoCodeSubmitted();
        let proPlan = this.state.proPlans.find(
          (p) => p.id === this.state.selectedProPlanId
        );
        if (!proPlan) {
          proPlan = this.state.proPlans.find((p) => p.id === "AnnualPlus")!;
        }

        const response = await ProSubscriptionService.validatePromoCode(
          this.state.enteredPromoCode,
          proPlan.term,
          proPlan.tier
        );

        if (ServerResponse.isSuccess<ProSubscriptionPromoCode>(response)) {
          this.setState(
            {
              appliedPromoCode: response.data,
              enableSubmit: true,
              errors: [],
            },
            () =>
              this.checkTax(
                this.state.existingCreditCards.length > 0 &&
                  !this.state.showUseNewCardForm &&
                  this.state.newCardPostalCodeCheck
                  ? this.state.newCardPostalCodeCheck
                  : this.state.postalCode
              )
          );
        } else if (
          ServerModelValidationResponse.isServerModelValidationResponse(
            response
          )
        ) {
          if (response.valid) {
            const serverError: FieldValidationError = {
              field: "",
              errors: ["An unknown error occurred. Please try again."],
            };
            this.setState({ errors: [serverError], enableSubmit: true });
          } else {
            this.setState({ errors: response.errors, enableSubmit: true });
          }
        } else if (ServerResponse.isError(response)) {
          const serverError: FieldValidationError = {
            field: "",
            errors: [response.message],
          };
          this.setState({ errors: [serverError], enableSubmit: true });
        } else {
          const serverError: FieldValidationError = {
            field: "",
            errors: ["An unknown error occurred. Please try again."],
          };
          this.setState({ errors: [serverError], enableSubmit: true });
        }
      } catch (errorResult) {
        const serverError: FieldValidationError = {
          field: "",
          errors: ["An unknown error occurred. Please try again."],
        };
        this.setState({ errors: [serverError], enableSubmit: true });
      }
    }
  }

  onPlanChange(plan: ProPlan) {
    if (this.state.selectedProPlanId === "") {
      EventTracking.CreateAccount.onSelectPlan(`${plan.name}-${plan.id}`);
    } else {
      EventTracking.CreateAccount.onChangePlan(`${plan.name}-${plan.id}`);
    }

    this.setState(
      {
        selectedProPlanId: plan.id,
        proSubscriptionSelectionExpanded: false,
      },
      () => this.checkTax(this.state.postalCode)
    );
  }

  isTrialAvailable = (plan: ProPlan): boolean => {

    let plusTrialAvailable =
        plan.tier === ProSubscriptionTier.Plus &&
        plan.allowTrial &&
        !this.state.appliedPromoCode &&
        !(this.props.user && this.props.user.hasEverHadProSubscription) &&
        !(this.state.excludeTrial);

    let basicTrialAvailable =
        plan.tier === ProSubscriptionTier.Basic &&
        plan.allowTrial &&
        !this.state.appliedPromoCode &&
        !(this.props.user && this.props.user.hasEverHadProSubscription) &&
        this.state.includeBasicTrial;

    return plusTrialAvailable || basicTrialAvailable;
  }

  renderPlanEntry(plan: ProPlan) {
    let totalPrice = ProSubscriptionService.getProPlanPriceIncludingDiscount(
      plan,
      this.state.appliedPromoCode
    );
    let monthlyPrice =
      plan.billingPeriod === "yr"
        ? ProSubscriptionService.getMonthlyPrice(totalPrice)
        : totalPrice;

    let trialAvailable = this.isTrialAvailable(plan);

    return (
      <div key={plan.id} className="form-check mb-2 plan-option">
        <input
          className="form-check-input"
          type="radio"
          name="SelectedPlan"
          id={plan.id}
          checked={
            this.state.selectedProPlanId === plan.id ||
            (!this.state.selectedProPlanId && plan.id === "AnnualPlus")
          }
          onChange={() => this.onPlanChange(plan)}
          onClick={() => this.onPlanChange(plan)}
        />
        <label
          id={`${plan.id}-label`}
          className="form-check-label"
          htmlFor={plan.id}
        >
          {trialAvailable ? (
            <strong>Free Today&nbsp;</strong>
          ) : (
            <strong>{plan.name}&nbsp;</strong>
          )}
          <span className="pro-description-secondary">
            {trialAvailable && <span>{plan.preludeDescription}&nbsp;</span>}$
            {monthlyPrice}
            {plan.description}
            {plan.postDescriptionTrial == null
              ? plan.postDescription.length > 0
                ? ` $${totalPrice}${
                    this.state.taxAmount > 0 &&
                    this.state.selectedProPlanId === plan.id
                      ? ` (with tax $${this.state.taxAmount + totalPrice})`
                      : ""
                  }. ${plan.postDescription}`
                : this.state.taxAmount > 0 &&
                  this.state.selectedProPlanId === plan.id
                ? ` (with tax $${this.state.taxAmount + totalPrice})`
                : "."
              : ` $${totalPrice}${
                  this.state.taxAmount > 0 &&
                  this.state.selectedProPlanId === plan.id
                    ? ` (with tax $${this.state.taxAmount + totalPrice})`
                    : ""
                }. ${plan.postDescriptionTrial}`}
          </span>
        </label>
      </div>
    );
  }

  render() {
    if (this.state.redirectToSubscribe) {
      return (
        <Redirect to={{ pathname: "/subscribe", state: this.redirectState }} />
      );
    }

    if (this.state.redirectToConfirmation) {
      return (
        <Redirect
          to={{
            pathname: "/subscribe/confirmation",
            state: this.redirectState,
          }}
        />
      );
    }

    if (this.state.loading) {
      return (
        <img
          className="sc-content-spinner"
          src="//i.cdn-sc.com/Logos/sc-full-spinner-gray.gif"
        />
      );
    }

    var selectedProPlan = this.state.proPlans.find(
      (p) => p.id === this.state.selectedProPlanId
    );
    var defaultCard = this.state.existingCreditCards.find(
      (cc) => cc.useCardByDefault
    );

    return (
      <div className="py-5">
        {FieldValidationError.hasGenericError(this.state.errors) && (
          <div
            className="alert alert-danger"
            role="alert"
            dangerouslySetInnerHTML={{
              __html: FieldValidationError.getGenericErrorSummary(
                this.state.errors
              ),
            }}
          />
        )}
        {this.state.cameFromCreateAccount && (
          <div className="steps">
            <div className="step inactive-step">1</div>
            <span id="crumb-one">Create Account</span>
            <div className="step active-step">2</div>
            <span id="crumb-two">Billing Info</span>
          </div>
        )}
        <div className="row">
          <div className="col-lg-8 order-12 order-lg-1">
            <form method="post" onSubmit={this.handleFormSubmit}>
              <div className="card mb-3">
                <div className="card-header primary-card-header">
                  <h5 id="form-title" className="text-white">
                    Billing Info
                  </h5>
                </div>
                <div className="card-body">
                  {this.state.existingCreditCards.length > 0 &&
                    !this.state.showUseNewCardForm && (
                      <>
                        <div className="mb-3">
                          <a
                            id="new-card-link"
                            href="#"
                            className="float-right"
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({ showUseNewCardForm: true });
                            }}
                          >
                            use a new card
                          </a>
                          <label
                            id="saved-method-label"
                            htmlFor="savedPaymentMethod"
                          >
                            Saved Payment Method
                          </label>
                          <div className="input-group">
                            <select
                              className="form-control form-control-lg"
                              id="savedPaymentMethod"
                              value={this.state.selectedCreditCardId}
                              onChange={(e) =>
                                this.setState({
                                  selectedCreditCardId: e.target.value,
                                })
                              }
                            >
                              {this.state.existingCreditCards.map((cc) => (
                                <option key={cc.id} value={cc.id}>
                                  {cc.type} {cc.maskedNumber} (expires:{" "}
                                  {cc.expirationMonth}/{cc.expirationYear})
                                </option>
                              ))}
                            </select>
                          </div>
                          <div
                            className="invalid-feedback"
                            dangerouslySetInnerHTML={{
                              __html: FieldValidationError.getFieldErrorSummary(
                                this.state.errors,
                                "SavedPaymentMethod"
                              ),
                            }}
                          />
                        </div>
                        {this.state.selectedCreditCardId &&
                          defaultCard &&
                          this.state.selectedCreditCardId !==
                            defaultCard.id && (
                            <div
                              id="saved-default-text"
                              className="alert alert-info mb-3"
                              role="alert"
                            >
                              Your default card will be updated to the card you
                              selected.
                            </div>
                          )}
                      </>
                    )}
                  {(this.state.existingCreditCards.length === 0 ||
                    this.state.showUseNewCardForm) && (
                    <>
                      {this.state.existingCreditCards.length > 0 && (
                        <a
                          id="existing-card-link"
                          href="#"
                          className="float-right"
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({ showUseNewCardForm: false });
                          }}
                        >
                          use an existing card
                        </a>
                      )}
                      <div className="mb-3">
                        <label id="card-name-label" htmlFor="nameOnCard">
                          Name on card
                        </label>
                        <input
                          type="text"
                          className={
                            FieldValidationError.isFieldInError(
                              this.state.errors,
                              "CreditCardName"
                            )
                              ? "form-control-lg form-control is-invalid"
                              : "form-control-lg form-control"
                          }
                          id="nameOnCard"
                          autoComplete="cc-name"
                          required
                          value={this.state.nameOnCard}
                          onChange={(e) =>
                            this.setState({ nameOnCard: e.target.value })
                          }
                        />
                        <div
                          className="invalid-feedback"
                          dangerouslySetInnerHTML={{
                            __html: FieldValidationError.getFieldErrorSummary(
                              this.state.errors,
                              "CreditCardName"
                            ),
                          }}
                        />
                      </div>
                      <div className="mb-3">
                        <label
                          id="card-number-label"
                          htmlFor="creditCardNumber"
                        >
                          Credit card number
                        </label>
                        <div className="input-group">
                          <input
                            type="text"
                            className={
                              FieldValidationError.isFieldInError(
                                this.state.errors,
                                "CreditCardNumber"
                              )
                                ? "form-control-lg form-control is-invalid"
                                : "form-control-lg form-control"
                            }
                            id="creditCardNumber"
                            autoComplete="cc-number"
                            required
                            value={this.state.creditCardNumber}
                            onChange={this.updateCreditCard}
                          />
                          <div className="input-group-append">
                            {(!this.state.creditCardType ||
                              this.state.creditCardType ===
                                CreditCardType.Amex) && (
                              <span className="input-group-text cc-amex d-none d-sm-flex">
                                <FontAwesomeIcon icon={["fab", "cc-amex"]} />
                              </span>
                            )}
                            {(!this.state.creditCardType ||
                              this.state.creditCardType ===
                                CreditCardType.Discover) && (
                              <span className="input-group-text cc-discover d-none d-sm-flex">
                                <FontAwesomeIcon
                                  icon={["fab", "cc-discover"]}
                                />
                              </span>
                            )}
                            {(!this.state.creditCardType ||
                              this.state.creditCardType ===
                                CreditCardType.Mastercard) && (
                              <span className="input-group-text cc-mastercard d-none d-sm-flex">
                                <FontAwesomeIcon
                                  icon={["fab", "cc-mastercard"]}
                                />
                              </span>
                            )}
                            {(!this.state.creditCardType ||
                              this.state.creditCardType ===
                                CreditCardType.Visa) && (
                              <span className="input-group-text cc-visa d-none d-sm-flex">
                                <FontAwesomeIcon icon={["fab", "cc-visa"]} />
                              </span>
                            )}
                          </div>
                          <div
                            className="invalid-feedback"
                            dangerouslySetInnerHTML={{
                              __html: FieldValidationError.getFieldErrorSummary(
                                this.state.errors,
                                "CreditCardNumber"
                              ),
                            }}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 mb-3">
                          <label id="cvc-label" htmlFor="cv2">
                            CVC/CVV
                          </label>
                          <input
                            type="text"
                            className={
                              FieldValidationError.isFieldInError(
                                this.state.errors,
                                "CreditCardSecurityCode"
                              )
                                ? "form-control-lg form-control is-invalid"
                                : "form-control-lg form-control"
                            }
                            id="cv2"
                            autoComplete="cc-csc"
                            required
                            value={this.state.cv2}
                            onChange={this.updateCv2}
                          />
                          <div
                            className="invalid-feedback"
                            dangerouslySetInnerHTML={{
                              __html: FieldValidationError.getFieldErrorSummary(
                                this.state.errors,
                                "CreditCardSecurityCode"
                              ),
                            }}
                          />
                        </div>
                        <div className="d-none d-md-block col-md-6 mb-3">
                          <p id="cvc-explanation" className="small pt-5">
                            The 3 or 4 digits near the signature strip
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 mb-3">
                          <label
                            id="card-expirty-label"
                            htmlFor="expirationMonth"
                          >
                            Expiration Date
                          </label>
                          <div className="input-group">
                            <select
                              className="form-control form-control-lg"
                              id="expirationMonth"
                              autoComplete="cc-exp-month"
                              value={this.state.expirationMonth}
                              onChange={(e) =>
                                this.setState({
                                  expirationMonth: parseInt(e.target.value, 10),
                                })
                              }
                            >
                              {[...Array(12).keys()].map((i) => {
                                return (
                                  <option key={i + 1} value={i + 1}>
                                    {i + 1}
                                  </option>
                                );
                              })}
                            </select>
                            <select
                              className="form-control form-control-lg"
                              id="expirationYear"
                              autoComplete="cc-exp-year"
                              value={this.state.expirationYear}
                              onChange={(e) =>
                                this.setState({
                                  expirationYear: parseInt(e.target.value, 10),
                                })
                              }
                            >
                              {[...Array(10).keys()].map((i) => {
                                var year = new Date().getFullYear();
                                return (
                                  <option key={i + year} value={i + year}>
                                    {i + year}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          <div
                            className="invalid-feedback"
                            dangerouslySetInnerHTML={{
                              __html: FieldValidationError.getFieldErrorSummary(
                                this.state.errors,
                                "CreditCardExpirationMonth"
                              ),
                            }}
                          />
                          <div
                            className="invalid-feedback"
                            dangerouslySetInnerHTML={{
                              __html: FieldValidationError.getFieldErrorSummary(
                                this.state.errors,
                                "CreditCardExpirationYear"
                              ),
                            }}
                          />
                        </div>
                        <div className="col-md-6 mb-3">
                          <label id="postal-label" htmlFor="postalCode">
                            ZIP / Postal code
                          </label>
                          <input
                            type="text"
                            className={
                              FieldValidationError.isFieldInError(
                                this.state.errors,
                                "CreditCardPostalCode"
                              )
                                ? "form-control-lg form-control is-invalid"
                                : "form-control-lg form-control"
                            }
                            id="postalCode"
                            autoComplete="shipping postal-code"
                            value={this.state.postalCode}
                            onChange={(e) =>
                              this.setState({ postalCode: e.target.value })
                            }
                            onBlur={(e) =>
                              this.checkTax(e.target.value as string)
                            }
                          />
                          <div
                            className="invalid-feedback"
                            dangerouslySetInnerHTML={{
                              __html: FieldValidationError.getFieldErrorSummary(
                                this.state.errors,
                                "CreditCardPostalCode"
                              ),
                            }}
                          />
                        </div>
                      </div>
                      {this.state.existingCreditCards.length > 0 && (
                        <div
                          id="new-default-text"
                          className="alert alert-info mb-3"
                          role="alert"
                        >
                          Your default card will be updated to the card you
                          enter.
                        </div>
                      )}
                    </>
                  )}
                  {this.state.showCountries && (
                    <div className="row">
                      <div className="col-12 mb-3">
                        <div className="input-group input-soft-border">
                          <select
                            className="form-control form-control-lg select-soft"
                            id="country"
                            value={
                              this.state.selectedCountryShortCode
                                ? this.state.selectedCountryShortCode
                                : ""
                            }
                            onChange={(e) =>
                              this.setState({
                                selectedCountryShortCode: e.target.value,
                              })
                            }
                          >
                            <option value="">Select country...</option>
                            {this.state.countries.map((c) => (
                              <option
                                key={c.twoCharCode}
                                value={c.threeCharCode}
                              >
                                {c.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        {FieldValidationError.isFieldInError(
                          this.state.errors,
                          "UnknownPostalCode"
                        ) && (
                          <div
                            className="need-more-feedback"
                            dangerouslySetInnerHTML={{
                              __html: FieldValidationError.getFieldErrorSummary(
                                this.state.errors,
                                "UnknownPostalCode"
                              ),
                            }}
                          />
                        )}
                      </div>
                    </div>
                  )}
                  {!this.state.hidePhoneNumberCapture && (
                    <div id="phone-number-container" className="mb-3">
                      <label id="phone-number-label" htmlFor="phoneNumber">
                        Phone Number
                      </label>
                      <input
                        type="tel"
                        className={
                          FieldValidationError.isFieldInError(
                            this.state.errors,
                            "PhoneNumber"
                          )
                            ? "form-control-lg form-control is-invalid"
                            : "form-control-lg form-control"
                        }
                        id="phoneNumber"
                        required
                        value={this.state.phoneNumber}
                        onChange={(e) =>
                          this.setState({ phoneNumber: e.target.value })
                        }
                      />
                      <div
                        className="invalid-feedback"
                        dangerouslySetInnerHTML={{
                          __html: FieldValidationError.getFieldErrorSummary(
                            this.state.errors,
                            "PhoneNumber"
                          ),
                        }}
                      />
                    </div>
                  )}
                  <button
                    id="submit-button"
                    className="btn btn-primary btn-block btn-lg mb-4"
                    type="submit"
                    disabled={!this.state.enableSubmit}
                  >
                    {this.state.enableSubmit && <>SUBSCRIBE</>}
                    {!this.state.enableSubmit && (
                      <>
                        PLEASE WAIT...
                        <div
                          className="spinner-border spinner-border-sm ml-4 mb-1"
                          role="status"
                        />
                      </>
                    )}
                  </button>

                  <p id="disclaimer-text" className="small">
                    Please note that your subscription will renew automatically
                    unless you cancel prior to renewal. We may contact you with
                    important information regarding your PRO subscription.
                    Standard data fees and text messaging rates may apply based
                    on your plan with your mobile phone carrier.
                  </p>
                </div>
              </div>
            </form>
          </div>
          <div className="col-lg-4 order-1 order-lg-2">
            <div className="card mb-3">
              <div className="card-header secondary-card-header">
                <h5>
                  <span id="pro-title" className="text-white">
                    Your PRO Subscription
                  </span>
                </h5>
              </div>
              <div className="card-body">
                {!this.state.proSubscriptionSelectionExpanded &&
                  selectedProPlan && (
                    <>
                      {this.renderPlanEntry(selectedProPlan)}
                      <div className="mb-3 ">
                        <a
                          id="change-link"
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({
                              proSubscriptionSelectionExpanded: true,
                            });
                          }}
                        >
                          (change)
                        </a>
                      </div>
                    </>
                  )}

                {this.state.proSubscriptionSelectionExpanded &&
                  this.state.proPlans.map((plan) => this.renderPlanEntry(plan))}

                {this.state.proSubscriptionSelectionExpanded && (
                  <>
                    {!this.state.appliedPromoCode &&
                      this.state.promoCodeCollapsed && (
                        <span
                          id="apply-promo-link"
                          style={{ color: "#268aff", cursor: "pointer" }}
                          onClick={() => {
                            this.setState({ promoCodeCollapsed: false });
                            EventTracking.BillingInfo.onPromoCodeLinkClick();
                          }}
                        >
                          Apply promo code{" "}
                          <FontAwesomeIcon icon={["fas", "angle-down"]} />
                        </span>
                      )}

                    {!this.state.appliedPromoCode &&
                      !this.state.promoCodeCollapsed && (
                        <form
                          onSubmit={this.handlePromoCodeFormSubmit}
                          className="mt-3"
                        >
                          <div className="input-group">
                            <input
                              id="promo-input"
                              type="text"
                              placeholder="Promo code"
                              className={
                                FieldValidationError.isFieldInError(
                                  this.state.errors,
                                  "PromoCode"
                                )
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              required
                              value={this.state.enteredPromoCode}
                              onChange={(e) =>
                                this.setState({
                                  enteredPromoCode: e.target.value,
                                })
                              }
                            />
                            <div className="input-group-append">
                              <button
                                id="promo-apply-button"
                                type="submit"
                                className="btn btn-secondary"
                                disabled={!this.state.enableSubmit}
                              >
                                Apply
                              </button>
                            </div>
                            <div
                              className="invalid-feedback"
                              dangerouslySetInnerHTML={{
                                __html:
                                  FieldValidationError.getFieldErrorSummary(
                                    this.state.errors,
                                    "PromoCode"
                                  ),
                              }}
                            />
                          </div>
                        </form>
                      )}
                  </>
                )}

                {this.state.existingCreditCards.length > 0 &&
                  !this.state.showUseNewCardForm && (
                    <form
                      className="mt-3"
                      onSubmit={this.handleCheckTaxFormSubmit}
                    >
                      <div className="input-group">
                        <input
                          type="text"
                          placeholder="Postal Code"
                          className={
                            FieldValidationError.isFieldInError(
                              this.state.errors,
                              "TaxCheck"
                            )
                              ? "form-control is-invalid"
                              : `form-control ${
                                  this.state.taxIsZero ? "is-valid" : ""
                                }`
                          }
                          value={this.state.newCardPostalCodeCheck}
                          onChange={(e) =>
                            this.setState({
                              newCardPostalCodeCheck: e.target.value,
                            })
                          }
                        />
                        <div className="input-group-append">
                          <button
                            type="submit"
                            className="btn btn-secondary"
                            disabled={!this.state.enableSubmit}
                          >
                            Check Tax
                          </button>
                        </div>
                        <div className="valid-feedback">
                          No tax for this postal code
                        </div>
                        <div
                          className="invalid-feedback"
                          dangerouslySetInnerHTML={{
                            __html: FieldValidationError.getFieldErrorSummary(
                              this.state.errors,
                              "TaxCheck"
                            ),
                          }}
                        />
                      </div>
                      <small>
                        Taxes may apply to this purchase, enter your postal code
                        to check.
                      </small>
                    </form>
                  )}
              </div>
            </div>

            <div className="d-none d-lg-block">
              <div className="row py-5 mt-3">
                <div className="col-sm-3 mt-1 p-1">
                  <img className="img-fluid" src={SSLLogo} />
                </div>
                <div id="ssl-container" className="col-sm-9">
                  <h6>SSL Secure Connection</h6>
                  <small>
                    This a secure transaction with all information sent from our
                    registration page to our secure servers encrypted for your
                    protection.
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
