import { ServerResponse, WebClient } from "../services/ServiceHelper";

export class TaxService {
  static async getTaxAmount(
    postalCode: string,
    isSubscription: boolean,
    price: number
  ) {
    var response = await WebClient.Get<number>(
      `api/Tax/GetCalculatedTax/${price}/${isSubscription}/${postalCode}`
    );

    if (ServerResponse.isSuccess<{ tax: number }>(response)) {
      return response.data.tax;
    } else {
      return null;
    }
  }
}
