import * as React from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import AppConfig from "../../entities/AppConfig";
import ApplicationUser from "../../entities/ApplicationUser";
import Country from "../../entities/Country";
import PostalCodeLocation from "../../entities/PostalCodeLocation";
import ProPlan from "../../entities/ProPlan";
import ProSubscriptionPromoCode from "../../entities/ProSubscriptionPromoCode";
import CreditCards from "../../resources/credit-cards.png";
import { AuthenticationService } from "../../services/AuthenticationService";
import { PostalCodeService } from "../../services/PostalCodeService";
import { ProSubscriptionService } from "../../services/ProSubscriptionService";
import {
  FieldValidationError,
  ServerModelValidationResponse,
  ServerResponse,
} from "../../services/ServiceHelper";
import { TaxService } from "../../services/TaxService";
import { EventTracking } from "../../utilities/EventTracking";
import { ProPlanIdDetermination } from "../../utilities/ProPlanIdDetermination";
import { ValidateInput } from "../../utilities/subscribe/validateInput";
import {ProSubscriptionTier} from "../../entities/ProSubscription";

interface Props extends RouteComponentProps<any> {
  config: AppConfig;
  user: ApplicationUser | null;
  handleUpdateUser: (user: ApplicationUser | null) => void;
}

type State = {
  proPlans: ProPlan[];
  fullName: string;
  email: string;
  password: string;
  selectedProPlanId: string;
  enteredPromoCode: string;
  appliedPromoCode?: ProSubscriptionPromoCode;
  redirectToSubscribe: boolean;
  enableSubmit: boolean;
  errors: FieldValidationError[];
  promoCodeCollapsed: boolean;
  creditCardNumber: string;
  cv2: string;
  expirationMonth: string;
  expirationYear: string;
  postalCode: string;
  changePlanActive: boolean;
  excludeTrial: boolean;
  redirectToConfirmation: boolean;
  accountAlreadyCreated: boolean;
  countries: Country[];
  showCountries: boolean;
  validatedPostalCodeLocation?: PostalCodeLocation;
  selectedCountryShortCode?: string;
  taxAmount: number;
  includeBasicTrial: boolean;
};

export default class SubscribeCreateFullAccountSinglePage extends React.Component<
  Props,
  State
> {
  redirectState: any = {};
  dataLayer = ((window as any).dataLayer = (window as any).dataLayer || []);
  numbersOnlyRegex = new RegExp("/^d+$/");

  constructor(props: Props) {
    super(props);
    this.state = {
      proPlans: [],
      fullName: "",
      email: "",
      password: "",
      selectedProPlanId: "AnnualPlus",
      enteredPromoCode: "",
      appliedPromoCode: undefined,
      redirectToSubscribe: false,
      enableSubmit: true,
      errors: [],
      promoCodeCollapsed: true,
      creditCardNumber: "",
      cv2: "",
      expirationMonth: "",
      expirationYear: "",
      postalCode: "",
      changePlanActive: false,
      excludeTrial: false,
      redirectToConfirmation: false,
      accountAlreadyCreated: false,
      countries: [],
      taxAmount: 0,
      showCountries: false,
      includeBasicTrial: false,
    };
  }

  async componentDidMount() {
    document.title = "SermonCentral Payments - Create Full Account Page";

    let redirectState = this.props.location.state;

    // Unlikely scenario, but user shouldn't be on this page unless they've been redirected
    // and aren't signed in, so redirect back to /subscribe
    if (!redirectState || this.props.user) {
      this.setState({
        redirectToSubscribe: true,
      });
    } else {
      const email = (redirectState as any).email || "";
      const firstName = (redirectState as any).firstName || "";
      const lastName = (redirectState as any).lastName || "";
      const enteredPromoCode = (redirectState as any).utm_promo || "";
      const fullName = (redirectState as any).fullName || "";

      let selectedProPlanId = "";

      if ((redirectState as any).pre_selected_plan) {
        selectedProPlanId = ProPlanIdDetermination.getProPlanNameById(
          (redirectState as any).pre_selected_plan
        );
      }

      let excludeTrial = false;
      if (
        (redirectState as any).excludeTrial &&
        (redirectState as any).excludeTrial === "1"
      ) {
        excludeTrial = true;
      }

      let includeBasicTrial = false;
      if (
          (redirectState as any).includeBasicTrial &&
          (redirectState as any).includeBasicTrial === "1"
      ) {
        includeBasicTrial = true;
      }

      delete (redirectState as any).email;
      delete (redirectState as any).firstName;
      delete (redirectState as any).lastName;
      delete (redirectState as any).phoneNumber;
      delete (redirectState as any).pre_selected_plan;
      delete (redirectState as any).utm_promo;
      delete (redirectState as any).excludeTrial;
      delete (redirectState as any).includeBasicTrial;
      delete (redirectState as any).fullName;

      let sentFullName = fullName
        ? fullName
        : firstName && lastName
        ? `${firstName} ${lastName}`.trim()
        : "";

      this.redirectState = redirectState;
      this.setState({
        email: email,
        selectedProPlanId: selectedProPlanId,
        enteredPromoCode: enteredPromoCode,
        fullName: sentFullName,
        excludeTrial,
        includeBasicTrial,
      });

      const responses = await Promise.all([
        ProSubscriptionService.getProPlans(),
        ProSubscriptionService.getCountries(),
      ]);

      const [proPlansResult, locationResult] = responses;

      if (ServerResponse.isError(proPlansResult)) {
        const serverError: FieldValidationError = {
          field: "",
          errors: ["Unable to retrieve plans. Please try again."],
        };
        this.setState({ errors: [serverError], enableSubmit: true });
      } else {
        this.setState({ proPlans: proPlansResult });
      }

      if (ServerResponse.isError(locationResult)) {
        const serverError: FieldValidationError = {
          field: "",
          errors: ["Unable to retrieve location. Please try again."],
        };
        this.setState({ errors: [serverError], enableSubmit: true });
      } else if (ServerResponse.isSuccess<Country[]>(locationResult)) {
        this.setState({
          countries: locationResult.data,
        });
      }

      if (enteredPromoCode) {
        await this.validatePromoCode();
      }

      EventTracking.CreateFullAccountSinglePage.onPageLoad();
    }
  }

  componentDidUpdate() {
    this.dataLayer.push({ event: "optimize.activate" });
  }

  async validatePromoCode() {
    if (!this.state.enableSubmit) {
      return;
    }

    if (this.state.enteredPromoCode) {
      try {
        this.setState({ enableSubmit: false });
        EventTracking.CreateAccount.onPromoCodeSubmitted();
        let proPlan = this.state.proPlans.find(
          (p) => p.id === this.state.selectedProPlanId
        );
        if (!proPlan) {
          proPlan = this.state.proPlans.find((p) => p.id === "AnnualPlus")!;
        }

        const response = await ProSubscriptionService.validatePromoCode(
          this.state.enteredPromoCode,
          proPlan.term,
          proPlan.tier
        );

        if (ServerResponse.isSuccess<ProSubscriptionPromoCode>(response)) {
          this.setState(
            {
              appliedPromoCode: response.data,
              enableSubmit: true,
              errors: [],
            },
            () => this.checkTax(this.state.postalCode)
          );
        } else if (
          ServerModelValidationResponse.isServerModelValidationResponse(
            response
          )
        ) {
          if (response.valid) {
            const serverError: FieldValidationError = {
              field: "",
              errors: ["An unknown error occurred. Please try again."],
            };
            this.setState({ errors: [serverError], enableSubmit: true });
          } else {
            this.setState({ errors: response.errors, enableSubmit: true });
          }
        } else if (ServerResponse.isError(response)) {
          const serverError: FieldValidationError = {
            field: "",
            errors: [response.message],
          };
          this.setState({ errors: [serverError], enableSubmit: true });
        } else {
          const serverError: FieldValidationError = {
            field: "",
            errors: ["An unknown error occurred. Please try again."],
          };
          this.setState({ errors: [serverError], enableSubmit: true });
        }
      } catch (errorResult) {
        const serverError: FieldValidationError = {
          field: "",
          errors: ["An unknown error occurred. Please try again."],
        };
        this.setState({ errors: [serverError], enableSubmit: true });
      }
    }
  }

  validateExpiryMonth() {
    var validationError = ValidateInput.CreditCardExpiryMonth(
      this.state.expirationMonth
    );

    if (validationError != null) {
      this.setState({ errors: [validationError], enableSubmit: true });
      return false;
    } else {
      return true;
    }
  }

  validateExpiryYear() {
    var validationError = ValidateInput.CreditCardExpiryYear(
      this.state.expirationYear
    );

    if (validationError != null) {
      this.setState({ errors: [validationError], enableSubmit: true });
      return false;
    } else {
      return true;
    }
  }

  handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!this.state.enableSubmit) {
      return;
    }

    if (!this.validateExpiryMonth()) {
      return;
    }

    if (!this.validateExpiryYear()) {
      return;
    }

    if (this.state.postalCode.length === 0) {
      const serverError: FieldValidationError = {
        field: "CreditCardPostalCode",
        errors: ["Zip/Postal code is required"],
      };
      this.setState({ errors: [serverError] });
      return;
    } else {
      if (this.state.selectedCountryShortCode) {
        this.setState(
          {
            validatedPostalCodeLocation: {
              country: this.state.selectedCountryShortCode,
              region: "",
              postalCode: this.state.postalCode,
            },
          },
          () => this.submitData()
        );
      } else {
        let postalCodeLocation = await PostalCodeService.getUSCanPostalCode(
          this.state.postalCode
        );

        if (postalCodeLocation === null) {
          const serverError: FieldValidationError = {
            field: "UnknownPostalCode",
            errors: ["Please select your country"],
          };
          this.setState({ showCountries: true, errors: [serverError] });
          return;
        } else {
          this.setState(
            { validatedPostalCodeLocation: postalCodeLocation },
            () => this.submitData()
          );
        }
      }
    }
  };

  checkTax = async (postalCode: string) => {
    var selectedProPlan = this.state.proPlans.find(
      (p) => p.id === this.state.selectedProPlanId
    );

    if (!selectedProPlan) {
      selectedProPlan = this.state.proPlans.find((p) => p.id === "AnnualPlus");
    }

    if (!selectedProPlan) {
      return;
    }

    let totalPrice = ProSubscriptionService.getProPlanPriceIncludingDiscount(
      selectedProPlan,
      this.state.appliedPromoCode
    );

    var taxRequest = await TaxService.getTaxAmount(
      postalCode,
      true,
      +totalPrice
    );

    this.setState({ taxAmount: taxRequest == null ? 0 : taxRequest });
  };

  submitData = async () => {
    if (this.state.accountAlreadyCreated) {
      // If the account created has already happened but billing did not, don't create another account, attempt payment.
      await this.SetUpBilling();
      return;
    }

    if (this.state.fullName && this.state.email && this.state.password) {
      try {
        this.setState({ enableSubmit: false });

        let firstName = "";
        let lastName = "";

        let splitName = this.state.fullName.trim().split(" ");

        if (splitName.length < 2) {
          const serverError: FieldValidationError = {
            field: "FirstName",
            errors: ["First and Last name are required"],
          };
          this.setState({ errors: [serverError], enableSubmit: true });
          return;
        }

        lastName = splitName[splitName.length - 1];
        firstName = splitName.slice(0, splitName.length - 1).join(" ");

        const response = await AuthenticationService.register(
          firstName.trim(),
          lastName.trim(),
          this.state.email,
          this.state.password
        );

        if (ServerResponse.isSuccess<ApplicationUser>(response)) {
          this.setState({ accountAlreadyCreated: true });
          EventTracking.CreateFullAccountSinglePage.onAccountCreated();
          this.props.handleUpdateUser(response.data);
          await this.SetUpBilling();
        } else if (
          ServerModelValidationResponse.isServerModelValidationResponse(
            response
          )
        ) {
          if (response.valid) {
            const serverError: FieldValidationError = {
              field: "",
              errors: ["An unknown error occurred. Please try again."],
            };
            this.setState({ errors: [serverError], enableSubmit: true });
          } else {
            this.setState({ errors: response.errors, enableSubmit: true });
          }
        } else if (ServerResponse.isError(response)) {
          const serverError: FieldValidationError = {
            field: "",
            errors: [response.message],
          };
          this.setState({ errors: [serverError], enableSubmit: true });
        } else {
          const serverError: FieldValidationError = {
            field: "",
            errors: ["An unknown error occurred. Please try again."],
          };
          this.setState({ errors: [serverError], enableSubmit: true });
        }
      } catch (errorResult) {
        const serverError: FieldValidationError = {
          field: "",
          errors: ["An unknown error occurred. Please try again."],
        };
        this.setState({ errors: [serverError], enableSubmit: true });
      }
    }
  };

  async SetUpBilling() {
    // Double check that this isn't happening
    if (!this.state.validatedPostalCodeLocation) {
      const serverError: FieldValidationError = {
        field: "UnknownPostalCode",
        errors: ["Please select your country"],
      };
      this.setState({ showCountries: true, errors: [serverError] });
      return;
    }

    // We need a 4 digit year, but this form allows for only two digits,  but if a saved payment method is used from the
    // browser, the year will be 4 digits.  So adjust the value if it's only 2.

    let expirationYear: number =
      this.state.expirationYear.length === 4
        ? +this.state.expirationYear
        : +(20 + this.state.expirationYear);

    try {
      this.setState({ enableSubmit: false });

      let response;
      if (this.state.selectedProPlanId === "AnnualBasic") {
        response = await ProSubscriptionService.subscribeToAnnualBasic(
          "",
          this.state.fullName,
          this.state.creditCardNumber,
          this.state.cv2,
          +this.state.expirationMonth,
          expirationYear,
          this.state.postalCode,
          "",
          this.state.appliedPromoCode ? this.state.appliedPromoCode.code : "",
          this.redirectState["pageTemplate"] || "",
          this.redirectState["url_ref"] || "",
          this.redirectState["utm_campaign"] || "",
          this.redirectState["utm_content"] || "",
          this.redirectState["utm_medium"] || "",
          this.redirectState["utm_source"] || "",
          this.state.includeBasicTrial,
          this.state.validatedPostalCodeLocation.region,
          this.state.validatedPostalCodeLocation.country
        );
      } else if (
        this.state.selectedProPlanId === "AnnualPlus" ||
        !this.state.selectedProPlanId
      ) {
        response = await ProSubscriptionService.subscribeToAnnualPlus(
          "",
          this.state.fullName,
          this.state.creditCardNumber,
          this.state.cv2,
          +this.state.expirationMonth,
          expirationYear,
          this.state.postalCode,
          "",
          this.state.appliedPromoCode ? this.state.appliedPromoCode.code : "",
          this.redirectState["pageTemplate"] || "",
          this.redirectState["url_ref"] || "",
          this.redirectState["utm_campaign"] || "",
          this.redirectState["utm_content"] || "",
          this.redirectState["utm_medium"] || "",
          this.redirectState["utm_source"] || "",
          this.state.excludeTrial,
          this.state.validatedPostalCodeLocation.region,
          this.state.validatedPostalCodeLocation.country
        );
      } else if (this.state.selectedProPlanId === "AnnualPremium") {
        response = await ProSubscriptionService.subscribeToAnnualPremium(
          "",
          this.state.fullName,
          this.state.creditCardNumber,
          this.state.cv2,
          +this.state.expirationMonth,
          expirationYear,
          this.state.postalCode,
          "",
          this.state.appliedPromoCode ? this.state.appliedPromoCode.code : "",
          this.redirectState["pageTemplate"] || "",
          this.redirectState["url_ref"] || "",
          this.redirectState["utm_campaign"] || "",
          this.redirectState["utm_content"] || "",
          this.redirectState["utm_medium"] || "",
          this.redirectState["utm_source"] || "",
          this.state.validatedPostalCodeLocation.region,
          this.state.validatedPostalCodeLocation.country
        );
      } else if (this.state.selectedProPlanId === "MonthlyPlus") {
        response = await ProSubscriptionService.subscribeToMonthlyPlus(
          "",
          this.state.fullName,
          this.state.creditCardNumber,
          this.state.cv2,
          +this.state.expirationMonth,
          expirationYear,
          this.state.postalCode,
          "",
          this.state.appliedPromoCode ? this.state.appliedPromoCode.code : "",
          this.redirectState["pageTemplate"] || "",
          this.redirectState["url_ref"] || "",
          this.redirectState["utm_campaign"] || "",
          this.redirectState["utm_content"] || "",
          this.redirectState["utm_medium"] || "",
          this.redirectState["utm_source"] || "",
          this.state.validatedPostalCodeLocation.region,
          this.state.validatedPostalCodeLocation.country
        );
      }

      if (ServerResponse.isSuccess<ApplicationUser>(response)) {
        EventTracking.CreateFullAccountSinglePage.onBillingInfoSubmitted();
        this.props.handleUpdateUser(response.data);
        this.redirectState = {
          redirectedFromBillingInfo: true,
          appliedPromoCode: this.state.appliedPromoCode
            ? this.state.appliedPromoCode.code
            : "",
        };
        this.setState({ errors: [], redirectToConfirmation: true });
      } else if (
        ServerModelValidationResponse.isServerModelValidationResponse(response)
      ) {
        if (response.valid) {
          const serverError: FieldValidationError = {
            field: "",
            errors: ["An unknown error occurred. Please try again."],
          };
          this.setState({ errors: [serverError], enableSubmit: true });
        } else {
          this.setState({ errors: response.errors, enableSubmit: true });
        }
      } else if (ServerResponse.isError(response)) {
        const serverError: FieldValidationError = {
          field: "",
          errors: [response.message],
        };
        this.setState({ errors: [serverError], enableSubmit: true });
      } else {
        const serverError: FieldValidationError = {
          field: "",
          errors: ["An unknown error occurred. Please try again."],
        };
        this.setState({ errors: [serverError], enableSubmit: true });
      }
    } catch (errorResult) {
      const serverError: FieldValidationError = {
        field: "",
        errors: ["An unknown error occurred. Please try again."],
      };
      this.setState({ errors: [serverError], enableSubmit: true });
    }
  }

  onkeyDownNumbersOnly(
    e: React.KeyboardEvent<HTMLInputElement>,
    currentValue: string
  ) {
    // If Backspace (8), Delete (46), Arrows (37, 38, 39, 40), or Tab (9), let thru too
    if (
      e.keyCode !== 8 &&
      e.keyCode !== 46 &&
      e.keyCode !== 37 &&
      e.keyCode !== 38 &&
      e.keyCode !== 39 &&
      e.keyCode !== 40 &&
      e.keyCode !== 9
    ) {
      let key = Number(e.key);
      if (isNaN(key) || e.key === null || currentValue.length === 2) {
        e.preventDefault();
      }
    }
  }

  onBlurExpiryYear(e: React.FocusEvent<HTMLInputElement>) {
    if (this.state.expirationYear.length === 1) {
      this.setState({ expirationYear: 0 + this.state.expirationYear });
    }
  }

  onBlurExpiryMonth(e: React.FocusEvent<HTMLInputElement>) {
    if (this.state.expirationMonth.length === 1) {
      this.setState({ expirationMonth: 0 + this.state.expirationMonth });
    }
  }

  handlePromoCodeFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    await this.validatePromoCode();
  };

  isTrialAvailable = (plan: ProPlan): boolean => {

    let plusTrialAvailable =
        plan.tier === ProSubscriptionTier.Plus &&
        plan.allowTrial &&
        !this.state.appliedPromoCode &&
        !(this.props.user && this.props.user.hasEverHadProSubscription) &&
        !(this.state.excludeTrial);

    let basicTrialAvailable =
        plan.tier === ProSubscriptionTier.Basic &&
        plan.allowTrial &&
        !this.state.appliedPromoCode &&
        !(this.props.user && this.props.user.hasEverHadProSubscription) &&
        this.state.includeBasicTrial;

    return plusTrialAvailable || basicTrialAvailable;;
  }

  determineSelectedInfo(plan: ProPlan | undefined) {
    if (!plan) {
      plan = this.state.proPlans.find((p) => p.id === "AnnualPlus");
    }

    if (!plan) {
      return;
    }

    let totalPrice = ProSubscriptionService.getProPlanPriceIncludingDiscount(
      plan,
      this.state.appliedPromoCode
    );
    let monthlyPrice =
      plan.billingPeriod === "yr"
        ? ProSubscriptionService.getMonthlyPrice(totalPrice)
        : totalPrice;
    let trialAvailable = this.isTrialAvailable(plan);

    if (plan.term === 12) {
      return (
        <div className="selected-plan-info">
          <div className="bill-cycle">Billed Annually</div>
          <div className="price-structure">
            <div style={{ width: "100%", marginBottom: -21 }}>
              ${totalPrice} {trialAvailable ? "($0 Today)" : ""}
            </div>
            {this.state.taxAmount > 0 && (
              <small style={{ fontSize: 12, color: "#000", opacity: 0.6 }}>
                *{totalPrice + this.state.taxAmount} including tax
              </small>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div className="selected-plan-info">
          <div className="bill-cycle">Billed Monthly</div>
          <div className="price-structure">
            <div style={{ width: "100%", marginBottom: -21 }}>
              ${totalPrice}
            </div>
            {this.state.taxAmount > 0 && (
              <small style={{ fontSize: 12, color: "#000", opacity: 0.6 }}>
                *{totalPrice + this.state.taxAmount} including tax
              </small>
            )}
          </div>
        </div>
      );
    }
  }

  onPlanChange(plan: ProPlan) {
    if (this.state.selectedProPlanId === "") {
      EventTracking.CreateFullAccountSinglePage.onSelectPlan(
        `${plan.name}-${plan.id}`
      );
    } else {
      EventTracking.CreateFullAccountSinglePage.onChangePlan(
        `${plan.name}-${plan.id}`
      );
    }

    this.setState({ selectedProPlanId: plan.id, changePlanActive: false }, () =>
      this.checkTax(this.state.postalCode)
    );
  }

  determineButtonText(plan: ProPlan | undefined) {
    if (!plan) {
      plan = this.state.proPlans.find((p) => p.id === "AnnualPlus");
    }

    if (!plan) {
      return;
    }

    if (this.isTrialAvailable(plan)) {
      return "Start Your Free Trial!";
    } else {
      return "Activate My Account";
    }
  }

  dueToday(plan: ProPlan | undefined) {
    if (!plan) {
      plan = this.state.proPlans.find((p) => p.id === "AnnualPlus");
    }

    if (!plan) {
      return;
    }

    let totalPrice = ProSubscriptionService.getProPlanPriceIncludingDiscount(
      plan,
      this.state.appliedPromoCode
    );
    let trialAvailable = this.isTrialAvailable(plan);

    return trialAvailable ? 0 : totalPrice;
  }

  renderTOS(plan: ProPlan | undefined) {
    if (!plan) {
      plan = this.state.proPlans.find((p) => p.id === "AnnualPlus");
    }

    if (!plan) {
      return;
    }

    let totalPrice = ProSubscriptionService.getProPlanPriceIncludingDiscount(
      plan,
      this.state.appliedPromoCode
    );
    let monthlyPrice =
      plan.billingPeriod === "yr"
        ? ProSubscriptionService.getMonthlyPrice(totalPrice)
        : totalPrice;

    let trialAvailable = this.isTrialAvailable(plan)

    if (trialAvailable) {
      return (
        <span className="gray-scale-font gray-scale-opacity">
          By signing up, you are agreeing to our&nbsp;
          <a
            className="anchor-item"
            href={`${this.props.config.mainSiteUrl}/content/termsandconditions?ref=Subscribe`}
            target="_blank"
          >
            Terms of Service
          </a>
          . &nbsp;After 14 days, your account will bill and activate with full
          benefits.
        </span>
      );
    } else {
      return (
        <span className="gray-scale-font gray-scale-opacity">
          By signing up, you are agreeing to our&nbsp;
          <a
            className="anchor-item"
            href={`${this.props.config.mainSiteUrl}/content/termsandconditions?ref=Subscribe`}
            target="_blank"
          >
            Terms of Service
          </a>
          . &nbsp;Your account will activate immediately with full PRO benefits.
          It will bill at ${totalPrice}/{plan.term === 1 ? "mo" : "yr"}. Cancel
          any time.
        </span>
      );
    }
  }

  renderPlanEntry(plan: ProPlan) {
    let totalPrice = ProSubscriptionService.getProPlanPriceIncludingDiscount(
      plan,
      this.state.appliedPromoCode
    );
    let monthlyPrice =
      plan.billingPeriod === "yr"
        ? ProSubscriptionService.getMonthlyPrice(totalPrice)
        : totalPrice;
    let trialAvailable = this.isTrialAvailable(plan)

    let abbrevPlanName = "";
    let abbrevDescription = "";

    switch (plan.id) {
      case "AnnualPlus":
        abbrevPlanName = "Plus";
        abbrevDescription = trialAvailable
          ? "14 day trial for $0 today"
          : `Get full access + unlimited media for ${totalPrice}/yr`;
        break;
      case "AnnualBasic":
        abbrevPlanName = "Basic";
        abbrevDescription = trialAvailable
            ? "14 day trial for $0 today"
            : `Get basic access for ${totalPrice}/yr`;
        break;
      case "MonthlyPlus":
        abbrevPlanName = "Plus";
        abbrevDescription = `Get full access for ${totalPrice}/mo`;
        break;
      case "AnnualPremium":
        abbrevPlanName = "Premium";
        abbrevDescription = `Full access + sermon kits for ${totalPrice}/yr`;
        break;
      default:
        break;
    }

    return (
      <div key={plan.id} className="form-check ml-4 mb-2 plan-option d-flex">
        <label className="blue-custom-radio">
          <input
            className="form-check-input"
            type="radio"
            name="SelectedPlan"
            id={plan.id}
            checked={
              this.state.selectedProPlanId === plan.id ||
              (!this.state.selectedProPlanId && plan.id === "AnnualPlus")
            }
            onChange={() => this.onPlanChange(plan)}
            onClick={() => this.onPlanChange(plan)}
          />
          <span className="checkmark"></span>
        </label>
        <label
          style={{ fontSize: 16 }}
          id={`${plan.id}-label`}
          className="form-check-label"
          htmlFor={plan.id}
        >
          {trialAvailable ? (
            <strong className="bluer-scale-font">Free&nbsp;</strong>
          ) : (
            <strong className="bluer-scale-font">{abbrevPlanName}&nbsp;</strong>
          )}
          <span
            style={{ opacity: 0.6 }}
            className="gray-scale-font font-weight-500"
          >
            {abbrevDescription}
          </span>
        </label>
      </div>
    );
  }

  render() {
    if (this.state.redirectToSubscribe) {
      return (
        <Redirect to={{ pathname: "/subscribe", state: this.redirectState }} />
      );
    }

    if (this.state.redirectToConfirmation) {
      return (
        <Redirect
          to={{
            pathname: "/subscribe/confirmation",
            state: this.redirectState,
          }}
        />
      );
    }

    var selectedProPlan = this.state.proPlans.find(
      (p) => p.id === this.state.selectedProPlanId
    );

    return (
      <div>
        {FieldValidationError.hasGenericError(this.state.errors) && (
          <div
            className="alert alert-danger"
            role="alert"
            dangerouslySetInnerHTML={{
              __html: FieldValidationError.getGenericErrorSummary(
                this.state.errors
              ),
            }}
          />
        )}
        <div className="row" style={{ backgroundColor: "#FFF" }}>
          <div className="col-lg-7 order-12 order-lg-1">
            <form method="post" onSubmit={this.handleFormSubmit}>
              <div className="mb-4 mt-3">
                <h3
                  className="font-weight-normal gray-scale-font"
                  id="form-title"
                >
                  Account Info
                </h3>
              </div>
              <div className="mb-4">
                <hr className="gray-scale-line" />
              </div>
              <div className="mb-6">
                <label className="gray-scale-font gray-scale-opacity">
                  Already have an account?&nbsp;
                  <span
                    style={{ cursor: "pointer" }}
                    className="blue-scale-font"
                    onClick={() =>
                      (window.location.href = `${this.props.config.mainSiteUrl}/Account/Login`)
                    }
                  >
                    Login
                  </span>
                </label>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="mb-3">
                    <label
                      className="gray-scale-font gray-scale-opacity font-weight-500"
                      id="full-name-label"
                      htmlFor="fullName"
                    >
                      Full Name
                    </label>
                    <input
                      type="text"
                      className={
                        FieldValidationError.isFieldInError(
                          this.state.errors,
                          "FirstName"
                        ) ||
                        FieldValidationError.isFieldInError(
                          this.state.errors,
                          "LastName"
                        )
                          ? "form-control-lg form-control is-invalid input-soft-border"
                          : "form-control-lg form-control input-soft-border"
                      }
                      id="firstName"
                      required
                      value={this.state.fullName}
                      onChange={(e) =>
                        this.setState({ fullName: e.target.value })
                      }
                    />
                    <div
                      className="invalid-feedback"
                      dangerouslySetInnerHTML={{
                        __html: FieldValidationError.getFieldErrorSummary(
                          this.state.errors,
                          "FirstName"
                        ),
                      }}
                    />
                    <div
                      className="invalid-feedback"
                      dangerouslySetInnerHTML={{
                        __html: FieldValidationError.getFieldErrorSummary(
                          this.state.errors,
                          "LastName"
                        ),
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="mb-3">
                    <label
                      className="gray-scale-font gray-scale-opacity font-weight-500"
                      id="email-label"
                      htmlFor="email"
                    >
                      Email Address
                    </label>
                    <input
                      type="text"
                      className={
                        FieldValidationError.isFieldInError(
                          this.state.errors,
                          "Email"
                        )
                          ? "form-control-lg form-control is-invalid input-soft-border"
                          : "form-control-lg form-control input-soft-border"
                      }
                      id="firstName"
                      required
                      value={this.state.email}
                      onChange={(e) => this.setState({ email: e.target.value })}
                    />
                    <div
                      className="invalid-feedback"
                      dangerouslySetInnerHTML={{
                        __html: FieldValidationError.getFieldErrorSummary(
                          this.state.errors,
                          "Email"
                        ),
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="mb-3">
                    <label
                      className="gray-scale-font gray-scale-opacity font-weight-500"
                      id="password-label"
                      htmlFor="email"
                    >
                      Password <small>(6-12 characters)</small>
                    </label>
                    <input
                      minLength={6}
                      maxLength={12}
                      type="text"
                      className={
                        FieldValidationError.isFieldInError(
                          this.state.errors,
                          "Password"
                        )
                          ? "form-control-lg form-control is-invalid input-soft-border"
                          : "form-control-lg form-control input-soft-border"
                      }
                      id="firstName"
                      required
                      value={this.state.password}
                      onChange={(e) =>
                        this.setState({ password: e.target.value })
                      }
                    />
                    <div
                      className="invalid-feedback"
                      dangerouslySetInnerHTML={{
                        __html: FieldValidationError.getFieldErrorSummary(
                          this.state.errors,
                          "Password"
                        ),
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="mb-4 mt-3 d-flex">
                <h3
                  className="font-weight-normal gray-scale-font"
                  id="form-title"
                >
                  Payment Info
                </h3>
                <img
                  className="cc-image ml-auto"
                  style={{ height: 40 }}
                  src={CreditCards}
                />
              </div>
              <div className="mb-4">
                <hr className="gray-scale-line" />
              </div>
              <div className="row">
                <div className="col-sm-12 mb-3">
                  <div className="faux-cc-input input-soft-border">
                    <div className="row no-gutters" style={{ height: "100%" }}>
                      <div className="col-7">
                        <input
                          maxLength={16}
                          autoComplete="cc-number"
                          className="cc-input-part form-control-lg form-control input-soft-border gray-scale-font pl-3"
                          style={{
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                          }}
                          placeholder="Card Number"
                          type="text"
                          required
                          value={this.state.creditCardNumber}
                          onChange={(e) =>
                            this.setState({ creditCardNumber: e.target.value })
                          }
                        />
                      </div>
                      <div className="col-3">
                        <div id="expiry-container" className="d-flex">
                          <input
                            className="cc-input-part form-control-lg form-control gray-scale-font"
                            autoComplete="cc-exp-month"
                            placeholder="MM"
                            type="text"
                            required
                            value={this.state.expirationMonth}
                            onChange={(e) =>
                              this.setState({ expirationMonth: e.target.value })
                            }
                            onKeyDown={(e) =>
                              this.onkeyDownNumbersOnly(
                                e,
                                this.state.expirationMonth
                              )
                            }
                            onBlur={(e) => this.onBlurExpiryMonth(e)}
                          />
                          <span className="gray-scale-font gray-scale-opacity">
                            /
                          </span>
                          <input
                            className="cc-input-part form-control-lg form-control  gray-scale-font"
                            autoComplete="cc-exp-year"
                            placeholder="YY"
                            type="text"
                            required
                            value={this.state.expirationYear}
                            onChange={(e) =>
                              this.setState({ expirationYear: e.target.value })
                            }
                            onKeyDown={(e) =>
                              this.onkeyDownNumbersOnly(
                                e,
                                this.state.expirationYear
                              )
                            }
                            onBlur={(e) => this.onBlurExpiryYear(e)}
                          />
                        </div>
                      </div>
                      <div className="col-2">
                        <input
                          id="cvc-input"
                          maxLength={4}
                          className="cc-input-part form-control-lg form-control input-soft-border gray-scale-font pl-2"
                          placeholder="CVC"
                          type="text"
                          autoComplete="cc-csc"
                          required
                          value={this.state.cv2}
                          onChange={(e) =>
                            this.setState({ cv2: e.target.value })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 custom-cc-feedback">
                  {FieldValidationError.isFieldInError(
                    this.state.errors,
                    "CreditCardNumber"
                  ) && (
                    <div
                      className="invalid-feedback"
                      dangerouslySetInnerHTML={{
                        __html: FieldValidationError.getFieldErrorSummary(
                          this.state.errors,
                          "CreditCardNumber"
                        ),
                      }}
                    />
                  )}
                  {FieldValidationError.isFieldInError(
                    this.state.errors,
                    "CreditCardSecurityCode"
                  ) && (
                    <div
                      className="invalid-feedback"
                      dangerouslySetInnerHTML={{
                        __html: FieldValidationError.getFieldErrorSummary(
                          this.state.errors,
                          "CreditCardSecurityCode"
                        ),
                      }}
                    />
                  )}
                  {FieldValidationError.isFieldInError(
                    this.state.errors,
                    "CreditCardExpirationMonth"
                  ) && (
                    <div
                      className="invalid-feedback"
                      dangerouslySetInnerHTML={{
                        __html: FieldValidationError.getFieldErrorSummary(
                          this.state.errors,
                          "CreditCardExpirationMonth"
                        ),
                      }}
                    />
                  )}
                  {FieldValidationError.isFieldInError(
                    this.state.errors,
                    "CreditCardExpirationYear"
                  ) && (
                    <div
                      className="invalid-feedback"
                      dangerouslySetInnerHTML={{
                        __html: FieldValidationError.getFieldErrorSummary(
                          this.state.errors,
                          "CreditCardExpirationYear"
                        ),
                      }}
                    />
                  )}
                </div>
                <div className="col-sm-12">
                  <div className="mb-4 zip-code">
                    <input
                      type="text"
                      maxLength={10}
                      placeholder="Zip/Postal Code"
                      className={
                        FieldValidationError.isFieldInError(
                          this.state.errors,
                          "CreditCardPostalCode"
                        )
                          ? "form-control-lg form-control input-soft-border gray-scale-font pl-3 is-invalid"
                          : "form-control-lg form-control input-soft-border gray-scale-font pl-3"
                      }
                      id="postalCode"
                      autoComplete="shipping postal-code"
                      value={this.state.postalCode}
                      onChange={(e) =>
                        this.setState({ postalCode: e.target.value })
                      }
                      onBlur={(e) => this.checkTax(e.target.value as string)}
                    />
                    <div
                      className="invalid-feedback"
                      dangerouslySetInnerHTML={{
                        __html: FieldValidationError.getFieldErrorSummary(
                          this.state.errors,
                          "CreditCardPostalCode"
                        ),
                      }}
                    />
                  </div>
                </div>
                {this.state.showCountries && (
                  <div className="col-12 mb-3">
                    <div className="input-group input-soft-border">
                      <select
                        className="form-control form-control-lg select-soft"
                        id="country"
                        value={
                          this.state.selectedCountryShortCode
                            ? this.state.selectedCountryShortCode
                            : ""
                        }
                        onChange={(e) =>
                          this.setState({
                            selectedCountryShortCode: e.target.value,
                          })
                        }
                      >
                        <option value="">Select country...</option>
                        {this.state.countries.map((c) => (
                          <option key={c.twoCharCode} value={c.threeCharCode}>
                            {c.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    {FieldValidationError.isFieldInError(
                      this.state.errors,
                      "UnknownPostalCode"
                    ) && (
                      <div
                        className="need-more-feedback"
                        dangerouslySetInnerHTML={{
                          __html: FieldValidationError.getFieldErrorSummary(
                            this.state.errors,
                            "UnknownPostalCode"
                          ),
                        }}
                      />
                    )}
                  </div>
                )}

                {!this.state.appliedPromoCode && (
                  <>
                    <div className="col-sm-12 d-flex full-account">
                      <div className="checkbox-card">
                        <div className="checkbox-container">
                          <label className="checkbox-label">
                            <input
                              type="checkbox"
                              onClick={() =>
                                this.setState({
                                  promoCodeCollapsed:
                                    !this.state.promoCodeCollapsed,
                                })
                              }
                            />
                            <span className="checkbox-custom rectangular"></span>
                          </label>
                          <div className="input-title"></div>
                        </div>
                      </div>
                      <span
                        className="ml-2 font-weight-normal gray-scale-font"
                        style={{ opacity: 0.6 }}
                      >
                        I have a promo code I want to use
                      </span>
                    </div>

                    {!this.state.promoCodeCollapsed && (
                      <div className="col-sm-12 mt-2 mb-2">
                        <label
                          className="gray-scale-font gray-scale-opacity font-weight-500"
                          id="promo-label"
                          htmlFor="promo"
                        >
                          Promo code
                        </label>
                        <form onSubmit={this.handlePromoCodeFormSubmit}>
                          <div className="input-group">
                            <input
                              type="text"
                              className={
                                FieldValidationError.isFieldInError(
                                  this.state.errors,
                                  "PromoCode"
                                )
                                  ? "promo-input form-control-lg form-control is-invalid input-soft-border"
                                  : "promo-input form-control-lg form-control input-soft-border"
                              }
                              id="promo"
                              required
                              value={this.state.enteredPromoCode}
                              onChange={(e) =>
                                this.setState({
                                  enteredPromoCode: e.target.value,
                                })
                              }
                            />
                            <div className="input-group-append">
                              <button
                                className="btn blue-primary-button"
                                type="submit"
                              >
                                Apply
                              </button>
                            </div>
                            <div
                              className="invalid-feedback"
                              dangerouslySetInnerHTML={{
                                __html:
                                  FieldValidationError.getFieldErrorSummary(
                                    this.state.errors,
                                    "PromoCode"
                                  ),
                              }}
                            />
                          </div>
                        </form>
                      </div>
                    )}
                  </>
                )}

                <div
                  id="due-today-non-mobile"
                  className="col-sm-12 d-flex align-items-sm-center mt-4"
                >
                  <label
                    className="gray-scale-font gray-scale-opacity font-weight-500"
                    id="due-today"
                  >
                    Due Today:{" "}
                  </label>
                  <label
                    className="font-weight-500 ml-2"
                    style={{ fontSize: 26 }}
                  >
                    ${this.dueToday(selectedProPlan)}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-6 col-sm-12 mt-4">
                  <button
                    id="submit-button-full-account"
                    className="btn blue-primary-button btn-block btn-lg mb-4"
                    type="submit"
                    disabled={!this.state.enableSubmit}
                  >
                    {this.state.enableSubmit && (
                      <>{this.determineButtonText(selectedProPlan)}</>
                    )}
                    {!this.state.enableSubmit && (
                      <>
                        PLEASE WAIT...
                        <div
                          className="spinner-border spinner-border-sm ml-4 mb-1"
                          role="status"
                        />
                      </>
                    )}
                  </button>
                </div>
                <div className="col-6 mt-4">
                  <div id="due-today-mobile">
                    <div
                      className="row no-gutters"
                      style={{ lineHeight: "2.5rem" }}
                    >
                      <div className="col-7">
                        <label
                          className="gray-scale-font gray-scale-opacity font-weight-500"
                          id="due-today"
                        >
                          Due Today:{" "}
                        </label>
                      </div>
                      <div className="col-5">
                        <label
                          className="font-weight-500 ml-2"
                          style={{ fontSize: 26 }}
                        >
                          ${this.dueToday(selectedProPlan)}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 mb-3">
                  {this.renderTOS(selectedProPlan)}
                </div>
              </div>
            </form>
          </div>
          <div className="col-lg-5 order-1 order-lg-2">
            <div className="d-lg-block">
              <div className="row py-4">
                <div className="col-sm-12">
                  <div className="mb-1">
                    <label className="gray-scale-font ">
                      <b>
                        Your Plan:{" "}
                        {selectedProPlan ? selectedProPlan.name : "PRO Plus"}
                      </b>{" "}
                    </label>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="outline-selected-plan-box selected-plan-box">
                    {this.determineSelectedInfo(selectedProPlan)}
                  </div>
                </div>
                <div className="col-sm-12 mt-2">
                  {!this.state.changePlanActive && (
                    <p
                      onClick={() => this.setState({ changePlanActive: true })}
                      className="change-plan-label"
                    >
                      Change Your Plan
                    </p>
                  )}
                  {this.state.changePlanActive &&
                    this.state.proPlans.map((plan) =>
                      this.renderPlanEntry(plan)
                    )}
                </div>
                <div className="col-sm-12">
                  <hr className="gray-scale-line" />
                </div>
                <div className="testimonials">
                  <div className="col-sm-12 mt-4">
                    <i>
                      "Appreciate you guys so much. I’ve used hundreds of your
                      video resources in weekly messages to youth, kids and
                      adults. Best resources out there"
                    </i>
                    <br />
                    <b>Phil Kleins</b>
                  </div>
                  <div className="col-sm-12 mt-4">
                    <i>
                      "SermonCentral has DRASTICALLY sped up my sermons from
                      idea-gathering to outlining to presentation. I am SO
                      thankful for your help."
                    </i>
                    <br />
                    <b>Michael Dean</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
