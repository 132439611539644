declare const gtag: (command: string, commandParameters?: any, additionalConfigInfo?: any) => any;

export class GTag {
    static event(action: string, detail: EventDetail) {
        let windowObject: any = window;
        if (windowObject.gtag) {
            gtag("event", action, detail);
        }
    }
}

export interface EventDetail {
    event_category: string;
    event_label?: string;
    value?: number;
}
