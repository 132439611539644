import { WebClient, ServerResponse } from './ServiceHelper';

import CreditCard from '../entities/CreditCard';

export class CreditCardService {

    static async getCreditCards() {

        var response = await WebClient.Get<CreditCard[]>("api/CreditCards/GetCreditCards");
        if (ServerResponse.isSuccess<CreditCard[]>(response)) {
            return response.data;
        }
        else {
            return null;
        }
    }
}