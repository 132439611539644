import ApplicationUser from "../../entities/ApplicationUser";
import {useEffect, useState} from "react";
import ChurchInfo from "../../entities/ChurchInfo";
import * as React from "react";

import 'react-phone-number-input/style.css'
import './verify.css'
import VerifyChurchInfo from "./VerifyChurchInfo";
import VerifySendCode from "./VerifySendCode";
import VerifyEnterCode from "./VerifyEnterCode";

const Verify = ({ user, onVerify, embed = false }: { user: ApplicationUser, onVerify: (dataLayerEvent: any) => void, embed?: boolean }) => {
    const [step, setStep] = useState<number>(1);
    const [currentPhone, setCurrentPhone] = useState<string>("");
    const [currentEmailAddy, setCurrentEmailAddy] = useState<string>("");
    const [verifyMethod, setVerifyMethod] = useState<"phone" | "email">("phone");
    const [churchInfo, setChurchInfo] = useState<ChurchInfo>();
    const [resendAddress, setResendAddress] = useState<string>("");
    const [userPreverified, setUserPreVerified] = useState(false);

    useEffect(() => {
        if (user.isVerified) {
            // User verified previously, so we won't make them do it again
            setUserPreVerified(true);
            setStep(4);
        }
    }, []);

    useEffect(() => {
        if (user?.phoneNumber) {
            setCurrentPhone(user.phoneNumber);
        }

        if (user?.email) {
            setCurrentEmailAddy(user.email);
        }
    }, [user]);

    useEffect(() => {
        if (step === 4) {
            let event = null;
            if (!userPreverified) {
                event = {
                    event: "userVerification",
                    userId: user.id.toString(),
                    verifiedField: verifyMethod,
                    churchName: churchInfo?.name ?? "",
                    churchCity: churchInfo?.city ?? "",
                    churchState: churchInfo?.state ?? "",
                    jobPosition: churchInfo?.jobPosition ?? "",
                };
            }

            onVerify(event);
        }
    }, [step, userPreverified]);

    const incrementStep = () => {
        if (step < 4) {
            setStep(step + 1);
        }
    };

    const decrementStep = () => {
        if (step > 1) {
            setStep(step - 1);
        }
    };

    let divStyle: React.CSSProperties = {};
    if (embed) {
        divStyle.backgroundColor = "#FFF";
        divStyle.height = "630px";
    }

    return (
        <div className="row py-5" style={divStyle}>
            <div className="offset-md-2 col-md-8">
                {step <= 1 ?
                    <VerifyChurchInfo nextStep={incrementStep} updateChurchInfo={setChurchInfo} embed={embed} />
                : step === 2 ?
                    <VerifySendCode currentEmailAddy={currentEmailAddy} currentPhone={currentPhone} setCurrentPhone={setCurrentPhone} setCurrentEmailAddy={setCurrentEmailAddy} nextStep={incrementStep} setResendAddress={setResendAddress} setVerifyMethod={setVerifyMethod} embed={embed} />
                :
                    <VerifyEnterCode nextStep={incrementStep} previousStep={decrementStep} resendPreference={verifyMethod} resendAddress={resendAddress} embed={embed} />
                }
            </div>
        </div>
    );
}

export default Verify;