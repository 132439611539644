import * as React from 'react';
import { Link, RouteComponentProps, Redirect } from 'react-router-dom';

import ApplicationUser from '../../entities/ApplicationUser';

import { FieldValidationError, ServerResponse, ServerModelValidationResponse } from '../../services/ServiceHelper';

import { AuthenticationService } from '../../services/AuthenticationService';
import AppConfig, { AppConfigEnvironment } from '../../entities/AppConfig';
import { GlooButton } from '../../components/GlooButton';

interface Props extends RouteComponentProps<any> {
    user: ApplicationUser | null;
    handleUpdateUser: (user: ApplicationUser | null) => void;
    config: AppConfig;
}

type State = {
    redirectToReferrer: boolean;
    enableSubmit: boolean;
    email: string;
    password: string;
    errors: FieldValidationError[];
};

export default class SignIn extends React.Component<Props, State> {

    redirectState: any = {};

    constructor(props: Props) {
        super(props);
        this.state = {
            redirectToReferrer: false,
            enableSubmit: true,
            email: '',
            password: '',
            errors: []
        };
    }

    async componentDidMount() {

        document.title = "SermonCentral Payments - Sign In";
    }

    async handleFormSubmit(e: React.FormEvent<HTMLFormElement>) {

        e.preventDefault();
        if (!this.state.enableSubmit) {
            return;
        }

        if (this.state.email && this.state.password) {
            try {
                this.setState({ enableSubmit: false });
                const response = await AuthenticationService.signIn(this.state.email, this.state.password, false, 'SignIn');

                if (ServerResponse.isSuccess<ApplicationUser>(response)) {
                    this.setState({ redirectToReferrer: true }, () => this.props.handleUpdateUser(response.data));
                }
                else if (ServerModelValidationResponse.isServerModelValidationResponse(response)) {
                    if (response.valid) {
                        const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                        this.setState({ errors: [serverError], enableSubmit: true });
                    }
                    else {
                        this.setState({ errors: response.errors, enableSubmit: true });
                    }
                }
                else if (ServerResponse.isError(response)) {
                    const serverError: FieldValidationError = { field: "", errors: [response.message] };
                    this.setState({ errors: [serverError], enableSubmit: true });
                }
                else {
                    const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                    this.setState({ errors: [serverError], enableSubmit: true });
                }
            }
            catch (errorResult) {
                const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                this.setState({ errors: [serverError], enableSubmit: true });
            }
        }
    }

    render() {
        let stateObject: any = this.props.location.state || { from: { pathname: "/" } };
        let from = stateObject.from;
        let { redirectToReferrer } = this.state;

        if (redirectToReferrer) {
            return <Redirect to={from} />;
        }

        if (this.props.user) {
            return <Redirect to="/" />;
        }

        const glooRedirectUrl = this.props.config.paymentsSiteUrl + from?.pathname
        const glooUrl = this.props.config.mainSiteUrl + '/account/gloo/login?redirectUri=' + encodeURIComponent(glooRedirectUrl);
        return (
            <div className="py-5">
                {FieldValidationError.hasGenericError(this.state.errors) &&
                    <div className="alert alert-danger" role="alert" dangerouslySetInnerHTML={{ __html: FieldValidationError.getGenericErrorSummary(this.state.errors) }} />
                }
                <div className="row">
                    <div className="offset-md-3 col-md-6">
                        <form onSubmit={e => this.handleFormSubmit(e)}>
                            <div className="order-md-1">
                                <div className="card">
                                    <div className="card-header primary-card-header">
                                        <h5 className="text-white">Sign In to SermonCentral</h5>
                                    </div>
                                    <div className="card-body">
                                        <p>Please sign in to make changes to your account.</p>
                                        <div className="mb-3">
                                            <label className="input-label" htmlFor="emailAddress">Email Address</label>
                                            <input type="email" className={FieldValidationError.isFieldInError(this.state.errors, 'Email') ? "form-control-lg form-control is-invalid" : "form-control-lg form-control"} id="emailAddress" required value={this.state.email} onChange={e => this.setState({ email: e.target.value })} />
                                            <div className="invalid-feedback" dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'Email') }} />
                                        </div>
                                        <div className="mb-3">
                                            <label className="input-label" htmlFor="password">Password</label>
                                            <input type="password" className={FieldValidationError.isFieldInError(this.state.errors, 'Password') ? "form-control-lg form-control is-invalid" : "form-control-lg form-control"} id="password" required value={this.state.password} onChange={e => this.setState({ password: e.target.value })} />
                                            <div className="invalid-feedback" dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'Password') }} />
                                        </div>

                                        <button className="btn btn-primary btn-block btn-lg mb-3" type="submit" disabled={!this.state.enableSubmit}>
                                            {this.state.enableSubmit &&
                                                <>SIGN IN</>
                                            }
                                            {!this.state.enableSubmit &&
                                                <>
                                                    PLEASE WAIT...
                                                <div className="spinner-border spinner-border-sm ml-4 mb-1" role="status" />
                                                </>
                                            }
                                        </button>
                                        <GlooButton url={glooUrl} />
                                        <a href={`${this.props.config.accountSiteUrl}/auth/forgot-password`} target="_blank" className="float-right">
                                            Forgot Password
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}