import React from "react";

export function GlooIcon() {
    return (
        <svg className="gloo-icon" width="24" height="24" viewBox="0 0 66 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1" y="1" width="64" height="31" rx="15.5" stroke="#41B768" strokeWidth="2" />
            <path d="M46.7397 20.3428C44.8287 20.3428 43.4559 18.824 43.4559 16.9365C43.4559 15.1031 44.8178 13.5953 46.7397 13.5953C48.6178 13.5953 49.9577 15.114 49.9577 16.9365C49.9577 18.824 48.6178 20.3428 46.7397 20.3428ZM46.7397 11.7402C43.6975 11.7402 41.4131 14.0726 41.4131 16.9365C41.4131 19.8438 43.6865 22.1978 46.7397 22.1978C49.7381 22.1978 52.0005 19.8438 52.0005 16.9365C52.0005 14.0726 49.749 11.7402 46.7397 11.7402Z" fill="#41B768" />
            <path d="M34.7895 20.3428C32.8785 20.3428 31.5057 18.824 31.5057 16.9365C31.5057 15.1031 32.8676 13.5953 34.7895 13.5953C36.6676 13.5953 38.0075 15.114 38.0075 16.9365C38.0075 18.824 36.6676 20.3428 34.7895 20.3428ZM34.7895 11.7402C31.7473 11.7402 29.4629 14.0726 29.4629 16.9365C29.4629 19.8438 31.7363 22.1978 34.7895 22.1978C37.7879 22.1978 40.0503 19.8438 40.0503 16.9365C40.0503 14.0726 37.7988 11.7402 34.7895 11.7402Z" fill="#41B768" />
            <path d="M27.651 7H25.6191V21.9487H27.651V7Z" fill="#41B768" />
            <path d="M13 16.9375C13 19.8448 15.2844 22.1988 18.3267 22.1988C19.5677 22.1988 20.666 21.7974 21.5446 21.1248V21.8517C21.5446 24.184 20.3914 25.0085 18.2827 25.0085L18.3047 26.8852C20.9186 26.8852 23.5655 25.9956 23.5655 21.396V11.828H21.6435L21.5556 12.8043C20.677 12.1426 19.5677 11.7412 18.3376 11.7412C15.2844 11.7412 13 14.0736 13 16.9375ZM18.3267 20.3438C16.4047 20.3438 15.0428 18.825 15.0428 16.9375C15.0428 15.1041 16.4047 13.5962 18.3267 13.5962C20.2047 13.5962 21.5446 15.115 21.5446 16.9375C21.5446 18.8359 20.2047 20.3438 18.3267 20.3438Z" fill="#41B768" />
        </svg>
    );
}