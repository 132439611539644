import { GTag } from "./GoogleAnalytics";

export class EventTracking {
  static CreateFullAccountSinglePage = {
    onPageLoad() {
      GTag.event("Page", {
        event_category: "Create Full Account",
        event_label: "Viewed",
      });
    },
    onSelectPlan(planName: string) {
      GTag.event("Plan", {
        event_category: "Create Full Account",
        event_label: `Selected ${planName}`,
      });
    },
    onChangePlan(planName: string) {
      GTag.event("Plan", {
        event_category: "Create Full Account",
        event_label: `Changed to ${planName}`,
      });
    },
    onPromoCodeLinkClick() {
      GTag.event("Promocode", {
        event_category: "Create Full Account",
        event_label: "Link Clicked",
      });
    },
    onPromoCodeSubmitted() {
      GTag.event("Promocode", {
        event_category: "Create Full Account",
        event_label: "Promocode submitted",
      });
    },
    onAccountCreated() {
      GTag.event("Account", {
        event_category: "Create Full Account",
        event_label: "Account created",
      });
    },
    onBillingInfoSubmitted() {
      GTag.event("Account", {
        event_category: "Create Full Account",
        event_label: "Billing Info Submitted",
      });
    },
  };

  static CreateFullAccountQuickSetup = {
    onPageLoad() {
      GTag.event("Page", {
        event_category: "Quick Setup",
        event_label: "Viewed",
      });
    },
    onSelectPlan(planName: string) {
      GTag.event("Plan", {
        event_category: "Quick Setup",
        event_label: `Selected ${planName}`,
      });
    },
    onChangePlan(planName: string) {
      GTag.event("Plan", {
        event_category: "Quick Setup",
        event_label: `Changed to ${planName}`,
      });
    },
    onPromoCodeLinkClick() {
      GTag.event("Promocode", {
        event_category: "Quick Setup",
        event_label: "Link Clicked",
      });
    },
    onPromoCodeSubmitted() {
      GTag.event("Promocode", {
        event_category: "Quick Setup",
        event_label: "Promocode submitted",
      });
    },
    onAccountCreated() {
      GTag.event("Account", {
        event_category: "Quick Setup",
        event_label: "Account created",
      });
    },
    onBillingInfoSubmitted() {
      GTag.event("Account", {
        event_category: "Quick Setup",
        event_label: "Billing Info Submitted",
      });
    },
  };

  static CreateEmbedAccountQuickSetup = {
    onPageLoad() {
      GTag.event("Page", {
        event_category: "Embed Setup",
        event_label: "Viewed",
      });
    },
    onSelectPlan(planName: string) {
      GTag.event("Plan", {
        event_category: "Embed Setup",
        event_label: `Selected ${planName}`,
      });
    },
    onChangePlan(planName: string) {
      GTag.event("Plan", {
        event_category: "Embed Setup",
        event_label: `Changed to ${planName}`,
      });
    },
    onPromoCodeLinkClick() {
      GTag.event("Promocode", {
        event_category: "Embed Setup",
        event_label: "Link Clicked",
      });
    },
    onPromoCodeSubmitted() {
      GTag.event("Promocode", {
        event_category: "Embed Setup",
        event_label: "Promocode submitted",
      });
    },
    onAccountCreated() {
      GTag.event("Account", {
        event_category: "Embed Setup",
        event_label: "Account created",
      });
    },
    onBillingInfoSubmitted() {
      GTag.event("Account", {
        event_category: "Embed Setup",
        event_label: "Billing Info Submitted",
      });
    },
  };

  static CreateAccount = {
    onPageLoad() {
      GTag.event("Page", {
        event_category: "Create Account",
        event_label: "Viewed",
      });
    },
    onSelectPlan(planName: string) {
      GTag.event("Plan", {
        event_category: "Create Account",
        event_label: `Selected ${planName}`,
      });
    },
    onChangePlan(planName: string) {
      GTag.event("Plan", {
        event_category: "Create Account",
        event_label: `Changed to ${planName}`,
      });
    },
    onPromoCodeLinkClick() {
      GTag.event("Promocode", {
        event_category: "Create Account",
        event_label: "Link Clicked",
      });
    },
    onPromoCodeSubmitted() {
      GTag.event("Promocode", {
        event_category: "Create Account",
        event_label: "Promocode submitted",
      });
    },
    onAccountCreated() {
      GTag.event("Account", {
        event_category: "Create Account",
        event_label: "Account created",
      });
    },
  };

  static BillingInfo = {
    onPageLoad() {
      GTag.event("Page", {
        event_category: "Billing Info",
        event_label: "Viewed",
      });
    },
    onSelectPlan(planName: string) {
      GTag.event("Plan", {
        event_category: "Billing Info",
        event_label: `Selected ${planName}`,
      });
    },
    onChangePlan(planName: string) {
      GTag.event("Plan", {
        event_category: "Billing Info",
        event_label: `Changed to ${planName}`,
      });
    },
    onPromoCodeLinkClick() {
      GTag.event("Promocode", {
        event_category: "Billing Info",
        event_label: "Link Clicked",
      });
    },
    onPromoCodeSubmitted() {
      GTag.event("Promocode", {
        event_category: "Billing Info",
        event_label: "Promocode submitted",
      });
    },
    onBillingInfoSubmitted() {
      GTag.event("Account", {
        event_category: "Billing Info",
        event_label: "Account created",
      });
    },
  };
}
