import * as React from "react";

const VerificationCard = ({ title, children, embed }: { title: string, children: any, embed: boolean }) => {
    if (embed) {
        return (
            <div className="card mb-3">
                <div className="card-header">
                    <h5>{title}</h5>
                </div>
                <div className="card-body">
                    {children}
                </div>
            </div>
        );
    }

    return (
        <div className="card mb-3">
            <div className="card-header primary-card-header">
                <h5 className='text-white'>{title}</h5>
            </div>
            <div className="card-body">
                {children}
            </div>
        </div>
    );
}
export default VerificationCard;