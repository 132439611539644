import * as React from 'react';
import { Link, RouteComponentProps, Redirect } from 'react-router-dom';
import { LocationDescriptor } from "history";

import ApplicationUser from '../../entities/ApplicationUser';
import AppConfig from '../../entities/AppConfig';
import { ProSubscriptionTerm, ProSubscriptionStatus } from '../../entities/ProSubscription';

interface Props extends RouteComponentProps<any> {
    user: ApplicationUser;
    config: AppConfig;
}

type State = {
    redirectTo: LocationDescriptor<any> | null;
};

export default class CancelSubscriptionConfirmation extends React.Component<Props, State> {

    redirectState: any = {};

    constructor(props: Props) {
        super(props);
        this.state = {
            redirectTo: null
        };
    }

    async componentDidMount() {

        document.title = "SermonCentral Payments - PRO Subscription Canceled";

        let redirectState = this.props.location.state;

        // Unlikely scenario, but user shouldn't be on this page unless they've been redirected, 
        // so redirect back to /cancel
        if (!redirectState || !(redirectState as any).redirectedFromCancel) {
            this.setState({ redirectTo: { pathname: '/cancel', state: this.redirectState } });
        }
    }

    render() {

        if (this.state.redirectTo) {
            return (
                <Redirect to={this.state.redirectTo} />
            );
        }
        else {
            return (
                <div className="container py-5">

                    <div className="row">
                        <div className="offset-md-2 col-md-8">
                            <div className="text-center py-5">
                                <h2 className="mb-4">PRO Subscription Canceled</h2>
                                <h6 className="mb-4">
                                    We're sorry to see you leave SermonCentral PRO.
                                    {this.props.user.proSubscription && this.props.user.proSubscription.cancelledDate &&
                                        <>
                                            &nbsp;Your cancellation will take effect on {new Date(this.props.user.proSubscription.cancelledDate).toLocaleDateString()}.
                                        </>
                                    }
                                    &nbsp;We hope you'll continue to use SermonCentral for inspiration in the future.

                                </h6>
                                <button className="btn btn-secondary ml-auto mr-auto d-block"><a href={this.props.config.mainSiteUrl} className="buttonLink">RETURN TO SERMONCENTRAL</a></button>
                            </div>
                        </div>
                    </div>

                </div>
            );
        }
    }
}