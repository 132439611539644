import * as React from 'react';
import { Link, RouteComponentProps, Redirect } from 'react-router-dom';
import { LocationDescriptor } from "history";

import ApplicationUser from '../../entities/ApplicationUser';
import AppConfig from '../../entities/AppConfig';
import { ProSubscriptionTerm, ProSubscriptionStatus } from '../../entities/ProSubscription';
import { ProSubscriptionService } from '../../services/ProSubscriptionService';
import { ServerResponse, ServerModelValidationResponse, FieldValidationError } from '../../services/ServiceHelper';


interface Props extends RouteComponentProps<any> {
    user: ApplicationUser;
    config: AppConfig;
    handleUpdateUser: (user: ApplicationUser | null) => void;
}

type State = {
    redirectTo: LocationDescriptor<any> | null;
    showNoSubscriptionError: boolean;
    caretPosTop: number;
    caretPosLeft: number;
    reason?: Reason;
    reasonDescription: string;
    confirmCancel: boolean;
    cancelImmediately: boolean;
    enableSubmit: boolean;
    errors: FieldValidationError[];
};

enum Reason {
    Expensive = 1,
    Difficult,
    Quality,
    Retiring,
    Other,
    FailedPayment
}

export default class CancelReason extends React.Component<Props, State> {
    dataLayer = (window as any).dataLayer = (window as any).dataLayer || [];
    redirectState: any = {};
    constructor(props: Props) {
        super(props);
        this.state = {
            redirectTo: null,
            showNoSubscriptionError: false,
            caretPosTop: 0,
            caretPosLeft: 0,
            confirmCancel: false,
            cancelImmediately: false,
            enableSubmit: true,
            reasonDescription: '',
            errors: []
        };
    }

    async componentDidMount() {

        document.title = "SermonCentral Payments - PRO Subscription Cancel Reason";

        if (!this.props.user.proSubscription) {

            this.setState({ showNoSubscriptionError: true });
        }
        else if (this.props.user.proSubscription.term === ProSubscriptionTerm.None) {

            this.setState({ redirectTo: { pathname: '/cancel/cant-cancel', state: this.redirectState } });
        }
        else if (this.props.user.proSubscription.status !== ProSubscriptionStatus.Active) {

            this.setState({ redirectTo: { pathname: '/cancel/cant-cancel', state: this.redirectState } });
        }
    }

    componentDidUpdate() {
        this.dataLayer.push({ 'event': 'optimize.activate' })
    }


    onClickReason(e: React.MouseEvent<HTMLElement>, reason: Reason) {
        e.preventDefault();

        let area = e.currentTarget.getBoundingClientRect();
        this.setState({
            caretPosLeft: area.left + (area.width / 2) - 18,
            caretPosTop: area.bottom - 12,
            reason
        });
    }

    determineLegacyReason() {
        let reason: string = '';

        switch (this.state.reason) {
            case Reason.Expensive:
                reason = 'Price is too expensive';
                break;
            case Reason.Difficult:
                reason = 'Too hard to use the website';
                break;
            case Reason.Quality:
                reason = 'Don\'t like the quality of the media';
                break;
            case Reason.Retiring:
                reason = 'Retiring';
                break;
            case Reason.FailedPayment:
                reason = "Failed payment, outstanding balance.  admin cancel."
                break;
            default:
                reason = 'Prefer Other Resource';
                break;
        }

        return reason;
    }

    async cancelSubscription() {
        if (!this.state.enableSubmit || !this.state.reason) {
            return;
        }

        try {
            this.setState({ enableSubmit: false });
            const response = await ProSubscriptionService.cancelSubscription(this.determineLegacyReason(), this.state.reasonDescription, this.state.cancelImmediately);

            if (ServerResponse.isSuccess<ApplicationUser>(response)) {

                this.props.handleUpdateUser(response.data);
                const redirectState = { redirectedFromCancel: true };
                this.setState({ redirectTo: { pathname: '/cancel/confirmation', state: redirectState } });
            }
            else if (ServerModelValidationResponse.isServerModelValidationResponse(response)) {
                if (response.valid) {
                    const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                    this.setState({ errors: [serverError], enableSubmit: true });
                }
                else {
                    this.setState({ errors: response.errors, enableSubmit: true });
                }
            }
            else if (ServerResponse.isError(response)) {
                const serverError: FieldValidationError = { field: "", errors: [response.message] };
                this.setState({ errors: [serverError], enableSubmit: true });
            }
            else {
                const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                this.setState({ errors: [serverError], enableSubmit: true });
            }
        }
        catch (errorResult) {
            const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
            this.setState({ errors: [serverError], enableSubmit: true });
        }
    }

    figureOutButton(reason: Reason) {
        if (!this.state.reason || this.state.reason === reason) {
            return "btn btn-outline-primary";
        }
        else {
            return "btn btn-outline-secondary";
        }
    }

    renderReason() {
        if (!this.state.reason) {
            return null;
        }

        let reason: number = +this.state.reason;

        switch (reason) {
            case Reason.Expensive: {
                return (
                    <>
                        <h5 id="expensive">
                            <small>
                                The price is too expensive. We get it. <br />  <br />
                                Please feel free to give us a call or <a href="https://www.sermoncentral.com/contactus">contact us</a> if you would like to request a discount.
                            </small>
                        </h5>
                    </>
                );
            }
            case Reason.Difficult: {
                return (
                    <>
                        <h5 id="difficult">
                            <small>
                                Your PRO subscription is too difficult to use. <br />  <br />
                                We're sorry to hear that. Please feel free to give us a call or <a href="https://www.sermoncentral.com/contactus">contact us</a> if there's anything specific we could make more clear.
                            </small>
                        </h5>
                    </>
                );
            }
            case Reason.Quality: {
                return (
                    <>
                        <h5 id="quality">
                            <small>
                                The media quality isn't sufficient. <br />  <br />
                                We're sorry to hear that. We add new media weekly, but we understand you prefer something different.
                                Please feel free to give us a call or <a href="https://www.sermoncentral.com/contactus">contact us</a> if there's anything specific we can do to make it better.
                            </small>
                        </h5>
                    </>
                );
            }
            case Reason.Retiring: {
                return (
                    <>
                        <h5 id="retiring">
                            <small>
                                You no longer need your subscription because you are either retiring or changing positions. <br />  <br />
                                We're sorry to see you go. Thank you for being a part of our community.
                            </small>
                        </h5>
                    </>
                );
            }
            case Reason.Other: {
                return (
                    <>
                        <h5 id="other">
                            <small>
                                You prefer other resources for your preaching. <br />  <br />
                                We get it and we're sorry to see you go. Please keep us in mind in the future, we'd love to have you back if you miss your PRO subscription.
                            </small>
                        </h5>
                    </>
                );
            }
            case Reason.FailedPayment: {
                return (
                    <>
                        <h5 id="other">
                            <small>
                                Failed payment, outstanding balance.  admin cancel.
                            </small>
                        </h5>
                    </>
                );
            }
            default:
                return null;
        }
    }

    render() {
        if (this.state.redirectTo) {
            return (
                <Redirect to={this.state.redirectTo} />
            );
        }
        else if (this.state.showNoSubscriptionError) {
            return (
                <div className="container py-5">
                    <div className="text-center py-5">
                        <h2 className="mb-4">Cannot Cancel PRO Subscription</h2>
                        <h6 className="mb-4">You do not have a subscription to SermonCentral PRO.</h6>
                        <button className="btn btn-secondary ml-auto mr-auto d-block"><a href={this.props.config.mainSiteUrl} className="buttonLink">RETURN TO SERMONCENTRAL</a></button>
                    </div>
                </div>
            );
        }
        else {
            return (
                <div className="py-5">
                    {FieldValidationError.hasGenericError(this.state.errors) &&
                        <div className="alert alert-danger" role="alert" dangerouslySetInnerHTML={{ __html: FieldValidationError.getGenericErrorSummary(this.state.errors) }} />
                    }
                    <div className="row">
                        <div className="offset-md-1 col-md-10">
                            <div className="order-md-1">
                                <div className="card">
                                    <div className="card-header primary-card-header">
                                        <h5 className="text-white">Are you sure you want to cancel?</h5>
                                    </div>
                                    <div className="card-body">
                                        <div id="cancel-explanation" className="mt-1">
                                            <h5>
                                                <small>
                                                    When you cancel your PRO subscription, you will lose access to all PRO benefits like top-rated sermon illustrations,
                                                    sermon downloads, church media downloads, sermon collections, and more.
                                                </small>
                                            </h5>
                                        </div>
                                        <div className="mt-1">
                                            <h5>
                                                <small>
                                                    Still want to cancel? <b>Select your reason below:</b>
                                                </small>
                                            </h5>
                                        </div>
                                        <div className="row mt-2 cancel-button-row">
                                            <div className="col-md-auto">
                                                <button type="button" onClick={e => this.onClickReason(e, Reason.Expensive)} className={this.figureOutButton(Reason.Expensive)}>Too Expensive</button>
                                            </div>
                                            <div className="col-md-auto">
                                                <button type="button" onClick={e => this.onClickReason(e, Reason.Difficult)} className={this.figureOutButton(Reason.Difficult)}>Difficult to Use</button>
                                            </div>
                                            <div className="col-md-auto">
                                                <button type="button" onClick={e => this.onClickReason(e, Reason.Quality)} className={this.figureOutButton(Reason.Quality)}>Media Quality</button>
                                            </div>
                                            <div className="col-md-auto">
                                                <button type="button" onClick={e => this.onClickReason(e, Reason.Retiring)} className={this.figureOutButton(Reason.Retiring)}>Retiring/Job Change</button>
                                            </div>
                                            <div className="col-md-auto">
                                                <button type="button" onClick={e => this.onClickReason(e, Reason.Other)} className={this.figureOutButton(Reason.Other)}>Prefer Other Resource</button>
                                            </div>
                                        </div>
                                        <div className="row mt-1 cancel-select">
                                            <div className="col-12">
                                                <label className="non-mobile-cancel-ask input-label" htmlFor="cancellationReason">Why are you canceling your subscription to SermonCentral PRO?</label>
                                                <select className={FieldValidationError.isFieldInError(this.state.errors, 'CancellationReason') ? "form-control-lg form-control is-invalid" : "form-control-lg form-control"} id="cancellationReason" required value={this.state.reason} onChange={e => this.setState({ reason: (e.target.value as any) })}>
                                                    <option value={undefined}>Why are you cancelling?</option>
                                                    <option value={Reason.Expensive}>Too Expensive</option>
                                                    <option value={Reason.Difficult}>Difficult to Use</option>
                                                    <option value={Reason.Quality}>Media Quality</option>
                                                    <option value={Reason.Retiring}>Retiring/Job Change</option>
                                                    <option value={Reason.Other}>Prefer Other Resource</option>
                                                </select>
                                            </div>
                                        </div>
                                        {this.state.reason &&
                                            <div className="card" style={{ marginTop: 16, borderColor: "#007bff" }}>
                                                <div className="card-body" style={{ marginTop: 9 }}>
                                                    {this.renderReason()}
                                                </div>
                                            </div>
                                        }
                                        {this.props.user.inAdminImpersonationMode &&
                                            <div className="mt-2 alert alert-info">
                                                <h5>ADMIN ONLY</h5>
                                                <label className="checkbox-container">
                                                    <input type="checkbox" checked={this.state.cancelImmediately} onChange={(e) => this.setState({ cancelImmediately: !this.state.cancelImmediately })} />
                                                    <span className="checkmark"></span>
                                                    Cancel the user's PRO subscription immediately. They will immediately lose access to their PRO benefits. This will generate a refund.
                                                    </label>
                                                <div className="mt-1">
                                                    <h5>
                                                        <small>
                                                            You can click Failed Payment to apply that as cancellation reason.
                                                        </small>
                                                        <button type="button" onClick={e => this.onClickReason(e, Reason.FailedPayment)} className="ml-1 btn btn-outline-info">Failed Payment</button>
                                                    </h5>
                                                </div>
                                            </div>
                                        }
                                        <div className="row mt-3">
                                            <div className="non-mobile-help col-md-6">
                                                <h5><small><b>Need help?</b> Call (866) 899-4426 or <a href="https://www.sermoncentral.com/contactus">contact us online</a>.</small></h5>
                                            </div>
                                            <div className="col-md-6">
                                                <div>
                                                    <label id="terms-label" style={{ opacity: this.state.reason ? 1 : .6 }}>
                                                        <input disabled={this.state.reason ? false : true} type="checkbox" checked={this.state.confirmCancel} id="confirmCheckbox" onChange={(e) => this.setState({ confirmCancel: !this.state.confirmCancel })} />
                                                        &nbsp; I understand I will lose access to all PRO benefits when I cancel my subscription.
                                                    </label>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        {
                                                            !this.state.confirmCancel &&
                                                            <button disabled type="button" className="cancel-yes btn btn-outline-secondary mr-4">Yes, Cancel Subscription</button>
                                                        }
                                                        {
                                                            this.state.reason && this.state.confirmCancel &&
                                                            <button type="button" disabled={!this.state.enableSubmit} className="cancel-yes btn btn-primary mr-4" onClick={() => this.cancelSubscription()}>
                                                                {this.state.enableSubmit &&
                                                                    <> Yes, Cancel Subscription</>
                                                                }
                                                                {!this.state.enableSubmit &&
                                                                    <>
                                                                        PLEASE WAIT...
                                                                    <div className="spinner-border spinner-border-sm ml-4 mb-1" role="status" />
                                                                    </>
                                                                }
                                                            </button>
                                                        }
                                                        <a
                                                            className="anchor-item cancel-anchor" style={{ textDecoration: 'underline', whiteSpace: 'nowrap' }}
                                                            href={`${this.props.config.accountSiteUrl}`}>No, don't cancel
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="mobile-help col-md-6">
                                                <h5><small><b>Need help?</b> Call (866) 899-4426 or <a href="https://www.sermoncentral.com/contactus">contact us online</a>.</small></h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.reason &&
                        <div className="caret" style={{ left: this.state.caretPosLeft, top: this.state.caretPosTop, display: this.state.reason === Reason.FailedPayment ? "none" : "initial" }}>
                            <div />
                            <div />
                        </div>
                    }
                </div>
            );
        }
    }
}