import * as React from 'react';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import {useEffect, useState} from 'react';
import AppConfig from "../../entities/AppConfig";
import ApplicationUser from '../../entities/ApplicationUser';
import Verify from "../../components/Verification/Verify";

interface ConfirmationProps extends RouteComponentProps<{ id: string }> {
    user: ApplicationUser;
    config: AppConfig;
}

declare const window: any;
declare const gtag: any;

const SubscribeConfirmation = ({user, config, location}: ConfirmationProps) => {
    const [returnUrl, setReturnUrl] = useState<string>("")
    const [redirectToSubscribe, setRedirectToSubscribe] = useState<boolean>(false);
    const [isFromEmbed, setIsFromEmbed] = useState<boolean>(false)
    const [showVerify, setShowVerify] = useState(true);
    let timeoutId: NodeJS.Timeout;
    
    useEffect(()=>{
        if (config.mainSiteUrl){
            setReturnUrl(config.mainSiteUrl)
        }
    },[config])
    
    useEffect(() => {
        document.title = "SermonCentral Payments - Subscription Confirmation";

        let redirectState = location.state;

        // Unlikely scenario, but user shouldn't be on this page unless they've been redirected, 
        // so redirect back to /subscribe
        if (!redirectState || !user.proSubscription) {
            setRedirectToSubscribe(true)
            return;
        }

        if ((redirectState as any).returnUrl) {
                setReturnUrl((redirectState as any).returnUrl)
        }

        // We could force the iframe to open links to the base, but since that is controlled by Caleb,
        // I would rather stay explicit and keep the decision in the page.  
        if ((redirectState as any).redirectedFromEmbed) {
                setIsFromEmbed( true)
        }

        if (timeoutId) {
            clearTimeout((timeoutId))
        }
        timeoutId = setTimeout(() => {
            window.dataLayer.push({
              'event':'orderComplete',
              'orderValue': user.proSubscription?.nextBillPrice,
              'orderCurrency': user.currency || "USD",
              'orderId': user.id, 
              'ecData': { 'email': user.email, "phone_number": user.phoneNumber?.replace(/\D/g, "")}
            });
            
            if ('gtag' in window) {
                const planName = user.proSubscription?.planName ?? '';
                
                let sku;
                if (planName.indexOf('Premium') >= 0) {
                    sku = '2c92a0fd61e79fd40161fc8fd3264af7';
                }
                else if (planName.indexOf('Basic') >= 0) {
                    sku = '2c92a0fe631cf034016321a3a6ee256c';
                }
                else if (planName.indexOf('Monthly') >= 0) {
                    sku = '2c92a0fb501f2f53015021c3b91c2cf3';
                }
                else {
                    sku = '2c92a0fb501f2f53015021c3b9082cf1';
                }

                window.gaItem = {
                    "id": sku,
                    "name": planName,
                    "brand": "SermonCentral",
                    "category": "PRO Subscription",
                    "quantity": "1",
                    "price": user.proSubscription?.nextBillPrice,
                };
                window.gaTransaction = {
                    "transaction_id": user.id,
                    "affiliation": "SermonCentralPro",
                    "value": user.proSubscription?.nextBillPrice,
                    "currency": user.currency || "USD",
                    "shipping": "0",
                    "tax": "0",
                    "items": [
                        window.gaItem
                    ],
                    "coupon": (redirectState as any).appliedPromoCode || ''
                };

                gtag('event', 'purchase', window.gaTransaction);
            }
            
        }, 1000);

        return () => clearTimeout(timeoutId);
    }, [])

    const handleVerify = (dataLayerEvent: any) => {
        if (dataLayerEvent) {
            window.dataLayer.push(dataLayerEvent);
        }
        setShowVerify(false);
    }
    
    if (redirectToSubscribe)
        return <Redirect to={{ pathname: "/subscribe" }} />;

    if (showVerify)
        return <Verify user={user} onVerify={handleVerify} />;

    return (
        <div className="row">
            <div className="offset-md-2 col-md-8">
                <div className="text-center py-5">
                    <h2 className="mb-4">Thank you for signing up for SermonCentral PRO!</h2>
                    <h6 className="mb-4">
                        Return to SermonCentral to use PRO features, manage your subscription from your <a
                        target={isFromEmbed ? '_PARENT' : undefined} href={config.accountSiteUrl}>account
                        dashboard</a>, or view your <a target={isFromEmbed ? '_PARENT' : undefined}
                                                        href={`${config.mainSiteUrl}/pro-benefits`}>PRO
                        benefits</a>.
                    </h6>
                    <button className="btn btn-secondary ml-auto mr-auto d-block"><a
                        target={isFromEmbed ? '_PARENT' : undefined} href={returnUrl}
                        className="buttonLink">RETURN TO SERMONCENTRAL</a></button>
                </div>
            </div>
        </div>
    );
}

export default SubscribeConfirmation;