import * as React from 'react';
import { Link, RouteComponentProps, Redirect } from 'react-router-dom';

import ApplicationUser from '../../entities/ApplicationUser';

import { FieldValidationError, ServerResponse, ServerModelValidationResponse } from '../../services/ServiceHelper';

import { AuthenticationService } from '../../services/AuthenticationService';

interface Props extends RouteComponentProps<any> {
    user: ApplicationUser | null;
    handleUpdateUser: (user: ApplicationUser | null) => void;
}

type State = {
    enableSubmit: boolean;
    email: string;
    password: string;
    redirectToSubscribe: boolean;
    errors: FieldValidationError[];
};

export default class SubscribeAuthChallenge extends React.Component<Props, State> {

    redirectState: any = {};

    constructor(props: Props) {
        super(props);
        this.state = {
            enableSubmit: true,
            email: '',
            password: '',
            redirectToSubscribe: false,
            errors: []
        };
    }

    async componentDidMount() {

        document.title = "SermonCentral Payments - Auth Challenge";

        let redirectState = this.props.location.state;

        // Unlikely scenario, but user shouldn't be on this page unless they've been redirected
        // and aren't signed in, so redirect back to /subscribe
        if (!redirectState || this.props.user) {
            this.setState({
                redirectToSubscribe: true
            });
        }
        else {
            const email = (redirectState as any).email;
            delete (redirectState as any).email;

            this.redirectState = redirectState;
            this.setState({
                email: email
            });
        }
    }

    async handleFormSubmit(e: React.FormEvent<HTMLFormElement>) {

        e.preventDefault();
        if (!this.state.enableSubmit) {
            return;
        }

        if (this.state.email && this.state.password) {
            try {
                this.setState({ enableSubmit: false });
                const response = await AuthenticationService.signIn(this.state.email, this.state.password, false, 'AuthChallenge');

                if (ServerResponse.isSuccess<ApplicationUser>(response)) {
                    this.props.handleUpdateUser(response.data);
                    this.setState({ redirectToSubscribe: true });
                }
                else if (ServerModelValidationResponse.isServerModelValidationResponse(response)) {
                    if (response.valid) {
                        const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                        this.setState({ errors: [serverError], enableSubmit: true });
                    }
                    else {
                        this.setState({ errors: response.errors, enableSubmit: true });
                    }
                }
                else if (ServerResponse.isError(response)) {
                    const serverError: FieldValidationError = { field: "", errors: [response.message] };
                    this.setState({ errors: [serverError], enableSubmit: true });
                }
                else {
                    const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                    this.setState({ errors: [serverError], enableSubmit: true });
                }
            }
            catch (errorResult) {
                const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                this.setState({ errors: [serverError], enableSubmit: true });
            }
        }
    }

    render() {

        if (this.state.redirectToSubscribe) {
            return <Redirect to={{ pathname: "/subscribe", state: this.redirectState }} />;
        }

        return (
            <div className="py-5">
                {FieldValidationError.hasGenericError(this.state.errors) &&
                    <div className="alert alert-danger" role="alert" dangerouslySetInnerHTML={{ __html: FieldValidationError.getGenericErrorSummary(this.state.errors) }} />
                }
                <div className="row">
                    <div className="offset-lg-3 col-lg-6">
                        <form onSubmit={e => this.handleFormSubmit(e)}>
                            <div className="card">
                                <div className="card-header primary-card-header">
                                    <h5 className="text-white">Sign In to SermonCentral</h5>
                                </div>
                                <div className="card-body">
                                    <p>To make changes to your account, please confirm your identity by signing in.</p>
                                    <div className="mb-3">
                                        <label className="input-label" htmlFor="emailAddress">Email Address</label>
                                        <input type="email" className={FieldValidationError.isFieldInError(this.state.errors, 'Email') ? "form-control-lg form-control is-invalid" : "form-control-lg form-control"} id="emailAddress" required value={this.state.email} onChange={e => this.setState({ email: e.target.value })} />
                                        <div className="invalid-feedback" dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'Email') }} />
                                    </div>
                                    <div className="mb-3">
                                        <label className="input-label" htmlFor="password">Password</label>
                                        <input type="password" className={FieldValidationError.isFieldInError(this.state.errors, 'Password') ? "form-control-lg form-control is-invalid" : "form-control-lg form-control"} id="password" required value={this.state.password} onChange={e => this.setState({ password: e.target.value })} />
                                        <div className="invalid-feedback" dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'Password') }} />
                                    </div>

                                    <button className="btn btn-primary btn-block btn-lg mb-4" type="submit" disabled={!this.state.enableSubmit}>
                                        {this.state.enableSubmit &&
                                            <>SIGN IN</>
                                        }
                                        {!this.state.enableSubmit &&
                                            <>
                                                PLEASE WAIT...
                                                <div className="spinner-border spinner-border-sm ml-4 mb-1" role="status" />
                                            </>
                                        }
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}