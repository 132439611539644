import { ServerResponse, WebClient } from '../services/ServiceHelper';
import ChurchInfo from "../entities/ChurchInfo";

export interface VerificationDTO {
    attemptsRemaining: number,
    expirationDate: string,
    isVerified: boolean,
}

export class VerificationService {
    
    public static async ensureVerificationExists() {
        const maybeExistingVerification = await VerificationService.getVerification();
        if (ServerResponse.isSuccess<VerificationDTO>(maybeExistingVerification)) {
            if (maybeExistingVerification.data.isVerified === true || maybeExistingVerification.data.attemptsRemaining === 0 || (Date.parse(maybeExistingVerification.data.expirationDate + "Z") < new Date().valueOf() )) {
                return await VerificationService.generateNewVerificationCode();
            }
            return maybeExistingVerification;
        }else if (ServerResponse.isError(maybeExistingVerification) && maybeExistingVerification.statusCode === 404){
            return await VerificationService.generateNewVerificationCode();
        }
        return maybeExistingVerification;
    }
    
    public static async getChurchInfo() {
        return await WebClient.Get<ChurchInfo>("api/Verification/GetChurchInfo");
    }

    public static async updateChurchInfo(data: Omit<ChurchInfo, "country">) {
        return await WebClient.Post<ChurchInfo>("api/Verification/UpdateChurchInfo", data);
    }

    public static async getVerification() {
        return await WebClient.Get("api/Verification/GetVerification");
    }
    
    public static async generateNewVerificationCode() {
        return await WebClient.Post("api/Verification/GenerateNewVerificationCode", {}); 
    }

    public static async sendVerificationCodeToPhone(phone: string) {
        return await WebClient.Post("api/Verification/SendVerificationCodeToPhone", { phoneNumber: phone }); 
    }

    public static async sendVerificationCodeToEmail(email: string) {
        return await WebClient.Post("api/Verification/SendVerificationCodeToEmail", { email: email });
    }

    public static async attemptVerification(verificationCode: string) {
        return await WebClient.Post("api/Verification/AttemptVerification", { verificationCode });
    }

    public static async getStates() {
        return await WebClient.Get("api/Verification/GetStates")
    }
}