import {useEffect, useState} from "react";
import {VerificationDTO, VerificationService} from "../../services/VerificationService";
import {FieldValidationError, ServerResponse} from "../../services/ServiceHelper";
import * as React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import VerificationCard from "./VerificationCard";
import './verify.css'

const VerifyEnterCode = ({ nextStep, previousStep, resendPreference, resendAddress, embed }: { nextStep: Function, previousStep: Function, resendPreference: "phone" | "email" , resendAddress: string, embed: boolean }) => {
    const [errors, setErrors] = useState<FieldValidationError[]>([]);
    const [code1, setCode1] = useState<string>("");
    const [code2, setCode2] = useState<string>("");
    const [code3, setCode3] = useState<string>("");
    const [code4, setCode4] = useState<string>("");
    const [enableSubmit, setEnableSubmit] = useState(true);

    useEffect(() => {
        if (code4 !== "" && enableSubmit) {
            verifyCode();
        }
    }, [code4, enableSubmit]);

    const resetCode = () => {
        setCode1("");
        setCode2("");
        setCode3("");
        setCode4("");
        document.getElementById('code1')?.focus();
    }

    const resend = async () => {
        setErrors(errors => []);

        const ve = await VerificationService.ensureVerificationExists();
        if (ServerResponse.isError(ve)) {
            setErrors([{ field: 'code', errors: ["There was an error. Please try again!"] }]);
            return;
        }

        switch(resendPreference) {
            case "phone":
                await VerificationService.sendVerificationCodeToPhone(resendAddress);
                break;
            case "email":
                await VerificationService.sendVerificationCodeToEmail(resendAddress);
                break;
            default:
                setErrors([
                    { field: "code", errors: [`There was an error resending the code to ${resendAddress}. Please try again.`] }
                ]);
                return;
        }

        resetCode();
    }

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!enableSubmit) {
            return;
        }
        
        await verifyCode();
    }

    const verifyCode = async () => {
        setEnableSubmit(false);
        setErrors(errors => []);

        const res = await VerificationService.attemptVerification(`${code1}${code2}${code3}${code4}`);

        if (ServerResponse.isSuccess<VerificationDTO>(res)) {
            if (res.data.isVerified) {
                nextStep();
                setEnableSubmit(true);
            } else {
                const errorMessage = res.data.attemptsRemaining === 0
                    ? "The verification code has expired. Please request a new code by clicking \"Resend code\" above."
                    : `The entered code was incorrect. You have ${res.data.attemptsRemaining} attempts remaining. Please try again.`
                setErrors([
                    { field: "code", errors: [errorMessage] }
                ]);
                resetCode();
                setEnableSubmit(true);
                return;
            }
        } else {
            setErrors([
                { field: "code", errors: ["There was a problem. Please try again!"] }
            ]);
            resetCode();
            setEnableSubmit(true);
            return;
        }

    }

    const shouldHighlightError = (inputName: string) => {
        return FieldValidationError.isFieldInError(errors, inputName) || FieldValidationError.isFieldInError(errors, 'code')
    }

    const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
        let data = e.clipboardData.getData("text/plain");
        if (isNaN(+data)) {
            return;
        }

        setCode1(data[0] ?? "");
        setCode2(data[1] ?? "");
        setCode3(data[2] ?? "");
        setCode4(data[3] ?? "");
    }

    return (
        <VerificationCard title="Verification code sent" embed={embed}>
            <p>You should receive a verification code sent to your device within 1 minute. Didn't get it? <a href="#"
                                                                                                             onClick={(e) => {
                                                                                                                 e.preventDefault();
                                                                                                                 resend()
                                                                                                             }}>Resend
                code</a></p>

            <label> Enter your code below:</label>

            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <div className="row justify-content-center flex-nowrap">
                        <div className="col-xs-4 mx-2">
                            <input type="text" value={code1} id="code1" onChange={(e) => {
                                if (!isNaN(+e.target.value)) {
                                    setCode1(e.target.value);

                                    if (e.target.value.trim().length) {
                                        document.getElementById('code2')?.focus()
                                    }
                                }
                            }} required minLength={1}
                                   maxLength={1}
                                   onPaste={(e) => handlePaste(e)}
                                   className={`form-control px-0 form-control-lg text-center ${shouldHighlightError('code1') ? 'border-danger' : null}`}/>
                        </div>

                        <div className="col-xs-4 mx-2">
                            <input type="text" value={code2} id="code2" onChange={(e) => {
                                if (!isNaN(+e.target.value)) {
                                    setCode2(e.target.value);

                                    if (e.target.value.trim().length) {
                                        document.getElementById('code3')?.focus()
                                    }
                                }
                            }}  required minLength={1}
                                   maxLength={1}
                                   onPaste={(e) => handlePaste(e)}
                                   className={`form-control px-0 form-control-lg text-center ${shouldHighlightError('code2') ? 'border-danger' : null}`}/>
                        </div>

                        <div className="col-xs-4 mx-2">
                            <input type="text" value={code3} id="code3" onChange={(e) => {
                                if (!isNaN(+e.target.value)) {
                                    setCode3(e.target.value);

                                    if (e.target.value.trim().length) {
                                        document.getElementById('code4')?.focus()
                                    }
                                }
                            }}  required minLength={1}
                                   maxLength={1}
                                   onPaste={(e) => handlePaste(e)}
                                   className={`form-control px-0 form-control-lg text-center ${shouldHighlightError('code3') ? 'border-danger' : null}`}/>
                        </div>

                        <div className="col-xs-4 mx-2">
                            <input type="text" value={code4} id="code4" onChange={(e) => {
                                if (!isNaN(+e.target.value)) {
                                    setCode4(e.target.value)
                                }
                            }} required minLength={1}
                                   maxLength={1}
                                   onPaste={(e) => handlePaste(e)}
                                   className={`form-control px-0 form-control-lg text-center ${shouldHighlightError('code4') ? 'border-danger' : null}`}/>
                        </div>

                    </div>

                    {FieldValidationError.isFieldInError(errors, "code") ?
                        <div className="text-danger"
                             dangerouslySetInnerHTML={{__html: FieldValidationError.getFieldErrorSummary(errors, "code")}}
                        /> : null
                    }
                </div>

                <button type="submit" className="btn btn-primary btn-block btn-lg mb-4" style={{backgroundColor: embed ? "#3b9d42" : "default"}} disabled={!enableSubmit}>
                    {enableSubmit && <>CONTINUE</>}
                    {!enableSubmit &&
                        <>
                            PLEASE WAIT...
                            <div
                                className="spinner-border spinner-border-sm ml-4 mb-1"
                                role="status"
                            />
                        </>
                    }
                </button>
            </form>

            <button type="button" className="btn btn-link p-0 text-decoration-none" onClick={() => previousStep()}><FontAwesomeIcon
                icon={faArrowLeft}/>
                {" "}Back</button>
        </VerificationCard>
    );
}

export default VerifyEnterCode;