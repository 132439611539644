import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from './app';

import axios from 'axios';
axios.defaults.withCredentials = true;

function renderApp() {
    const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href')!;
    ReactDOM.render(
        <App baseUrl={baseUrl} />,
        document.querySelector('#root')
    );
}

renderApp();