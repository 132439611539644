import PostalCodeLocation from "../entities/PostalCodeLocation";
import { ServerResponse, WebClient } from "../services/ServiceHelper";

export class PostalCodeService {
  static async getUSCanPostalCode(postalCode: string) {
    var response = await WebClient.Get<PostalCodeLocation>(
      `api/PostalCodeLocation/GetPostalCodeLocation/${postalCode}`
    );

    if (ServerResponse.isSuccess<PostalCodeLocation>(response)) {
      return response.data;
    } else {
      return null;
    }
  }
}
