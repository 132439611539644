
import { ServerResponse, WebClient } from '../services/ServiceHelper';

export class AccountService {
    public static async hasPayPal() {

        var response = await WebClient.Get<boolean>("api/Account/HasPayPal");
        if (ServerResponse.isSuccess<boolean>(response)) {
            return response.data;
        }
        else {
            return null;
        }
    }

    public static async hasHadTrial() {
        var response = await WebClient.Get<boolean>("api/Account/HasHadTrial");
        if (ServerResponse.isSuccess<boolean>(response)) {
            return response.data;
        }
        else {
            return true;
        }
    }

    public static async hasHadMakerTrial() {
        var response = await WebClient.Get<boolean>("api/Account/HasHadMakerTrial");
        if (ServerResponse.isSuccess<boolean>(response)) {
            return response.data;
        }
        else {
            return true;
        }
    }
}