import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import ApplicationUser from "./entities/ApplicationUser";
import "./layout.css";
import Logo from "./resources/sc-logo.png";

interface Props extends RouteComponentProps<any> {
  user: ApplicationUser | null;
  children?: React.ReactNode;
}

type State = {};

class Layout extends React.Component<Props, State> {
  noHeaderPaths = [
    "/subscribe/create-full-account",
    "/subscribe/quick-setup",
    "/subscribe/embed",
    "/subscribe/bundle",
  ];

  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    let showHeaderBar =
      this.noHeaderPaths.indexOf(this.props.location.pathname) === -1
        ? true
        : false;

    return (
      <div className="container-wrapper">
        {showHeaderBar && (
          <div className="header">
            <div className="container">
              <img className="logo" src={Logo} />
              {this.props.user && (
                <span className="user-label">
                  {this.props.user.firstName} {this.props.user.lastName}
                </span>
              )}
              {this.props.user && this.props.user.profileImageUrl && (
                <img
                  className="user-image rounded-circle"
                  src={this.props.user.profileImageUrl}
                />
              )}
            </div>
          </div>
        )}
        <div className="container">{this.props.children}</div>
      </div>
    );
  }
}

export default withRouter(Layout);
