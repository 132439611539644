import ApplicationUser from "../entities/ApplicationUser";
import MediaItem from "../entities/MediaItem";
import MediaItemPromoCode from "../entities/MediaItemPromoCode";
import MediaItemPurchase from "../entities/MediaItemPurchase";
import { PromoCodeDiscountType } from "../entities/PromoCodeDiscountType";
import { ServerResponse, WebClient } from "./ServiceHelper";

export class MediaItemPurchaseService {
  static getMediaItemPriceIncludingDiscountAndTax(
    mediaItem: MediaItem,
    tax: number,
    appliedPromoCode?: MediaItemPromoCode
  ) {
    var purchasePrice = mediaItem.salePrice
      ? mediaItem.salePrice
      : mediaItem.listPrice
      ? mediaItem.listPrice
      : 0;

    var finalPrice = purchasePrice;

    if (appliedPromoCode) {
      if (appliedPromoCode.discountType === PromoCodeDiscountType.PercentOff) {
        var discountPercentage = appliedPromoCode.discountAmount / 100;
        if (discountPercentage == 1) {
          finalPrice = 0;
        } else {
          var discountAmount = purchasePrice * discountPercentage;
          finalPrice = this.roundToTwo(purchasePrice - discountAmount);
        }
      } else if (
        appliedPromoCode.discountType === PromoCodeDiscountType.DollarOff
      ) {
        finalPrice = purchasePrice - appliedPromoCode.discountAmount;
      }
    }

    finalPrice = finalPrice + tax;

    return Math.max(0, finalPrice);
  }

  static async getMediaItem(id: number) {
    var response = await WebClient.Get<MediaItem>(
      `api/MediaItemPurchases/GetMediaItem/${id}`
    );
    if (ServerResponse.isSuccess<MediaItem>(response)) {
      return response.data;
    } else {
      return null;
    }
  }

  static async purchaseMediaItem(
    mediaItemId: number,
    creditCardId: string,
    nameOnCard: string,
    creditCardNumber: string,
    cv2: string,
    expirationMonth: number,
    expirationYear: number,
    postalCode: string,
    saveCreditCard: boolean,
    promoCode: string,
    region?: string,
    country?: string
  ) {
    return await WebClient.Post<MediaItemPurchase>(
      "api/MediaItemPurchases/PurchaseMediaItem",
      {
        mediaItemId,
        creditCardId,
        nameOnCard,
        creditCardNumber,
        cv2,
        expirationMonth,
        expirationYear,
        postalCode,
        saveCreditCard,
        promoCode,
        region,
        country,
      }
    );
  }

  static async subscribeToProPremiumAndGiftMediaItem(
    mediaItemId: number,
    creditCardId: string,
    nameOnCard: string,
    creditCardNumber: string,
    cv2: string,
    expirationMonth: number,
    expirationYear: number,
    postalCode: string,
    phoneNumber: string,
    region?: string,
    country?: string
  ) {
    return await WebClient.Post<ApplicationUser>(
      "api/MediaItemPurchases/SubscribeToProPremiumAndGiftMediaItem",
      {
        mediaItemId,
        creditCardId,
        nameOnCard,
        creditCardNumber,
        cv2,
        expirationMonth,
        expirationYear,
        postalCode,
        phoneNumber,
        region,
        country,
      }
    );
  }

  static async upgradeToProPremiumAndGiftMediaItem(
    mediaItemId: number,
    creditCardId: string,
    nameOnCard: string,
    creditCardNumber: string,
    cv2: string,
    expirationMonth: number,
    expirationYear: number,
    postalCode: string,
    phoneNumber: string,
    region?: string,
    country?: string
  ) {
    return await WebClient.Post<ApplicationUser>(
      "api/MediaItemPurchases/UpgradeToProPremiumAndGiftMediaItem",
      {
        mediaItemId,
        creditCardId,
        nameOnCard,
        creditCardNumber,
        cv2,
        expirationMonth,
        expirationYear,
        postalCode,
        phoneNumber,
        region,
        country,
      }
    );
  }

  static async validatePromoCode(promoCode: string, mediaItemId: number) {
    return await WebClient.Post<MediaItemPromoCode>(
      "api/MediaItemPurchases/ValidatePromoCode",
      { promoCode, mediaItemId }
    );
  }

  static roundToTwo(number: number) {
    return +(Math.round(+(number + "e+2")) + "e-2");
  }
}
