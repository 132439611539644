
import * as React from "react";
import { Link, RouteComponentProps, Redirect } from "react-router-dom";
import { LocationDescriptor } from "history";

import AppConfig from "../../entities/AppConfig";
import ApplicationUser from "../../entities/ApplicationUser";
import { ProSubscriptionTerm, ProSubscriptionStatus, ProSubscriptionTier } from "../../entities/ProSubscription";
import { getParameterByName } from '../../services/ServiceHelper';

interface Props extends RouteComponentProps<any> {
    config: AppConfig;
    user: ApplicationUser;
    hasPaypal: boolean;
}

type State = {
    redirectTo: LocationDescriptor<any> | null;
    showGenericError: boolean;
};

export default class UpgradeRouter extends React.Component<Props, State> {
    redirectState: any = {};

    constructor(props: Props) {
        super(props);
        this.state = {
            redirectTo: null,
            showGenericError: false,
        };
    }

    componentDidMount() {

        document.title = "SermonCentral Payments - Upgrade";

        try {
            let plan = getParameterByName('plan');
            let returnUrl = getParameterByName('returnUrl');

            let currency = this.props.user.currency;

            if (this.props.hasPaypal) {
                (this.redirectState as any).previousLocation = "Upgrade";
                this.setState({ redirectTo: { pathname: '/feature-unavailable', state: this.redirectState } });
            }

            else if (!this.props.user.proSubscription) {
                this.setState({ showGenericError: true });
            }
            else if (this.props.user.proSubscription.term === ProSubscriptionTerm.None) {
                this.setState({ redirectTo: { pathname: '/upgrade/cant-upgrade', state: this.redirectState } });
            }
            else if (this.props.user.proSubscription.status !== ProSubscriptionStatus.Active) {
                this.setState({ redirectTo: { pathname: '/upgrade/cant-upgrade', state: this.redirectState } });
            }
            else if (this.props.user.proSubscription.tier === ProSubscriptionTier.Premium) {
                this.setState({ redirectTo: { pathname: '/upgrade/cant-upgrade', state: this.redirectState } });
            }
            else if (!this.props.user.proSubscription.inTrial) {
                this.setState({ redirectTo: { pathname: `/upgrade/tier`, state: { returnUrl: returnUrl, plan: plan } } });
            }
            else if (this.props.user.proSubscription.inTrial) {
                this.setState({ redirectTo: { pathname: `/upgrade/from-trial`, state: { returnUrl: returnUrl } } });
            }
            else {
                this.setState({ showGenericError: true });
            }
        }
        catch {
            this.setState({ showGenericError: true });
        }
    }

    render() {

        if (this.state.redirectTo) {
            return (
                <Redirect to={this.state.redirectTo} />
            );
        }
        else if (this.state.showGenericError) {
            return (
                <div className="container py-5">
                    <div className="text-center py-5">
                        <h2>Something Went Wrong :'(</h2>
                        <h6>Please contact our <a href={`${this.props.config.mainSiteUrl}/contactus`}>customer service</a> team for help.</h6>
                    </div>
                </div>
            );
        }
        else {
            return (
                <img className="sc-content-spinner" src="//i.cdn-sc.com/Logos/sc-full-spinner-gray.gif" />
            );
        }
    }
}
