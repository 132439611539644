import { WebClient, ServerResponse } from '../services/ServiceHelper';
import AppConfig from '../entities/AppConfig';

export class AppConfigService {

    static async getAppConfig() {

        var response = await WebClient.Get<AppConfig>("api/AppConfig");
        if (ServerResponse.isSuccess<AppConfig>(response)) {
            return response.data;
        }
        else {
            return null;
        }
    }
}
