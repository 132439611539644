export const InputHelper = {
  onkeyDownNumbersOnly: (
    e: React.KeyboardEvent<HTMLInputElement>,
    currentValue: string,
    maxLength?: number
  ) => {
    // If Backspace (8), Delete (46), Arrows (37, 38, 39, 40), or Tab (9), let thru too
    if (
      e.keyCode !== 8 &&
      e.keyCode !== 46 &&
      e.keyCode !== 37 &&
      e.keyCode !== 38 &&
      e.keyCode !== 39 &&
      e.keyCode !== 40 &&
      e.keyCode !== 9
    ) {
      let key = Number(e.key);

      if (maxLength && currentValue.length === maxLength) {
        e.preventDefault();
      }

      if (isNaN(key) || e.key === null) {
        e.preventDefault();
      }
    }
  },
  onBlurPadLeftZero: (e: React.FocusEvent<HTMLInputElement>, value: string) => {
    if (value.length === 1) {
      return 0 + value;
    } else {
      return value;
    }
  },
};

export default InputHelper;
