import * as React from 'react';
import { Link, RouteComponentProps, Redirect } from 'react-router-dom';
import { LocationDescriptor } from "history";

import ApplicationUser from '../../entities/ApplicationUser';
import AppConfig from '../../entities/AppConfig';

import ProSubscription, { ProSubscriptionTerm, ProSubscriptionStatus, ProCost, ProSubscriptionSource } from '../../entities/ProSubscription';
import { FieldValidationError, ServerResponse, ServerModelValidationResponse } from '../../services/ServiceHelper';

import { ProSubscriptionService } from '../../services/ProSubscriptionService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DowgradeDude from '../../resources/downgrade-guy.png';

interface Props extends RouteComponentProps<any> {
    user: ApplicationUser;
    config: AppConfig;
    handleUpdateUser: (user: ApplicationUser | null) => void;
    hasPaypal: boolean;
}

type State = {
    enableSubmit: boolean;
    redirectTo: LocationDescriptor<any> | null;
    showNoSubscriptionError: boolean;
    errors: FieldValidationError[];
};

export default class CancelSubscription extends React.Component<Props, State> {

    redirectState: any = {};
    cancellationReasons: string[] = ['Don\'t like the quality of the media', 'Too hard to use the website', 'Price is too expensive', 'Budget reduction requirements', 'Retiring', 'Called to a career outside of church', 'Found another website I like better', 'Other'];
    dataLayer = (window as any).dataLayer = (window as any).dataLayer || [];

    constructor(props: Props) {
        super(props);
        this.state = {
            enableSubmit: true,
            redirectTo: null,
            showNoSubscriptionError: false,
            errors: []
        };
    }

    async componentDidMount() {

        document.title = "SermonCentral Payments - Cancel PRO Subscription";

        let currency = this.props.user.currency;

        if (this.props.hasPaypal) {
            (this.redirectState as any).previousLocation = "Cancel";
            this.setState({ redirectTo: { pathname: '/feature-unavailable', state: this.redirectState } });
        }

        if (!this.props.user.proSubscription) {

            this.setState({ showNoSubscriptionError: true });
        }
        else if (this.props.user.proSubscription.term === ProSubscriptionTerm.None) {

            this.setState({ redirectTo: { pathname: '/cancel/cant-cancel', state: this.redirectState } });
        }
        else if (this.props.user.proSubscription.status !== ProSubscriptionStatus.Active) {

            this.setState({ redirectTo: { pathname: '/cancel/cant-cancel', state: this.redirectState } });
        }
        else if (this.props.user.proSubscription.source === ProSubscriptionSource.Gloo){

            window.location.replace(this.props.config.glooSubscriptionsUrl)

        }
    }

    componentDidUpdate() {
        this.dataLayer.push({ 'event': 'optimize.activate' })
    }

    async downgradeSubscription() {
        if (!this.state.enableSubmit) {
            return;
        }

        try {
            this.setState({ enableSubmit: false });
            const response = await ProSubscriptionService.downgradeSubscription();

            if (ServerResponse.isSuccess<ApplicationUser>(response)) {

                this.props.handleUpdateUser(response.data);
                const redirectState = { redirectedFromDowngrade: true };
                this.setState({ redirectTo: { pathname: '/downgrade/confirmation', state: redirectState } });
            }
            else if (ServerResponse.isError(response)) {
                const serverError: FieldValidationError = { field: "", errors: [response.message] };
                this.setState({ errors: [serverError], enableSubmit: true });
            }
            else {
                const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                this.setState({ errors: [serverError], enableSubmit: true });
            }
        }
        catch (errorResult) {
            const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
            this.setState({ errors: [serverError], enableSubmit: true });
        }
    }

    render() {
        const discountPrice = ProCost.basic - 20;
        const monthlyPrice = discountPrice / 12;

        if (this.props.user?.proSubscription?.source === ProSubscriptionSource.Gloo) {
            return (
                <div className="container py-5">
                    <div className="text-center py-5">
                        <h2 className="mb-4">You have a Gloo Pro Subscription</h2>
                        <h6 className="mb-4">Redirecting to Gloo.</h6>
                    </div>
                </div>
            )
        }
        if (this.state.redirectTo) {
            return (
                <Redirect to={this.state.redirectTo}/>
            );
        } else if (this.state.showNoSubscriptionError) {
            return (
                <div className="container py-5">
                    <div className="text-center py-5">
                        <h2 className="mb-4">Cannot Cancel PRO Subscription</h2>
                        <h6 className="mb-4">You do not have a subscription to SermonCentral PRO.</h6>
                        <button className="btn btn-secondary ml-auto mr-auto d-block"><a href={this.props.config.mainSiteUrl} className="buttonLink">RETURN TO SERMONCENTRAL</a></button>
                    </div>
                </div>
            );
        }
        else {
            return (
                <div className="py-5">
                    {FieldValidationError.hasGenericError(this.state.errors) &&
                        <div className="alert alert-danger" role="alert" dangerouslySetInnerHTML={{ __html: FieldValidationError.getGenericErrorSummary(this.state.errors) }} />
                    }
                    <div className="row">
                        <div className="offset-md-1 col-md-10">
                            <div className="order-md-1">
                                <div className="card">
                                    <div className="card-header primary-card-header">
                                        <h5 id="header-title" className="text-white">Wait, before you cancel...</h5>
                                    </div>
                                    <div className="card-body">
                                        <p className="mb-4">
                                            <h3 id="subheader-title" className="text-uppercase">Special offer: Pro <span className="text-capitalize">Basic Plan</span></h3>
                                        </p>
                                        <div className="mb-3">
                                            <h5 id="subheader-description"><small>If you don't use unlimited media downloads, we do have an option for subscribers who want everyone else.</small></h5>
                                        </div>
                                        <div className="inital-cancel-card row">
                                            <div className="col-md-12">
                                                <div
                                                    className="card"
                                                    style={{
                                                        height: 185,
                                                        width: "100%",
                                                        border: "none",
                                                        borderRadius: 3,
                                                        backgroundImage: "linear-gradient(#dddddd,#dbdbdb)",
                                                    }}
                                                >
                                                    <div id="main-banner-container" className="row">
                                                        <img className="downgrade-dude" src={DowgradeDude} />
                                                        <div className="offset-md-4 col-md-4" style={{ color: "#ed7c39" }}>
                                                            <h4 className="mt-3 pl-5">Keep PRO Access</h4>
                                                        </div>
                                                        <div className="offset-md-5 col-md-4">
                                                            <h3>for only ${discountPrice} a year</h3>
                                                        </div>
                                                        <div className="offset-md-4 col-md-6">
                                                            <h5 className="ml-3 pl-5 mb-0"><small>keep your favorites and purchased media,</small></h5>
                                                        </div>
                                                        <div className="offset-md-4 col-md-6">
                                                            <h5 className="ml-5 pl-5"><small>plus access all non-media PRO features</small></h5>
                                                        </div>
                                                    </div>
                                                    <div className="container" style={{ margin: "auto" }}>
                                                        <div id="mobile-banner-container" className="row">
                                                            <div className="col-12"  >
                                                                <h4> <span style={{ color: "#ed7c39" }}>Keep PRO Access</span>&nbsp;for only ${discountPrice} a year</h4>
                                                            </div>
                                                            <div className="col-12">
                                                                <h5 ><small>keep your favorites and purchased media, plus access all non-media PRO features</small></h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-sm-12 col-md-12 col-lg-6 new-annual-fee">
                                                <div className="card" style={{ height: 150, width: "100%", backgroundColor: "#f6f6f6", border: "none" }}>
                                                    <div className="row no-gutters" style={{ height: "100%" }}>
                                                        <div className="cancel-icon-container col-md-4" style={{ alignItems: "center" }}>
                                                            <FontAwesomeIcon style={{ fontSize: 80, color: "#b8b8b8", margin: "0 auto", display: "block", transform: "scaleX(-1)" }} icon={['fas', 'tag']} />
                                                        </div>
                                                        <div id="price-structure" className="col-sm-12 col-md-8">
                                                            <h4 className="mt-3" style={{ color: "#ed7c39" }}>New Annual Fee</h4>
                                                            <h5><small>Your PRO subscription fee will be reduced to ${discountPrice} per year.  That's only ${monthlyPrice} per month!</small></h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-12 col-lg-6 one-time-offer">
                                                <div className="card" style={{ height: 150, width: "100%", backgroundColor: "#f6f6f6", border: "none" }}>
                                                    <div className="row no-gutters" style={{ height: "100%" }}>
                                                        <div className="cancel-icon-container col-md-4" style={{ alignItems: "center" }}>
                                                            <FontAwesomeIcon style={{ fontSize: 90, color: "#b8b8b8", margin: "0 auto", display: "block", transform: "scaleX(-1)" }} icon={['far', 'clock']} />
                                                        </div>
                                                        <div id="price-secondary-structure" className="col-sm-12 col-md-8">
                                                            <h4 className="mt-3" style={{ color: "#ed7c39" }}>One-Time Offer</h4>
                                                            <h5><small>PRO Basic is priced at ${ProCost.basic}/year. This special ${discountPrice} price is an exclusive offer only available today. Don't miss it.</small></h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row justify-content-center mt-4">
                                            <div className="col-sm-8 col-lg-3 continue-canceling">
                                                <button disabled={!this.state.enableSubmit} onClick={() => this.props.history.push("/cancel-reason")} className="btn btn-outline-secondary btn-block btn-md">
                                                    Continue Canceling
                                                </button>
                                            </div>
                                            <div className="col-sm-8 col-lg-3 stop-canceling">
                                                <button disabled={!this.state.enableSubmit} className="btn btn-primary btn-block btn-md" onClick={() => this.downgradeSubscription()}>
                                                    {this.state.enableSubmit &&
                                                        <> I Want This Offer!</>
                                                    }
                                                    {!this.state.enableSubmit &&
                                                        <>
                                                            PLEASE WAIT...
                                                                    <div className="spinner-border spinner-border-sm ml-4 mb-1" role="status" />
                                                        </>
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                        <div id="footer" className="row mt-5">
                                            <div className="col-md-12">
                                                <h5>
                                                    <small>
                                                        Grab this offer now to downgrade your account. You will have full access to all sermons, illustrations, sermon downloads,
                                                        view sermons on one page, lectionary calendar, PRO partnership benefits and offers, sermon collections, SermonFolders, and Favorites.  Media and Sermon Series
                                                        Kits can be purchased a la carte, anytime.
                                                    </small>
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            );
        }
    }
}