import {useEffect, useState} from "react";
import {VerificationService} from "../../services/VerificationService";
import State from "../../entities/State";
import {FieldValidationError, ServerResponse} from "../../services/ServiceHelper";
import ChurchInfo from "../../entities/ChurchInfo";
import * as React from "react";
import VerificationCard from "./VerificationCard";
import './verify.css'

const VerifyChurchInfo = ({ nextStep, updateChurchInfo, embed }: { nextStep: () => void, updateChurchInfo: (churchInfo: ChurchInfo) => void, embed: boolean }) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [usStates, setUsStates] = useState<State[]>([]);
    const [name, setName] = useState("")
    const [city, setCity] = useState("")
    const [region, setRegion] = useState("")
    const [country, setCountry] = useState("")
    const [jobPosition, setJobPosition] = useState("")
    const [errors, setErrors] = useState<FieldValidationError[]>([]);
    const [initialChurchInfo, setInitialChurchInfo] = useState<ChurchInfo|null>(null);
    const [enableSubmit, setEnableSubmit] = useState(true);
    
    useEffect(() => {
        let cancelStateUpdate = false;
        
        const getInfo = async () => {
            const info = await VerificationService.getChurchInfo();

            if (cancelStateUpdate) {
                return;
            }
            
            if (ServerResponse.isSuccess<ChurchInfo>(info)) {
                if (info.data.jobPosition === null) {
                    info.data.jobPosition = "";
                }
                setInitialChurchInfo(info.data);

                setName(info.data.name);
                setCity(info.data.city);
                setRegion(info.data.state);
                setCountry(info.data.country);
                setJobPosition(info.data.jobPosition);
            }
            setIsLoading(false);
        }
        getInfo();
        return () => {
            cancelStateUpdate = true;
        };
    }, []);

    useEffect(() => {
        let cancelStateUpdate = false;
        
        const getStates = async () => {
            const res = await VerificationService.getStates();
            if (cancelStateUpdate) {
                return;
            }
            
            if (ServerResponse.isSuccess<State[]>(res)) {
                res.data.unshift({name:"", postalAbbreviation:""});
                setUsStates(res.data);
            } else {
                setErrors(errors =>  [...errors, { field: "error", errors: ["There was an error. Please try again."]} ]);
            }
        }

        getStates();
        return () => {
            cancelStateUpdate = true
        };
    }, []);

    const validateField = (s: string, field: string, text: string) => {
        if (s && s.trim().length >= 3) {
            setErrors(errors => errors.filter(e=> e.field === field));
            return true;
        } else {
            setErrors(errors =>  [...errors, { field: field, errors: [`${text} must be at least 3 characters long`]}] );
            return false;
        }
    }

    const _isFormDirty = () => {
        return !(initialChurchInfo?.name === name && initialChurchInfo.city === city && initialChurchInfo.state === region && initialChurchInfo.jobPosition === jobPosition);
    }

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!enableSubmit) {
            return;
        }

        setEnableSubmit(false);
        setErrors(errors =>[]);

        if (!_isFormDirty()) {
            // Send back to parent component for datalayer event
            updateChurchInfo({name, city, state: region, country, jobPosition});
            nextStep();
            setEnableSubmit(true);
            return;
        }

        if (!validateField(name, "name", "Church Name") || !validateField(city, "city", "City") ||
            !validateField(region, "region", "State/Region") || !validateField(jobPosition, "jobPosition", "Ministry Role")
        ) {
            setErrors(errors => [...errors, {field: "error", errors:["Please fix the form field(s) below and try again."]}]);
            setEnableSubmit(true);
            return;
        }

        const response = await VerificationService.updateChurchInfo({
            name: name,
            city: city,
            state: region,
            jobPosition: jobPosition
        })

        if (ServerResponse.isSuccess<ChurchInfo>(response)) {
            // Send back to parent component for datalayer event
            updateChurchInfo({name, city, state: region, country, jobPosition});
            nextStep();
            setEnableSubmit(true);
        } else {
            setErrors([{ field: "error", errors: ["There was an error. Please try again."] }]);
            setEnableSubmit(true);
        }
    }

    return (
        <>
            {
                isLoading ? (<p>Loading...</p>) : (
                    <form onSubmit={handleSubmit}>
                        <VerificationCard title="Create Your Account" embed={embed}>

                            <p>Share about the ministry where you'll use these preaching tools.</p>

                            {FieldValidationError.isFieldInError(errors, "error") ?
                                <div className="text-danger mb-3"
                                     dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(errors, "error") }}
                                /> : null
                            }

                            <div className="mb-3">
                                <label>Church Name</label>
                                <div className="input-group">
                                    <input type="text" className={`form-control-lg form-control ${FieldValidationError.isFieldInError(errors, 'name') ? 'is-invalid' : null}`}
                                           minLength={3}
                                           value={name}
                                           onChange={(e) => setName((e.target.value))}
                                    />
                                </div>
                                {FieldValidationError.isFieldInError(errors, "name") ?
                                    <div className="text-danger"
                                         dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(errors, "name") }}
                                    /> : null
                                }
                            </div>

                            <div className="row mb-3">
                                <div className="col">
                                    <label>Church City</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control-lg form-control"
                                               minLength={3}
                                               value={city}
                                               onChange={(e) => setCity((e.target.value))}
                                        />
                                    </div>
                                    {FieldValidationError.isFieldInError(errors, "city") ?
                                        <div className="text-danger"
                                             dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(errors, "city") }}
                                        /> : null
                                    }
                                </div>

                                <div className="col">
                                    {country === "United States of America" ?
                                        <>
                                            <label>Church State</label>
                                            <select value={region} multiple={false} className="form-control form-control-lg" onChange={e => setRegion(e.target.selectedOptions[0].value)}>
                                                {usStates.map(s => (
                                                    <option key={s.postalAbbreviation} value={s.name}>{s.name}</option>
                                                ))}
                                            </select>
                                        </>
                                        :
                                        <div>
                                            <label>Church State/Province</label>
                                            <div className="input-group">
                                                <input type="text" className="form-control-lg form-control"
                                                       minLength={3}
                                                       maxLength={50}
                                                       value={region}
                                                       onChange={(e) => setRegion((e.target.value))}
                                                />
                                            </div>
                                            {FieldValidationError.isFieldInError(errors, "region") ?
                                                <div className="text-danger"
                                                     dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(errors, "region") }}
                                                /> : null
                                            }
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className="mb-3">
                                <label>Your Ministry Role</label>
                                <div className="input-group">
                                    <input type="text" className="form-control-lg form-control"
                                           minLength={3}
                                           value={jobPosition}
                                           onChange={(e) => setJobPosition((e.target.value))}
                                    />
                                </div>
                                {FieldValidationError.isFieldInError(errors, "jobPosition") ?
                                    <div className="text-danger"
                                         dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(errors, "jobPosition") }}
                                    /> : null
                                }
                            </div>

                            <button type="submit" className="btn btn-primary btn-block btn-lg mb-4" style={{backgroundColor: embed ? "#3b9d42" : "default"}} disabled={!enableSubmit}>
                                {enableSubmit && <>CONTINUE</>}
                                {!enableSubmit &&
                                    <>
                                        PLEASE WAIT...
                                        <div
                                            className="spinner-border spinner-border-sm ml-4 mb-1"
                                            role="status"
                                        />
                                    </>
                                }
                            </button>
                        </VerificationCard>
                    </form>
                )}
        </>
    );
}

export default VerifyChurchInfo;