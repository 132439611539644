import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import AppConfig from "../entities/AppConfig";
import ApplicationUser from "../entities/ApplicationUser";

interface Props extends RouteComponentProps<any> {
  user: ApplicationUser;
  config: AppConfig;
}

type State = {};

export default class FeaturedUnavailable extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    let redirectState = this.props.location.state;
    document.title = "SermonCentral Payments - Unavailable in your Region";
  }

  render() {
    return (
      <div className="container py-5">
        <div className="row">
          <div className="offset-md-2 col-md-8">
            <div className="text-center py-5">
              <h2 className="mb-4">Unavailable in Your Region</h2>
              <h6 className="mb-4">
                This product is not available in your region.{" "}
                <a href="javascript:history.back()">Go back</a> to your previous
                page.
                <br />
                <a
                  href={`${this.props.config.mainSiteUrl}/contactus?ref=feature-unavailable-payments`}
                >
                  Contact Support
                </a>{" "}
                for help.
              </h6>
              <button className="btn btn-secondary ml-auto mr-auto d-block">
                <a href={this.props.config.mainSiteUrl} className="buttonLink">
                  GO TO SERMONCENTRAL
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
